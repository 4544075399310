.theme-test {
  transition: all ease-in-out 200ms;
  width: 100%;
  height: 100%;
  position: relative;

  .theme-toggle-button,
  .device-toggle-button {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 10px;
    left: 10px;
    cursor: pointer;
    font-size: 32px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .device-toggle-button {
    top: 70px;
  }
}

