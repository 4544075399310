#manager__disease-view {
  width: 100%;
  height: 100%;

  .manager__header {
    width: 100%;
    flex-shrink: 0;
  }

  .manager__disease-view__body {
    width: 100%;
    height: 0;
    flex: 1;
    align-items: flex-start;

    .data-separator {
      width: 2px;
      height: 100%;
      background: #e9e9e9;
    }

    .manager__data-grid {
      flex-grow: 1;
      flex-shrink: 0;
      padding: 0 20px;
      flex-basis: 0;
    }

    .tags-section {
      height: unset;
      grid-column: 1/-1;

      .tag {
        padding-left: 10px;
      }
    }
  }
}