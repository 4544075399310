@mixin circleButton {
  width: 25px;
  height: 25px;
  border: 3px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: {
    size: 20px;
    weight: 600;
  }
  cursor: pointer;
}