@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

#editor-organization {
  width: 100%;
  height: 100%;
  background: #fff;
  row-gap: 20px;

  .manager__header {
    flex-shrink: 0;
    width: 100%;

    .manager__header__main {
      justify-content: flex-start;
      gap: 20px;

      .manager__header__title {
        gap: 0;
        height: unset;
        align-items: flex-start;
      }
    }

    .editor-organization__header__tail {
      gap: 20px;
      margin-left: auto;
    }
  }

  .editor-organization__body {
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  .editor-organization__views {
    $border-color: #e3e9f2;
    border-right: 1px solid $border-color;
    padding-left: 20px;
    scrollbar-gutter: unset;
    width: 200px;
    flex-shrink: 0;

    .editor-organization__view {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      min-height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-block: 4px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      &.selected {
        background: #f6f8fc;
        border-color: $border-color;
      }
    }

    .ts-collapsable-container {
      .ts-collapsable-container__header {
        font-weight: normal;
        height: 30px;
        gap: 4px;

        .icon--wrapper:last-child {
          margin-left: auto;
          width: 24px;
          height: 24px;
          padding: 4px;
          border-radius: 4px;

          &:hover {
            background: #f6f8fc;
          }
        }
      }

      .ts-collapsable-container__container-wrapper {
        gap: 0;
      }
    }
  }

  .editor-organization__info-title {
    color: $font-color;
    line-height: 30px;
  }
}

#pop-up__organization-action {
  @extend %actions-popup;
}

#ehr-instances-menu {
  @extend %actions-popup;
}

#instance-pop-up-menu {
  @extend %actions-popup;
}