@use '../../functions-and-mixins' as styles;

.ts-busy-button {
  //Dimensions
  min-width: 80px;
  min-height: 30px;
  padding: 4px;
  margin-inline: 8px;

  //Border
  border-radius: 3px;
  cursor: pointer;

  //Text
  text-align: center;
  font: {
    size: 1rem;
    weight: 600;
  }

  //Colors
  color: var(--button__text-color);
  background: var(--button__background-color);

  //Animation
  transition: background 100ms linear;

  //Pseudos
  &:hover {
    background: var(--button__background-color-hover);
  }

  &:active {
    background: var(--button__background-color-active);
  }

  //Concat Classes
  &.desired-action-button {
    color: var(--button__text-color-desired);
    @include styles.mouseInteractiveBackground(
                    var(--button__background-color-desired),
                    var(--button__background-color-desired-hover),
                    var(--button__background-color-desired-active)
    );
  }

  &.delete-button {
    @include styles.mouseInteractiveBackground(styles.red(4), styles.red(3), styles.red(2));
    color: white;
    margin-right: auto;
  }

  &.ts-busy-button__disabled {
    background: var(--color__dialog__button-background-disabled);

    &:hover, &:active {
      background: var(--color__dialog__button-background-disabled);
    }
  }

  &.ts-busy-button__loading {
    cursor: wait;
  }
}