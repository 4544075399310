@use "../../functions-and-mixins" as mixins;

.q-scroll-wrapper {
  @include mixins.customScrollbar("both", true);
  width: 100%;
  height: 100%;

  &.flex {
    height: 0;
    flex: 1;
  }
}