@use '@app/styles' as styles;

.discussion {
  margin-top: 20px;
  width: 300px;
  display: flex;

  .ll_h_c {
    width: 100%;
    display: flex;
    align-items: stretch;

    .container-left {
      width: 30px;

      .resolved-status {
        width: 10px;
        height: 10px;
        margin-top: 8px;
        background: #f24822;
        border-radius: 50%;

        &.resolved {
          opacity: 0;
        }

        &.unresolved {
          opacity: 100%;
        }
      }
    }

    .container-mid {
      flex-grow: 1;
      max-width: 233px;
      overflow-x: hidden;

      .container-participants {
        margin-bottom: 13px;
        gap: 10px;

        .participant-icon {
          padding: 3px 2px 2px 2px;
          width: 26px;
          height: 26px;
          font-size: 13px;
          text-transform: uppercase;
          text-align: center;
          color: #1d496f;
          border: 1px solid #1d496f;
          border-radius: 50%;
        }
      }

      .topic-label {
        @include styles.mouseInteractiveColor(#1e98d7, #0f3a57);
        font-size: 13px;
        font-weight: 520;
      }

      .topic-entity-name {
        @include styles.mouseInteractiveColor(#0a3449, black);
        font-size: 13px;
        font-weight: 520;
      }

      .error-message-text {
        font-size: 13px;
        color: black;
        white-space: pre-wrap;
        width: 100%;
      }

      .replies {
        @include styles.mouseInteractiveColor(#1e98d7, #0f3a57);
        font-size: 13px;
        font-weight: 520;
      }

      .add-message-container {
        align-items: stretch;

        .add-message-input {
          width: 220px;
          height: 77px;

          color: #4b4b4b;
          font-size: 13px;

          padding: 10px;
          border-radius: 4px;
          background-color: white;
          box-shadow: -2px 2px 6px 0px rgba(174, 193, 218);
          margin: 2px;
        }

        .add-message-button-container {
          justify-content: end;

          .add-message-button-cancel {
            color: #4b4b4b;
            font-size: 13px;
            font-weight: unset;

            background: unset;
            text-align: left;
          }

          .add-message-button-save {
            width: 30px;
            height: 30px;

            color: #1e98d7;
            font-size: 14px;

            background: unset;
            text-align: left;
            line-height: normal;
            min-width: unset;
            min-height: unset;
          }
        }
      }
    }

    .container-right {
      //width: 37px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      justify-items: start;

      .resolve-button {
        @include styles.mouseInteractiveIcon(#1d496f);
        margin-top: 2px;
        padding: 3px;
        width: 20px;
        height: 20px;
        color: #1d496f;
        border-radius: 50%;
        border: 1px solid #1d496f;

        .icon--wrapper {
          width: 17px;
          height: 17px;
          background-color: #1d496f;
        }

        &:hover {
          background-color: #eff3f5;
          box-sizing: border-box;
          border-radius: 50%;
        }
      }

      .add-message-bubble {
        @include styles.mouseInteractiveIcon(#1d496f);
        width: 24px;
        height: 24px;
        //padding: 2px 2px 2px 2px;
        background-color: white;
        box-sizing: border-box;

        .icon--wrapper {
          background-color: #1d496f;
        }

        &:hover {
          background-color: #eff3f5;
          box-sizing: border-box;
          border-radius: 50%;
        }

        //display: none;
      }
    }
  }

  .container-bottom-border-for-padding {
    width: 100%;
    margin-top: 20px;
    padding-inline: 30px 22px;

    .discussion-bottom-border {
      width: 100%;
      min-height: 1px;
      background-color: #d2dbea;
    }
  }
}