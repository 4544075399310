//@use '@nu-art/thunderstorm/styles' as *;

.default_panel_header {
  width: 100%;
  height: 32px;
  justify-content: flex-end;
  background: var(--color__header__values);
  padding: 0 10px;
  flex-shrink: 0;

  .header__title {
    font-size: 16px;
    font-weight: var(--workspace__header-font-weight);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .qsearch {
    background: var(--color__header__values-search);
    //width: 200px;
    min-width: 100px;
    margin-inline: 12px;
    box-shadow: var(--shadow__general-inset);

    .qsearch-icon {
      width: 12px;
      height: 12px;

      svg path {
        fill: none;
      }
    }

    .ts-input {
      display: flex;
      flex-grow: 1;
      padding-bottom: 4px;
      max-width: unset;
      font-size: var(--workspace__default-font-size);
      color: var(--color__header__search-text);

      &::placeholder {
        color: var(--color__header__search-text);
        font-size: var(--workspace__default-font-size);
      }
    }
  }

  .header__icon {
    width: 16px;
    cursor: pointer;
  }
}