@use '@app/styles' as styles;

#variable-editor {
  width: max(95vw, 1000px);
  min-height: max(90vh, 500px);
  border-radius: 10px;

  .ts-dialog__header {
    .exit_icon {
      @include styles.mouseInteractiveIcon(white);
      width: 20px;
      height: 20px;
    }
  }

  .ts-dialog__main {
    height: 0;

    .ts-tabs {
      height: 100%;

      .ts-tabs__content {
        @include styles.customScrollbar('vertical');
        display: flex;
        flex-direction: column;

        .edit-not-allowed {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font: {
            size: 30px;
            weight: bold;
          }
        }
      }
    }

    .checkbox-container {
      gap: 10px;

      span {
        font: {
          size: 14px;
          weight: bold;
        }
      }
    }

    //Children
    .dialog__main__section {
      height: 100%;
      flex: 1;
      gap: 16px;

      .dialog__main__section-item {
        gap: 16px;

        &.tags {
          height: 100%;

          .q-scroll-wrapper {
            height: 0;
            flex: 1;
          }

          .tags-section {
            width: 100%;
            height: unset;
            flex: unset;

            .dialog__field-content {
              width: 100%;
              flex: 1;
              gap: 10px;
            }
          }
        }

        .ts-input {
          flex-grow: 1;
          color: black;
          width: 100%;
          max-width: unset;

          //border
          border: 2px solid styles.gray(2);
          border-radius: 4px;

          &:focus {
            outline: none;
          }
        }

        .ts-dropdown {
          .ts-dropdown__header {
            border: 2px solid styles.gray(2);
            border-radius: 4px;
          }

          .ts-input {
            border: none;
          }
        }

        .ts-radio {
          justify-content: flex-start;
          align-items: center;

          .ts-radio__label {
            padding: 6px 10px;
            font: {
              size: 14px;
              weight: 600;
            }
          }
        }

        .q-editable-entry-list {
          gap: 8px;

          .entry {
            height: 30px;
            margin: 0;

            display: flex;
            justify-content: flex-start;
            align-items: center;

            border: 2px solid styles.purple(2);
            border-radius: 4px;
            background: styles.purple(6);

            .icon--wrapper {
              padding: 0 5px;
              height: 100%;
              width: 22px;
              cursor: pointer;

              svg path {
                fill: black;
                stroke-width: 1px;
                stroke: black;
              }

              &:hover {
                background: styles.purple(5);
              }
            }

            .synonym-display {
              border-left: 2px solid styles.purple(2);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 5px;
              font: {
                size: 14px;
                weight: bold;
              }
              color: black;

            }

            .ref-link {
              border-left: 2px solid styles.purple(2);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 5px;
              font: {
                size: 14px;
                weight: bold;
              }
              color: black;

              &:hover {
                background: styles.purple(5);
              }
            }

            .ref-loader {
              margin: 0 5px;

              .ts-loader {
                width: 30px;
                height: 30px;
              }
            }
          }

          .ts-input {
            width: 30px;
            height: 30px;
            min-height: unset;
            max-width: 150px;
            min-width: unset;

            border-radius: 50%;
            border-color: styles.purple(2);
            background: styles.purple(6);

            transition: width 200ms ease-out, border-radius 200ms ease-out;

            &::placeholder {
              font-size: 28px;
              color: black;
              translate: 0 4px;
              transition: translate 200ms ease-out;
            }

            &:focus, &:hover {
              width: 150px;
              border-radius: 4px;

              &::placeholder {
                translate: -20px 4px;
              }
            }
          }
        }

        .tag-dropdown {
          width: 200px;
          height: 28px;
          cursor: pointer;

          .ts-dropdown__header {
            background: styles.dark-blue(7);
            border: 2px solid styles.dark-blue(2);
            border-radius: 10px;

            .caret {
              height: 100%;
              width: 20px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 5px;
              transition: transform 200ms linear;

              path[data-name="Path 12166"] {
                fill: styles.dark-blue(2);
                stroke: styles.dark-blue(2);
                stroke-width: 1;
              }

              &.close {
                transform: rotateX(180deg);
              }
            }

            .ts-dropdown__placeholder {
              color: styles.dark-blue(2);
              padding: 0 5px;
              font: {
                weight: bold;
                size: 13px
              }
            }
          }

          .ts-dropdown__items-container {
            background: styles.dark-blue(7);
            border: 2px solid styles.dark-blue(2);

            .ts-dropdown__items {
              .ts-tree__node {
                .node-data {
                  color: styles.dark-blue(2);
                  font-size: 13px;
                }

                &:hover {
                  background: styles.dark-blue(6);;
                }
              }
            }
          }
        }

        .full-range {
          gap: 10px;

          .title {
            flex: 1;
            flex-basis: 0;
          }

          .range-full {
            flex: 1;
            flex-basis: 0;
          }

          .range-min, .range-max {
            width: 100px;
            flex-grow: 0;
          }
        }

        &.values {
          height: 100%;
        }

        &.ll_h_c {
          .ts-dropdown {
            width: 0;
            flex-grow: 1;
          }
        }

        &.iv {
          flex-grow: 1;
        }
      }

      .add-value-button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        min-width: 170px;
        height: 40px;
        padding: 0 10px;
        margin: 0 0 0 auto;
        background: var(--color__dialog-header-background);
        color: white;
        font-weight: 600;
        cursor: pointer;
        align-self: center;

        &:active {
          background: black;
        }
      }

      &.ll_v_l {
        .dialog__main__section-item {
          width: 100%;
        }
      }

      &.ll_h_c {
        .dialog__main__section-item {
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }

    .values-grid-wrapper {
      width: 100%;
      height: 200px;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      box-shadow: var(--shadow__general-inset-strong);
      padding: 5px 0 0;

      .values-grid {
        z-index: -1;
        width: 100%;
        border-collapse: collapse;
        display: grid;
        grid-template-columns: repeat(4, auto);

        .grid-separator {
          grid-column: 1/5;
          height: 0;
          border-top: 1px solid black;
        }

        .grid-header {
          width: 100%;
          font: {
            size: 14px;
            weight: bold;
          }
        }

        .col-0, .col-1, .col-2, .col-3 {
          display: flex;
          align-items: center;
          background: transparent;
          padding: 0 10px;

          .data {
            height: 30px;
            display: flex;
            align-items: center;
          }
        }

        .col-1, .col-2, .col-3 {
          border-left: 1px solid black;
        }

        .ts-input {
          border: none;
          max-width: unset;
          border-radius: 0;
          background: transparent;
          height: 30px;
          min-height: 30px;

          &:focus {
            outline: none;
          }
        }
      }
    }

    //Variations
    &.ll_v_l {
      .dialog__main__section {
        width: 100%;
      }
    }

    &.ll_h_c {
      .dialog__main__section {
        height: 100%;
      }
    }

    .attribute-list {
      width: 100%;
      height: 100%;
      padding: 20px;
      @include styles.customScrollbar('vertical');
      flex-wrap: wrap;
    }
  }

  .ts-textarea {
    width: 100%;
    height: 100px;
  }

  .fields-tab {
    width: 100%;
    height: 100%;

    .ts-textarea {
      width: 100%;
      height: 100px;
    }

    .fields {
      width: 100%;
      padding: 20px;
      display: grid;
      grid-template-rows: repeat(6, max-content);
      grid-auto-flow: column;
      gap: 20px;

      .field {
        width: max-content;
      }
    }
  }

  .macro-tags {
    width: 100%;
    border: 2px solid black;
    border-radius: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;

    .macro-tag {
      height: 30px;
      padding: 0 10px;
      border: 1px solid black;
      border-radius: 4px;
      gap: 5px;
      font-size: 14px;

      .icon--wrapper {
        width: 12px;
        height: 12px;
      }
    }

    .ts-dropdown {
      width: 200px;
      height: 30px;
    }
  }
}

.reference-used-tooltip {
  background: white;
  border: 2px solid styles.dark-blue(2);
  border-radius: 4px;
  padding: 8px 16px;
  gap: 8px;

  .reference-used-tooltip__title {
    font: {
      size: 14px;
      weight: bold;
    }
  }

  .reference-used-tooltip__ref {
    gap: 8px;

    .reference-used-tooltip__ref-id {
      font: {
        size: 13px;
        weight: bold;
      }
    }

    .reference-used-tooltip__ref-names {
      font-size: 13px;
    }
  }
}