@use '../../functions-and-mixins/index' as styles;

.ts-tooltip__content {
  max-width: 150px;
  border: 1px solid styles.black(2);
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: none;
  font: {
    size: 13px
  }
  white-space: pre-wrap;
  color: styles.black(2);
}