.ts-table {
  border-collapse: collapse;
  border: none;
  table-layout: fixed;

  //Table Head
  .ts-table__head .ts-table__tr {
    background: var(--table__header-background-color);
    height: 50px;
    border: none;

    .ts-table__th {
      font: {
        size: 1rem;
        weight: 600;
      }
      color: var(--table__header-text-color);
      padding: 0 5px;
      text-align: start;
      border: 1px solid var(--table__border-color);

      //Make direct children inherit design
      & > * {
        font: {
          size: inherit;
          weight: inherit;
        }
        color: inherit;
        text-align: inherit;
      }
    }
  }

  //Table Body
  .ts-table__body .ts-table__tr {
    background: var(--table__body-row-background-color);
    height: 50px;

    td.ts-table__td {
      //Dimensions
      height: 100%;
      width: 100%;
      padding: 0 10px 0 5px;
      margin-right: 5px;
      border: 1px solid var(--table__border-color);

      //Text
      font: {
        size: 0.8rem;
        weight: 600;
      }

      //Colors
      color: var(--table__body-text-color);

      //Make direct children inherit design
      & > * {
        font: {
          size: inherit;
          weight: inherit;
        }
        color: inherit;
      }
    }

    &.selected {
      background: var(--table__body-row-background-selected);
    }
  }
}