@use '@app/styles' as styles;

$form-card-height: 500px;
$peeper-width: 35vw;
$transitionMs: 300ms;

%peeper-content-design {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  transition: translate $transitionMs ease-out;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 0 90px;

  h1 {
    font: {
      size: 50px;
      family: 'Open Sans', sans-serif;
    };
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
  }

  h2 {
    font: {
      size: 30px;
      family: 'Open Sans', sans-serif;
    }
    margin: 0;
    white-space: pre-wrap;
    text-align: center;
    line-height: 1.5;
  }

  .ts-button {
    @include styles.mouseInteractiveBackground(styles.dark-blue(4), styles.dark-blue(3));
    width: 80%;
    height: 50px;
    border-radius: 25px;
    font-size: 20px;
  }
}

#page-login {
  width: 100vw;
  height: 100vh;
  justify-content: space-between;
  align-items: center;

  #login-background {
    position: fixed;
    inset: 0;
    isolation: isolate;
    z-index: -1;
    width: 100vw;
    height: 100vh;

    .blur-backdrop {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
    }

    .box {
      border-radius: 10%;
      position: absolute;

      &.box-1 {
        width: 1500px;
        height: 1500px;
        background: linear-gradient(styles.dark-blue(2), styles.dark-blue(5));
        bottom: 0;
        right: 0;
        translate: 50% 50%;
        animation: spinningBox 60s linear infinite;
      }

      &.box-2 {
        width: 750px;
        height: 750px;
        background: linear-gradient(rgb(244 255 63), #fd6fcb);
        top: 0;
        left: 0;
        translate: -50% -50%;
        animation: spinningBox 75s linear infinite;
      }
    }
  }

  .page-login__peeper {
    width: $peeper-width;
    height: 100%;
    overflow: hidden;
    position: relative;

    .page-login__switch-to-register {
      @extend %peeper-content-design;

      .ts-button {
        @include styles.mouseInteractiveBackground(white, styles.gray(7));
        color: styles.black(3);
      }

      &.hidden {
        translate: 100%;
      }
    }

    .page-login__switch-to-login {
      @extend %peeper-content-design;

      &.hidden {
        translate: -100%;
      }
    }
  }

  .page-login__quai-banner {
    position: fixed;
    top: 20px;
    left: 20px;
    margin: 16px 0;
    align-items: flex-start;
    gap: 8px;

    .quai-icon {
      width: 120px;
      height: 120px;
    }

    .header {
      .header__title {
        font: {
          weight: 600;
          size: 36px;
        }
      }

      .header__sub-title {
        font: {
          size: 34px;
        }
      }
    }
  }

  .page-login__form-container {
    height: 100%;
    width: calc(100% - $peeper-width);
    transition: width ease-out $transitionMs;
    overflow: hidden;
    -webkit-box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.65);
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.65);

    &.hidden {
      width: 0;
    }
  }

  .page-login__login-form {
    width: calc(100vw - $peeper-width);
    height: 100%;
    background: #fefefe;
    align-items: center;
    justify-content: center;
    gap: 35px;

    h1 {
      font: {
        size: 60px;
        family: 'Open Sans', sans-serif;
      }
      margin: 0;
    }

    .ts-account__authenticate {
      width: 60%;
      gap: 20px;

      .ts-input {
        max-width: unset;
        height: 50px;
        border: 1px solid styles.black(5);
        border-radius: 10px;
        font: {
          size: 18px;
        }
        padding: 0 10px;
      }

      .ts-prop-renderer {
        .ts-prop-renderer__label {
          font: {
            size: 20px;
          }
          color: styles.black(3);
        }
      }

      .ts-busy-button {
        @include styles.mouseInteractiveBackground(styles.dark-blue(4), styles.dark-blue(3));
        margin-top: 20px;
        height: 50px;
        width: 100%;
        border-radius: 25px;
      }
    }

    .page-login__login-form__separator {
      width: 60%;
      height: 20px;
      position: relative;

      //Render Line
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background: black;
        position: absolute;
        top: 50%;
        left: 0;
        translate: 0 -50%;
      }

      &::after {
        content: 'OR';
        background: #fefefe;
        height: 100%;
        width: 50px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 50%;
        translate: -50%;
        z-index: 1;
      }
    }

    .ts-account__saml-button {
      width: 60%;
      height: 50px;

      .ts-account__saml-button__text {
        font: {
          size: 18px;
        }
      }
    }
  }

  .form-area {
    gap: 32px;

    .form-cards {
      height: $form-card-height;
      width: 330px;
      position: relative;

      .form-card {
        position: absolute;
        height: $form-card-height;
        width: 100%;
        border: 1px solid styles.gray(6);
        background: rgba(255, 255, 255, 0.7);
        border-radius: 10px;
        transition: transform 100ms linear;
        box-shadow: 5px 5px 35px 1px rgba(0, 0, 0, 0.35);


        &.mount, &.enter {
          transform: rotateY(-90deg);
        }

        &.exit, &.exit-done {
          transform: rotateY(90deg);
        }
      }
    }

    .ts-account__authenticate {
      width: 100%;
      margin: 0;
      flex-grow: 1;
      padding: 16px;
      gap: 16px;

      .ts-prop-renderer {
        gap: 8px;

        .ts-input {
          border: 1px solid styles.black(5);
          border-radius: 10px;
          max-width: unset;
        }
      }

      .ts-account__error-messages {
        width: 100%;
        padding-block: 8px;
        padding-inline-start: 25px;
        background: styles.red(7);
        border: 2px solid styles.red(5);
        border-radius: 10px;

        li {
          color: styles.red(3);
          font: {
            size: 13px;
          }
          white-space: pre-wrap;
        }
      }

      .ts-account__action-button {
        @include styles.mouseInteractiveBackground(styles.dark-blue(3), styles.dark-blue(2), styles.dark-blue(1));
        margin-top: auto;
        width: 100%;
        height: 40px;
      }
    }

    .saml-buttons {
      width: 100%;
      padding: 0 16px;
    }

    .switch-area {
      width: 100%;
      margin: 16px 0;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .hint, .clickable {
        font: {
          size: 14px;
          weight: bold;
        }
      }

      .clickable {
        color: styles.dark-blue(3);
        cursor: pointer;
      }
    }
  }
}

@keyframes spinningBox {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}