@use '@app/styles' as styles;

.panel__multivar-list {
  width: 100%;
  height: 100%;

  .panel__multivar-list__header {
    height: 50px;

    .tail-icon {
      cursor: pointer;
      width: 25px;
      height: 25px;
      margin-inline-end: 5px;
    }

    .add-button {
      cursor: pointer;
      font: {
        size: 16px;
        weight: bold;
      }
      line-height: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
  }

  .multivar-list {
    @include styles.customScrollbar('vertical');
    width: 100%;
    height: 0;
    flex-grow: 1;

    .multivar {
      cursor: pointer;
      height: 50px;
      width: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;
      position: relative;

      .chief-complaint-icon {
        position: absolute;
        top: 6px;
        left: 4px;
        rotate: -30deg;
        fill: styles.orange(4);
      }

      .multivar-name {
        @include styles.text-ellipsis;
        gap: 5px;

        .text {
          font: {
            size: 14px;
            weight: bold;
          }
        }

        .dot {
          font: {
            size: 16px;
            weight: bold;
          }
          color: styles.red(2)
        }

        .icon--wrapper{
          height: 20px;
          width: 20px;
        }
      }

      .multivar-tail {
        gap: 10px;

        .variable-info-tooltip-icon {
          margin: 0;
        }

        .edit-button {
          opacity: 0;
          padding: 5px;
          border-radius: 8px;
          border: 1px solid black;
          transition: all 200ms linear;

          .icon--wrapper {
            width: 20px;
            height: 20px;

            svg path {
              transition: all 200ms linear;
            }
          }

          &:hover {
            background: styles.dark-blue(2);
            border: 1px solid styles.dark-blue(2);

            .icon--wrapper svg path {
              fill: white
            }
          }
        }

        .disease-value-count {
          @include styles.disease-value-count;
        }

        .variants {
          gap: 8px;

          .variant {
            background: styles.dark-blue(2);
            color: white;
            border-radius: 4px;
            padding: 4px 8px;
            font: {
              size: 14px;
              weight: bold;
            }
          }
        }
      }

      &.selected {
        background: styles.dark-blue(7);
      }

      &:hover .multivar-tail .edit-button {
        opacity: 1;
      }
    }
  }
}