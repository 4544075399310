@use "@app/styles" as styles;


.resolution-function-editor {
  @include styles.customScrollbar('vertical');

  .pathway__bottom-buttons {
    justify-content: flex-start !important;
  }

  .ts-list-organizer__list {
    margin-block: 2%;
    gap: 20px;
    display: grid;
    grid-template-columns: max-content;
  }
}