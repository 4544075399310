@use '../../functions-and-mixins' as styles;

.ts-input {
  max-width: 300px;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  min-height: 40px;
  padding-inline: 4px;

  &::placeholder {
    font: {
      size: 14px;
      weight: 500;
      style: italic;
    }
    color: styles.black(5);
  }
}