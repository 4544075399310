@use '../../../../index' as styles;
@use '../../../ui/ui-manager/consts' as *;

.quai-manager-ui__data-grid {
  grid-template-columns: max-content 1fr;
  width: 100%;
  gap: 10px 30px;

  .quai-manager-ui__data-grid__label,
  .quai-manager-ui__data-grid__data {
    line-height: 40px;
    font: {
      size: 14px;
      family: $font-family;
    }
    color: $font-color;

    &.clickable {
      @include styles.mouseInteractiveColor($font-color, $font-color-clickable)
    }
  }

  .quai-manager-ui__data-grid__label {
    font-weight: 600;
  }

  .quai-manager-ui__data-grid__data {
    &.indent {
      padding-left: 10px;
    }
  }
}