@use '../../../../index' as styles;
@use '../../../ui/ui-manager/consts' as *;

.quai-manager-ui__header {
  width: 100%;
  cursor: default;
  gap: 10px;
  justify-content: space-between;

  .quai-manager-ui__header__main {
    gap: 2px;
  }

  .quai-manager-ui__header__title {
    color: #4f4f4f;
    font: {
      family: $font-family;
      size: 18px;
    }
    text-transform: capitalize;
    gap: 5px;

    &.clickable {
      @include styles.mouseInteractiveColor($font-color, $font-color-clickable)
    }

    .icon--wrapper {
      width: 20px;
      height: 20px;
    }
  }

  .quai-manager-ui__header__subtitle {
    color: #4f4f4f;
    font: {
      family: $font-family;
      size: 12px;
    }
    text-transform: capitalize;
  }

  .quai-manager-ui__header__actions-icon {
    @include styles.mouseInteractiveBackground(transparent, rgba(129, 211, 248, 0.15));
    border-radius: 4px;
    width: 30px;
    height: 30px;
    padding: 6px;
  }

  &.underline {
    border-bottom: 1px solid $border-color;
    padding-bottom: 10px;
  }

  &.grow {
    flex-grow: 1;
  }

  &.small-title {
    .quai-manager-ui__header__title {
      font-size: 16px;
    }
  }
}

#quai-manager-ui__header__actions {
  $border-color: #e3e9f2;
  border: 1px solid $border-color;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  translate: calc(-50% + 15px) 0;

  .quai-manager-ui__header__actions__action {
    @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
    height: 40px;
    font: {
      size: 13px;
      family: $font-family;
    }
    color: $font-color;
    line-height: 40px;
    padding-inline: 10px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &.not-ready {
      opacity: 0.5;
    }

    &.disabled {
      @include styles.mouseInteractiveBackground(transparent);
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .quai-manager-ui__loader-wrapper {
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ts-button-loader div {
      background: $font-color;
    }

  }
}