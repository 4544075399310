@use '@app/styles/frontend/ui/ui-manager/consts' as *;
@use '@nu-art/ts-styles' as ts-styles;

#resolution-function-header {
  position: sticky;
  top: -30px;
  background: #fff;
  z-index: 2;
  padding-block: 10px;

  .quai-manager-ui__header__actions-icon {
    @include ts-styles.color-svg(#333333);

    &.expand-button {
      [data-name="bottom"] {
        transform-origin: 0 75%;
        transform: rotateX(180deg);
        transition: transform 200ms linear;
      }

      [data-name="top"] {
        transform-origin: 0 25%;
        transform: rotateX(180deg);
        transition: transform 200ms linear;
      }

      &.expanded {
        [data-name="top"],
        [data-name="bottom"] {
          transform: rotateX(0);
        }
      }
    }
  }
}


.quai-manager-ui__header {
  .quai-manager-ui__header__main {
    .quai-manager-ui__header__title {
      span.clickable {
        &:hover {
          color: #2970f6;
        }
      }

      .ts-button-loader {
        width: 100%;
        height: 100%;
        scale: 0.5;
        transform: translateY(-5px);
        margin-left: 10px;

        div {
          background: $font-color;
        }
      }
    }
  }
}