.editor-precondition {
  width: 100%;
  gap: 5px;

  .value-container {
    width: 100%;
    gap: 5px;

    .order-title {
      font-size: 14px;
      padding-inline: 10px 0;
    }

    .plus {
      height: 20px;
      width: 20px;
    }
  }

  .ts-dropdown {
    padding-inline: 10px 0;
  }

}