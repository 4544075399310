//@use 'node_modules/@nu-art/thunderstorm/styles' as *;

.knowledge-manager__sub-header {
  //Dimensions
  width: 100%;
  height: 40px;
  padding: 0 20px;
  flex-shrink: 0;

  background: var(--color__background__general-panel);

  //Display
  display: flex;
  justify-content: space-between;
  align-items: center;

  //Border
  border-bottom: 1px solid #DAE2E8;

  //Children
  .delete-workspace {
    width: 26px;
    height: 26px;
  }

  .sub-header__status {
    display: flex;
    align-items: center;
    gap: 20px;

    .sub-header__title {
      color: var(--color__sub-header__font-color);
      font-weight: var(--workspace__header-font-weight);
    }
  }

  .header__logo {
    margin-right: 30px;

    &.config {
      width: 30px;
      height: 30px;
      cursor: pointer;

      svg path {
        fill: #004565;
      }
    }
  }

  .sub-header__buttons {
    display: flex;
    align-items: center;
    gap: 20px;

    .sub-header__button {
      //Dimensions
      padding: 0 6px;

      //Display
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      //Border
      border: 1px solid #D9E6EF;
      border-radius: 3px;

      //Children
      span:first-child {
        width: 15px;
        height: 14px;
      }

      .sub-header__button__text {
        font-size: var(--workspace__default-font-size);
      }
    }
  }
}