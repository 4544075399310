@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.compound-range-editor {
  padding: 0 20px;
  width: 100%;

  .row {
    width: 100%;
    justify-content: space-between;
  }

  .add-value-button {
    background-color: var(--color__dialog-header-background);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin: 15px 0;
    font-weight: bold;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      background-color: #2C3541;
      color: white;
    }
  }

  .ts-dropdown {
    margin: 0 10px;
    min-width: 200px;
  }

  .inner-values {
    flex-grow: 1;

    .inner-values__actions {
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .inner-values__actions__delete,
      .inner-values__actions__cancel,
      .inner-values__actions__accept {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        margin: 0;

        svg {
          width: 20px;
          height: 20px;

          path {
            fill: white;
          }
        }
      }

      .inner-values__actions__delete, {
        background: styles.red(3);
      }

      .inner-values__actions__cancel {
        background: styles.red(3);

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .inner-values__actions__accept {
        background: styles.dark-green(3);

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .scrollable-list {
    overflow-y: auto;
    width: 100%;
    height: 0;
    flex-grow: 1;
  }

  .values-table-wrapper {
    .ts-table {
      border-collapse: collapse;

      .ts-table__head {
        position: sticky;
        top: 0;
        background: var(--color__values-table-table-header-background);
        z-index: 1;
      }

      .ts-table__body {

        .ts-table__tr {
          background: white;


          &:hover {
            .ts-table__td {
              border: 2px solid black;
            }
          }

          .ts-table__td {
            padding: 0;
            border-left: unset;
            border-top: unset;

            .icon {
              width: 30px;
              margin: 0 auto;
            }

            .ts-input {
              min-height: 30px;
            }
          }
        }
      }
    }
  }
}

.normal-range-editor {
  height: 0;
  flex-grow: 1;
  gap: 5%;
  padding: 15px;
  width: 100%;

  .tabs-container {
    width: 100%;
  }

  .ts-tabs {
    gap: 15px;

    .ts-tabs__content {
      .expression-grid {
        gap: 20px;
        padding-inline: 20px;
        padding: 10px;

        .add-term-btn {
          @extend %pathway-button-design;
          width: 90px;
        }
      }
    }
  }
}

.enumerated-editor {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px;
  gap: 16px;

  .inner-values {
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    align-items: flex-start;

    .inner-value {

      &.existing {
        @include styles.tag(styles.dark-green(7), styles.dark-green(2));
        overflow: hidden;

        span {
          color: styles.dark-green(2);
        }

        .ts-button {
          &:hover {
            background: styles.dark-green(5);
          }

          &:active {
            background: styles.dark-green(4);
          }
        }

        .icon--wrapper {
          path {
            fill: styles.dark-green(2);
            stroke: styles.dark-green(2);
          }
        }
      }

      &:not(.existing) {
        @include styles.tag(styles.dark-blue(7), styles.dark-blue(2));

        span {
          color: styles.dark-blue(2);
        }

        .ts-button {
          &:hover {
            background: styles.dark-blue(5);
          }

          &:active {
            background: styles.dark-blue(4);
          }
        }

        .icon--wrapper {
          path {
            fill: styles.dark-blue(2);
            stroke: styles.dark-blue(2);
          }
        }
      }

      padding-left: 0 !important;

      span {
        height: 100%;
        display: flex;
        align-items: center;
        font: {
          weight: bold;
          size: 13px;
        };
      }

      .ts-button {
        height: 100%;
        width: 26px;
        margin: 0 5px 0 0;
        padding: 0 0 0 2px;
        border-radius: 8px 0 0 8px;
        align-items: center;
        justify-content: center;
        background: transparent;
        min-width: unset;

        .icon--wrapper {
          width: 10px;
          height: 10px;
          cursor: pointer;

          path {
            stroke-width: 1;
          }
        }
      }
    }

    .ts-dropdown {
      @include styles.fancyDropDown;
      width: 220px;
      height: 28px;
      cursor: pointer;

      .ts-dropdown__header {
        background: styles.dark-green(7);
        border: 2px solid styles.dark-green(2) !important;

        .caret {
          height: 100%;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 5px;
          transition: transform 200ms linear;

          path[data-name="Path 12166"] {
            fill: styles.dark-green(2);
            stroke: styles.dark-green(2);
            stroke-width: 1;
          }

          &.close {
            transform: rotateX(180deg);
          }
        }

        .ts-dropdown__placeholder {
          color: styles.dark-green(2);
          padding: 0 5px;
          font: {
            weight: bold;
            size: 13px
          }
        }
      }

      .ts-dropdown__empty {
        font-size: 13px;
        background: styles.dark-blue(7);
        border: 2px solid styles.dark-blue(2);
        padding-top: 15px;
        transform: translateY(-15px);
      }

      .ts-dropdown__items-container {
        overflow: auto !important;
        background: styles.dark-green(7);
        border-color: styles.dark-green(2);
        border-top: 2px solid styles.dark-green(2);
        padding-top: 0;
        translate: 0 -2px;
        transform: none;

        .ts-dropdown__items {
          .ts-tree__children-container {
            .ts-tree__node {
              .node-data {
                color: styles.dark-green(2);
                font-size: 13px;
                white-space: pre-wrap;
              }

              &:hover {
                background: styles.dark-green(6);
              }
            }
          }
        }
      }
    }
  }
}