@use "@app/styles" as styles;
@use "@nu-art/ts-styles" as ts-styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;


.resolution-function-card {
  padding-right: 70px !important;
  min-width: 800px !important;
  position: relative;
  gap: 20px;
  transition: border 0.5s ease;
  display: flex;
  flex-flow: column;

  .card-title {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }

  .ts-editable-item-status {
    font-size: 14px;
    color: #33333388;
  }

  .transition-drag-handle {
    @include ts-styles.color-svg(black);

    height: 20px;
    width: 20px;
    cursor: grab;
    -webkit-user-drag: element;
  }

  .collapse-arrow {
    cursor: pointer;
    transform: rotate(90deg);


    &.collapsed {
      transform: rotate(0deg);
    }
  }

  .resolution-function-card__save-button {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .ts-prop-renderer {
    position: relative;
    top: 2%;

    .ts-prop-renderer__label {
      .transition-title {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.15);
      }
    }

    .ts-dropdown {
      max-width: 350px;
    }
  }

  .ts-list-organizer__list {
    gap: 20px;
    margin: unset;
  }

  .term {
    .expression-sublist {
      .ts-dropdown {
        height: 40px;
      }
    }
  }

  .add-term-btn {
    margin-top: 10px;
    background: none;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    color: #2970f6;
    border: none;
    font-weight: 400;
    height: 30px;
    font-size: 14px;
    cursor: pointer;
    text-transform: none;
    width: 0;


    &:hover {
      font-weight: 600;
      background: #eff1f3;
    }
  }
}

#pop-up__resolution-function-card-actions {
  @extend %actions-popup;
}