#resource-manager-panel {
  #search-panel {
    box-shadow: none;
    height: 91%;

    #entity-filter {
      margin-top: 0;
    }

    .search-container {
      padding-top: 0;
    }
  }

  .button-container {
    padding: 20px 6% 10px 15px;
    justify-content: flex-end;

    .ts-button {
      background: #8ca5ca;
      height: 40px;
      padding-inline: 10px;
      border-radius: 8px;
      font-weight: 400;

      &:hover {
        background: rgb(108, 135, 176);
      }
    }
  }
}