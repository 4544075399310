.info-tooltip {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--color__table-item__color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help;
}

.info-tooltip__content {
  height: 100%;
  background: white;
  padding: 10px;
  max-width: 350px;
  width: max-content;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 2px solid var(--color__dialog-header-background);

  .info-tooltip__title {
    text-decoration: underline;
    font: {
      size: 14px;
      weight: 600;
    };
  }

  .info-tooltip__paragraph {
    white-space: pre-wrap;
    font: {
      size: 13px;
    }
  }
}