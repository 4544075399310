@use '@app/styles' as styles;

$dimension: 60px;
$transitionMs: 200ms;

#nav-bar-label-tooltip {
  background-color: var(--side-nav-bar__background-color-hover);
  color: var(--side-nav-bar__icon-color);
  padding: 10px;
  border-radius: 4px;
  font-size: 12px;
  max-width: unset;
}

.knowledge-manager__side-nav-bar {
  //Dimensions
  width: $dimension;
  min-width: $dimension;
  height: 100%;
  flex-shrink: 0;

  //Colors
  background-color: var(--side-nav-bar__background-color);
  color: black;

  //Display
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  //padding: 40px 0;

  transition: width $transitionMs ease-out;
  overflow: hidden;
  border-right: 2px solid var(--side-nav-bar-icon-background-click-color);

  //Children
  .side-nav-bar__nav-item {
    @include styles.mouseInteractiveBackground(var(--side-nav-bar__icon-background-color), var(--side-nav-bar__background-color-hover), var(--side-nav-bar__background-color-active));
    //Dimensions
    width: 100%;
    height: $dimension;

    //Display
    display: flex;
    padding-left: 18px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    text-decoration: none;

    //Colors
    background: var(--side-nav-bar__icon-background-color);

    //Position
    position: relative;


    //Children
    .icon--wrapper {
      @include styles.mouseInteractiveIcon(var(--side-nav-bar__icon-color), var(--side-nav-bar__icon-color), var(--side-nav-bar__icon-click-color));
      width: 20px;
      height: 20px;
    }

    .side-nav-bar__nav-item__label {
      opacity: 0;
      pointer-events: none;
      transition: opacity $transitionMs $transitionMs ease-out;
      font-weight: bold;
      color: var(--side-nav-bar__icon-color);
    }

    &.show-designator {
      .icon--wrapper {
        position: relative;

        &::after {
          content: attr(data-designator);
          height: 16px;
          min-width: 16px;
          padding: 0 2px;
          top: 55%;
          left: 48%;
          border-radius: 8px;
          background: var(--side-nav-bar__icon-color);;
          position: absolute;
          color: var(--side-nav-bar__icon-background-color);
          border: 1px solid var(--side-nav-bar__icon-background-color);
          font: {
            size: 8px;
            weight: bold;
          }
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          text-transform: uppercase;
        }
      }
    }

    &:active, &.selected {
      background: #004A72;

      .icon--wrapper {
        @include styles.mouseInteractiveIcon(var(--side-nav-bar__icon-click-color), var(--side-nav-bar__icon-click-color));
      }

      .side-nav-bar__nav-item__label {
        color: var(--side-nav-bar__icon-click-color);
      }

      &::after {
        color: #004A72;
        border-color: #004A72;
        background: var(--side-nav-bar__icon-click-color);
      }
    }
  }

  &.open {
    width: 280px;

    .side-nav-bar__nav-item > .side-nav-bar__nav-item__label {
      opacity: 1;
      pointer-events: all;
    }
  }
}