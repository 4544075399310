@use '@app/styles' as styles;

#toast__new-app-version {
  background: white;
  border: 1px solid styles.dark-blue(2);
  padding: 10px 20px;
  gap: 20px;
  border-radius: 10px;

  .icon--wrapper {
    @include styles.mouseInteractiveIcon(styles.dark-blue(2));
    width: 30px;
    height: 30px;
    padding: 2px;
    border: 2px solid styles.dark-blue(2);
    border-radius: 50%;
  }

  .toast__new-app-version__info {
    .toast__new-app-version__text {
      font: {
        size: 14px;
      }
    }

    .ts-busy-button {
      font: {
        size: 14px;
      }
      color: styles.dark-blue(2);
      background: transparent;
      margin: 0;
      padding: 0;
    }
  }
}