$input-border: 1px solid black;
$border-radius: 5px;
$line-height: 30px;

.epidemiology-item-editor {
  grid-template-columns: 100px 1fr 1fr;
  column-gap: 10px;

  &.edit-mode {
    row-gap: 5px;
  }

  .epidemiology-item-editor__data {
    line-height: $line-height;
    font: {
      size: 14px;
    }

    &.span-row {
      grid-column: 2/-1;
    }
  }

  .ts-dropdown {
    height: $line-height;
    border: $input-border;
    border-radius: $border-radius;

    .ts-dropdown__header {
      border: none;
      background: transparent;
      align-items: center;
      padding-right: 5px;
    }
  }

  .q-range-inputs {
    gap: 5px;
    height: $line-height;

    .ts-input {
      border: $input-border;
      border-radius: $border-radius;
      height: $line-height;
      min-height: unset;
      font-size: 14px;
      outline: none !important;
    }

    .q-range-inputs__divider {
      font-size: 26px;
      line-height: $line-height;
    }
  }

  .ts-prop-renderer__label {
    line-height: $line-height;
    margin-bottom: 0 !important;
    font: {
      size: 14px;
    }
  }

  .epidemiology-item-editor__percentage-input {
    border: $input-border;
    border-radius: $border-radius;
    height: $line-height;
    gap: 5px;
    padding-right: 5px;

    .ts-input {
      height: $line-height;
      min-height: unset;
      border: none;
      font-size: 14px;
      flex-grow: 1;
      width: 0;
      min-width: unset;
      outline: none !important;
    }

    .epidemiology-item-editor__percentage-input__icon {
      font: {
        size: 14px;
        weight: bold;
      }
      flex-shrink: 0;
    }
  }
}