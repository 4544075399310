@use 'sass:math';
@use 'sass:list';

//(Dir en) Grey
$darker_grey: #504c4c;
$almost-pure-grey: #707070;
$sidewalk_grey: #8A959B;
$very-light-grey: #F7F7F7;
$scroll-track-background-grey: #eeeeee;
$scroll-thumb-grey: #cccccc;

//Others
$white: white;
$very_pastel_teal: #d2e0e2;
$very-light-cyan: #f2f8ff;
$near_white: #f0f0f0;
$very_pastel_grey: #e5e9ee;

//Uniques
$unique_light_blue: #88DFFF;
$unique_green: #4FC63C;
$unique_orange: #FFA808;
$unique_red: #F35B5B;
$unique_glowing_red: #FF3D64;
$unique_turquoise: #1DDBC6;
$unique_green_select: #D6EEF0;
$unique_light_blue_select: #F2F8FF;
