@use '../../../index' as styles;

.search-result {
  @include styles.mouseInteractiveBackground(white, #f7f7f7);
  height: 100%;
  width: 100%;
  padding: 0 20px;
  justify-content: center;
  gap: 10px;
  border: 1px solid #e9e9e9;
  border-bottom: none;
  text-transform: capitalize;

  .search-result__item {
    text-transform: inherit;
    gap: 5px;

    .icon--wrapper {
      width: 20px;
      height: 20px;
    }
  }

  .search-result__data {
    width: 100%;
    font-size: 10px;
    color: #484646;
    gap: 5px;

    .search-result__type {
      background: white;
      border-radius: 5px;
      border: 1px solid #DEE4EF;
      padding: 4px;
      font-size: inherit;
    }

    .search-result__hierarchy {
      font-size: inherit;
      padding: 4px;
      border-radius: 5px;
      border: 1px solid #DEE4EF;
      background: #E3E9F2;
    }
  }

  &.selected {
    background: #f6f8fc;
  }

  &.error {
    @include styles.mouseInteractiveBackground(styles.red(7), styles.red(6));

    &.selected {
      background: styles.red(6);
    }
  }

  &.open-discussion {
    position: relative;

    &::after {
      content: '';
      background: #f24822;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      right: 10px;
      translate: 0 -50%;
    }
  }
}