@use "@app/styles" as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;


.resolution-function-editor-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 300px;
  background: white;
  box-shadow: 2px 2px 6px 0 #aec1da;
  border-radius: 4px;
  padding: 22px 17px;

  .resolution-function-editor-panel__header {
    color: $font-color;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .resolution-function-editor-panel__buttons {
    margin-top: auto;

    .ts-button {
      height: 30px;
      width: 50px;
      background: #eff3f5;
      border: 1px solid #E3E9F2;
      padding-block: 10px;

      &:hover {
        background: #E3E9F2;
      }
    }
  }
}