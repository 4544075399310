.q-toast {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: white;
  border-radius: 6px;
  border: 2px solid black;

  &.error {
    border-color: red;
  }
}