@mixin device-css-vars__default {
  //Defaults
  --size__font-default: 1rem;

  //Scrollbar
  --size__scrollbar-width: 5px;
  --size__scrollbar-width-hover: 8px;

  //Border
  --size__border-size-default: 1px;

  //Workspace
  --workspace__default-row-height: 3.125rem;
  --workspace__default-font-size: 0.875rem;
  --workspace__header-font-size: 1rem;
  --workspace__header-font-weight: 600;

  --disease-profile-min-width: 16px;
  --disease-profile-max-width: 26px;
}