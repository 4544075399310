.draggable-table-container {
  width: 500px;
  margin: 15px 10px;

  .draggable-table {
    width: 100%;
    background: #808080;

    border: 1px solid black;
    border-collapse: collapse;

    th, td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    tr {
      background: white;
      height: 40px;
      -webkit-user-drag: element;

      &.dragging {
        position: relative;
        cursor: grabbing;
      }
    }
  }
}