@use '../../functions-and-mixins' as mixins;

.ts-button {
  //Dimensions
  min-width: 80px;
  min-height: 30px;
  padding: 4px;
  margin-inline: 8px;

  //Border
  border-radius: 3px;
  cursor: pointer;

  //Text
  text-align: center;
  font: {
    size: 14px;
    weight: 600;
    family: Arial;
  }

  //Colors
  color: var(--button__text-color);
  background: var(--button__background-color);

  //Animation
  transition: background 100ms linear;

  //Pseudos
  &:hover {
    background: var(--button__background-color-hover);
  }

  &:active {
    background: var(--button__background-color-active);
  }

  //Concat Classes
  &.desired-action-button {
    color: var(--button__text-color-desired);
    background: var(--button__background-color-desired);
    font: {
      size: 14px;
      family: Arial;
      weight: normal;
    }

    &:hover {
      background: var(--button__background-color-desired-hover);
    }

    &:active {
      background: var(--button__background-color-desired-active);
    }
  }

  &.ts-button__disabled {
    background: var(--color__dialog__button-background-disabled);

    &:hover, &:active {
      background: var(--color__dialog__button-background-disabled);
    }
  }
}