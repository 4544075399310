@use '@nu-art/ts-styles' as styles;
@use '@app/styles' as app-styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.term, .expression-card .term {
  gap: 20px;
  height: 100%;
  width: 100%;
  align-items: flex-start;

  .term-editor__value-input {
    width: 100%;
    justify-content: space-between;
    margin-left: 29px;

    .value-input__value-label {
      color: $font-color;
      font-size: 14px;
    }

    .value-input__input-container {
      margin-right: 88px;
    }

    .ts-input {
      height: 40px;
      width: 90px;
    }

    &.no-margin {
      margin: 5px;

      .value-input__input-container {
        margin-right: 65px;
      }
    }
  }

  .ts-checkbox.quai {
    .ts-checkbox__button.ts-checkbox__button__checked {
      background: #016fa0;
      border: 1px solid #016fa0;
    }

    .ts-checkbox__content {
      font-weight: normal;
    }
  }

  .ts-dropdown.variable-dropdown {
    width: 300px;

    .variable-dropdown-row {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      gap: 15px;
      font-size: 14px;
    }

    .lefthand-type-tag {
      font-size: 14px;
      padding: 3px;
      border-radius: 4px;
      border: 1px #e9e9e9 solid;
      background-color: white;
    }

    .ts-dropdown__header {
      .input-with-label {
        position: relative;
        display: inline-block;
        width: 100%;

        .ts-input {
          color: $font-color;
          font-weight: normal;
          font-size: 14px;

        }

        .ts-dropdown__selected {
          position: absolute;
          top: 0;
          pointer-events: none;
        }

        &.ts-input:focus + .ts-dropdown__selected, .ts-input:not(:placeholder-shown) + .ts-dropdown__selected {
          display: none;
        }
      }

      .ts-dropdown__selected {
        width: 100%;
      }
    }
  }

  .ts-dropdown.operator-dropdown {
    height: 40px;
    min-width: 135px;
    max-width: 135px;
  }

  .expression-sublist {
    height: 100%;
    justify-content: flex-start;
    gap: 10px;


    .ts-input {
      width: 170px;
      height: 40px;
      min-height: unset;

      &.value-input {
        width: 75px;
      }
    }

    .ts-dropdown {
      width: 170px;
    }

    .inputs-container {
      gap: 6px;
    }

    .term-remove-icon {
      margin-inline: 8px 5px;
      @include app-styles.mouseInteractiveIcon(black);
    }

    .ts-multi-select__list {
      flex-flow: column-reverse;

      .ts-multi-select__list-value {

        .value-label {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 14px;
          width: 130px;
        }
      }
    }
  }


  .delete-wrapper {
    height: 40px;
    width: 40px;
    margin-left: 10px;
    cursor: pointer;

    .expression-editor__close-icon {
      @include styles.color-svg(#AAAAAA);
      border-radius: 4px;
      height: 16px;
      width: 16px;
      padding: 2px;
      transition: all 0.1s ease;

      &:hover {
        @include styles.color-svg(#4F4F4F);
        background-color: #e3e9f2;
      }
    }

    .icon--wrapper {
      cursor: pointer;
    }
  }

  .row-content {
    width: 100%;
    height: 100%;
    align-items: flex-start;

    .ts-dropdown {
      height: 35px;

      .ts-dropdown__header {
        height: 35px;

        .ts-input {
          height: 35px;
          border: none;
        }
      }

    }
  }

  .bridge {
    height: 100%;
    border-bottom: 2px solid #016fa0;
    border-top: 2px solid #016fa0;
    border-left: 2px solid #016fa0;
    justify-content: center;
    width: 25px;

    .logic-picker {
      align-items: center;
      transform: translateX(-50%);
      border-radius: 4px;
      height: 30px;
      padding: 4px;
      color: #333333;
      cursor: pointer;
      font-size: 13px;

      &.and {
        background: #f6cf97;


        &:hover {
          background: #e3e9f2;
        }

        &.disabled {
          cursor: default;

          &:hover {
            background: #f6cf97;
          }
        }
      }


      &.or {
        background: #e3e9f2;

        &:hover {
          background: #f6cf97;
        }

        &.disabled {
          cursor: default;

          &:hover {
            background: #e3e9f2;
          }
        }
      }
    }
  }


  .term-btn {
    background: none;
    color: #016fa0;
    font-size: 14px;
    font-weight: 400;
    min-height: 30px;
    padding: 2px 5px 2px 5px;

    &:hover {
      font-weight: 600;
    }
  }

  .condition-container {
    gap: 11px;
    height: 100%;
    width: 100%;

    .ts-list-organizer__list {
      display: flex;
    }

    .case-editor__factor-container {
      gap: 10px;

      .factor-label {
        font-size: 14px;
        color: #4f4f4f;
        font-weight: normal;
      }

      .icon--wrapper {
        @include styles.color-svg(#4f4f4f);
        height: 12px;
      }

      .ts-input {
        max-width: 60px;
      }
    }

    .ts-prop-renderer {
      gap: 5px;

      .ts-prop-renderer__label {
        width: unset;
        height: 100%;

        .atom-label-container {
          align-items: flex-start;
          transform: translateY(5px);
          height: 100%;


          .icon--wrapper {
            @include styles.color-svg(#333333);
            height: 33px;
            -webkit-user-drag: element;
            cursor: grab;

          }

          &.grabbing {
            .icon--wrapper {
              cursor: grabbing;
            }
          }

          .atom-label {
            color: #333333;
            font-weight: 400;
            padding: 6px;
            border-radius: 50%;

            &.warning {
              background-color: rgb(246 225 9 / 61%);
            }
          }
        }
      }
    }

    .label {
      font-size: 14px;
    }

    &.else-value {
      justify-content: space-between;
      align-items: center;


      .term-value-input {
        margin-right: 60px;
      }
    }


    .divider {
      border: 1px solid styles.gray(6);
      width: 0;
      height: 40px;
      margin-inline: 0 10px;
    }

    .sample-index-dropdown {
      max-width: 150px;
    }

    .label-container {
      min-width: 300px;
      gap: 10px;
      width: 100%;
    }

    .condition-value {
      &.expiry-container {
        width: 100%;
        gap: 11px;
        color: $font-color;

        .divider {
          margin-inline: 0;
        }

      }

      &.sample-constrains {
        width: 100%;
        gap: 11px;
        color: $font-color;

        .divider {
          margin-inline: 0;
        }
      }


      .time-range-editor {
        width: unset;

        .time-range-input {
          width: unset;

          .ts-dropdown {
            max-width: 100px;
          }

          .ts-input {
            max-width: 50px;

          }

          &.infinity {
            .ts-input {
              &::placeholder {
                left: 12px;
              }
            }
          }
        }
      }

      .term-value-input {
        width: 90px;
        height: 40px;
      }
    }

    .atom-editor__delta-info {
      width: 100%;
      margin-top: 10px;
      gap: 5px;
    }
  }

  .button-container {
    margin-left: 29px;

    &.single-button {
      justify-content: flex-end;
    }

    &.no-margin {
      margin-left: 5px;
    }
  }

  .ts-button.ts-button__disabled {
    background: unset;

    &:hover {
      font-weight: 400;
      cursor: not-allowed;
      background: unset;
    }
  }

  .add-entity-btn {
    background: none;
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    color: #2970f6;
    border: none;
    font-weight: 400;
    height: 30px;
    font-size: 14px;
    cursor: pointer;
    text-transform: none;


    &:hover {
      font-weight: 600;
      background: #eff1f3;
    }
  }

  .logic-editor {
    gap: 20px;
    width: 100%;
    align-items: center;

    .smart-editor-container {
      align-items: center;
    }
  }

  .term-output-type {
    gap: 10px;
    width: 15%;

    > span {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      width: 100px;
    }
  }

  .ts-grid {
    grid-template-columns: max-content;
    gap: 10px;
    padding: 0;
    width: 100%;
  }

  .ts-dropdown {
    @extend %ui-manager__ts-dropdown-design;
  }

  .ts-button, .ts-busy-button {
    @extend %pathway-button-design;
  }

  .ts-input {
    @extend %ui-manager__ts-input-design;
  }

  .term-editor__structured-free-text {
    gap: 10px;
    justify-content: center;

    .ts-prop-renderer {
      .ts-prop-renderer__label {
        color: #333333;
        font-weight: 400;
      }

      .ts-input {
        min-width: unset;
        width: 150px;
        font-family: monospace;
      }

      .ts-dropdown {
        width: 150px;
      }
    }
  }
}

#input-error-tooltip {
  border: 1px solid red;
  max-width: 250px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
}

.ts-tooltip__content {
  .overview-tooltip {
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.6);
  }
}