@use '@app/styles' as styles;

$font-family: 'Open Sans', sans-serif;
$font-color: #333;
$border-radius: 4px;
$border-color: #e3e9f2;

#manager__variable {
  width: 100%;
  height: 100%;
  grid-template-areas:
          "header header"
          "sections info";
  grid-template-columns: 200px 1fr;
  grid-template-rows: 80px 1fr;

  .manager__header {
    height: 80px;

    .discussion-icon {
      @include styles.mouseInteractiveIcon(#29486c);
      width: 20px;
      height: 20px;
    }
  }

  .manager__sections {
    grid-area: sections;
    border-right: 1px solid #e3e9f2;

    .manager__section {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      height: 50px;
      width: 100%;
      padding: 0 10px;

      flex-shrink: 0;
      display: flex;
      align-items: center;
      white-space: pre-wrap;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      &.selected {
        background: #f6f8fc;
        border-top: 1px solid #e3e9f2;
        border-bottom: 1px solid #e3e9f2;
      }
    }
  }

  .manager__variable__info {
    height: fit-content;
    grid-area: info;
    padding: 14px 40px 14px 20px;

    .sub-title {
      padding-left: 15px;
    }

    .section-point__values {
      flex-wrap: wrap;
      gap: 20px;
      grid-column: 1/-1;

      .data-grid__data {
        border: 1px solid $font-color;
        padding: 4px 8px;
        border-radius: $border-radius;
      }
    }
  }
}