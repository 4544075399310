.references-list {
  width: 100%;

  .ts-table {
    table-layout: fixed;
    height: 100%;
    border: none;
    overflow-x: auto;
    border-collapse: collapse;

    .ts-table__head {
      position: sticky;
      top: 0;
      z-index: 1;

      .ts-table__tr {
        height: var(--workspace__default-row-height);
        border: none;
        border-bottom: 2px solid var(--color__table-row__border);

        .ts-table__th {
          padding-left: 5px;
          border: none;

          &:not(:last-child) {
            border-right: 2px solid var(--color__table-row__border);
          }

          .th-data {
            font-size: var(--workspace__default-font-size);
            font-weight: var(--workspace__header-font-weight);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    .ts-table__body .ts-table__tr {
      background: var(--color__background__general-panel);
      height: var(--workspace__default-row-height);
      border-bottom: 1px solid var(--color__table-row__border);
      cursor: pointer;

      .ts-table__td {
        padding-left: 5px;
        border: none;

        &:not(:last-child) {
          border-right: 1px solid var(--color__table-row__border);
        }

        .td-data {
          font-size: var(--workspace__default-font-size);
          white-space: pre-wrap;
          overflow: hidden;

          .ts-link {
            font-size: var(--workspace__default-font-size);
            max-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      td.ts-table__td {
        border-top: 1px solid transparent;
        border-left: 1px solid transparent;
      }

      &:hover {
        outline: var(--ws__border__item-hover);
        box-shadow: var(--ws__shadow__item-hover);
        position: relative;
        z-index: 1;
      }

      &.selected {
        background: var(--ws__color__item-selected);
      }

      &.invalid-reference {
        background: #da4375;
      }

      &.edit-blocked {
        pointer-events: none;
      }
    }
  }
}