@use '@app/styles' as styles;

.q-color-picker-background-foreground {
  position: relative;

  .colors-preview {
    width: 25px;
    height: 25px;
    border: 2px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin-inline: 10px;
  }

}

.ts-popup.ts-overlay {
  background: transparent;

  .ts-popup__content {
    overflow: visible;
    box-shadow: none;

    .colors-picker {
      border: 1px solid black;
      border-radius: 4px;
      overflow: hidden;

      .ts-tabs {

        .ts-tabs__tabs-header {
          height: unset;

          .ts-tabs__tab {
            padding: 5px 0;
            font-size: 14px;
            background: white;
            border: none;

            &.ts-tabs__focused {
              background: white;
              color: var(--color__text__focused-blue);
              border-bottom: var(--border__general-solid-light);
            }
          }
        }

        .ts-tabs__content {
          @include styles.customScrollbar('vertical');
          padding: 10px;
          background: white;
          border: none;
          max-height: 120px;

          .colors-container {
            gap: 10px;
            flex-wrap: wrap;

            .color-preview {
              cursor: pointer;
              width: 20px;
              height: 20px;
              border: 1px solid black;

              &.selected {
                border: var(--border__general-solid-light);
              }
            }
          }
        }
      }


      .foreground-color-preview {
        width: 20px;
        height: 20px;
      }

      &.show {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

}
