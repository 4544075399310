@use '../functions-and-mixins/colors' as colors;
@use '../functions-and-mixins/palette' as palette;
@use "../components/Dialogs/lightTheme" as *;
@use "../components/TS_PropRenderer/lightTheme" as *;
@use "../components/TS_BusyButton/lightTheme" as *;
@use "../components/TS_Button/lightTheme" as *;
@use "../components/TS_Checkbox/lightTheme" as *;
@use "../components/TS_Dropdown/lightTheme" as *;
@use "../components/TS_Input/lightTheme" as *;
@use "../components/TS_Radio/lightTheme" as *;
@use "../components/TS_Table/lightTheme" as *;
@use "../components/TS_Tabs/lightTheme" as *;
@use "../components/TS_TextArea/lightTheme" as *;
@use "../components/TS_Tooltip/lightTheme" as *;
@use "../components/TS_Tree/lightTheme" as *;
@use "../components/TS_Toast/lightTheme" as *;

@mixin theme-css-vars_light {
  @include light-theme__ts-prop-renderer;
  @include light-theme__dialogs;
  @include light-theme__ts-tooltip;
  @include light-theme__ts-table;
  @include light-theme__ts-tabs;
  @include light-theme__ts-textarea;
  @include light-theme__ts-input;
  @include light-theme__ts-dropdown;
  @include light-theme__ts-button;
  @include light-theme__ts-busy-button;
  @include light-theme__ts-tree;
  @include light-theme__ts-toast;

  --color__text__placeholder-1: #{palette.black(6)};
  --color__text__placeholder-2: #{palette.black(5)};
  --color__text__gray: #{palette.black(4)};
  --color__sub-header__font-color: #{palette.dark-blue(1)};

  //General Background Colors
  --color__background__general-panel: white;
  --color__background__gray: #{palette.black(4)};

  --color__variables-search-bar-background: #{palette.dark-green(6)};


  //Dialog
  --color__dialog-header-background: #{palette.dark-blue(2)};
  --color__dialog__button-background-disabled: #{colors.$very-light-grey};

  //tables
  --border__values-table-regular: 1px solid #{palette.black(5)};
  --color__table-row__border: #dbdbdb;

  //Scrollbar
  --color__scrollbar-track: #{colors.$scroll-track-background-grey};
  --color__scrollbar-thumb: #{colors.$scroll-thumb-grey};
  --color__scrollbar-thumb-hover: #{palette.dark-blue(7)};

  //Headers
  --color__header__categories: #{palette.ochre(7)};
  --color__header__categories-search: #{palette.ochre(6)};
  --color__header__values: #{palette.gray-blue(7)};
  --color__header__values-search: #{palette.gray-blue(6)};
  --color__header__search-icon: black;
  --color__header__search-text: black;

  //Tooltips
  --color__tooltip__variable-information: #{palette.dark-blue(2)};

  //Popup Menu
  --color__popup-menu-item-hover: #{palette.dark-blue(7)};
  --color__glowing_red: #{colors.$unique_glowing_red};
  --color__turquoise: #{colors.$unique_turquoise};
  --color__selected_row: #{colors.$unique_green_select};

  //Workspace
  --ws__border__item-hover: 1px solid #4CBBE5;
  --ws__color__item-selected: #{palette.dark-blue(7)};
  --ws__shadow__item-hover: 0px 3px 6px #00000029;
  --color__disease-value-count__background: #a0a0a0;
  --color__list-seperator: #{palette.black(1)};


  //################### Advisor ###################
  --advisor__color__background-basic: #F3F6F7;
  --advisor__color__panel-separator: #2699FB;
}