@use '../../functions-and-mixins' as styles;

.ts-editable-text-area,
.ts-editable-text-input {

  .ts-button .icon--wrapper {
    width: 16px;
    height: 16px;

    svg path {
      fill: white;
    }
  }

  .ts-editable-text__buttons__save {
    @include styles.mouseInteractiveBackground(styles.dark-green(3), styles.dark-green(2), styles.dark-green(1));
  }

  .ts-editable-text__buttons__cancel {
    @include styles.mouseInteractiveBackground(styles.red(3), styles.red(2), styles.red(1));
  }

  .ts-editable-text__buttons__reset {
    @include styles.mouseInteractiveBackground(styles.dark-blue(3), styles.dark-blue(2), styles.dark-blue(1));
  }
}

.ts-editable-text-area.advisor {
  .ts-editable-text-area__edit {
    .ts-editable-text-area__text-area {
      border: 1px solid #207AFF;
      border-radius: 4px;
      resize: none;
      padding: 4px;
    }

    .ts-editable-text-area__buttons {
      justify-content: space-between;

      .ts-busy-button, .ts-button {
        margin: 0;
        border: none;
        background: transparent;
        color: #0067FF;
        gap: 4px;
      }
    }
  }
}

.ts-editable-text-area {
  width: 100%;

  .ts-editable-text-area__text-area {
    @include styles.customScrollbar('vertical');
    width: 100%;
    height: 0;
    padding-inline: 0;
    min-height: 90px;
    background: white;
    font-weight: normal;
    box-shadow: none;
    font: {
      size: 14px;
      weight: 400;
    }
    line-height: 22px;
    color: #202020;
  }

  .ts-editable-text-area__buttons {
    .ts-editable-text__buttons__cancel {
      @include styles.advisor-button-colors;
      color: black;
      font: {
        size: 14px;
        weight: normal;
      }
    }

    .ts-editable-text__buttons__save {
      @include styles.advisor-button-colors;
      background: styles.dark-blue(2);
      font: {
        size: 14px;
        weight: normal;
      }

      &:hover {
        background: styles.dark-blue(1);
      }
    }

    .ts-editable-text__buttons__reset {
      @include styles.advisor-button-colors;
      @include styles.mouseInteractiveIcon(black);
    }
  }

  .ts-editable-text__text {
    width: 100%;
    white-space: break-spaces;
    color: #202020;
    font: {
      size: 14px;
      weight: 400;
    }
  }
}

.ts-editable-text-input {
  .ts-editable-text-input__edit {
    .ts-editable-text-input__buttons {
      gap: 0;
    }
  }

  .ts-input {
    font-size: 14px;
  }

  .ts-button {
    padding: 0;
    margin: 0;
  }
}