@use '../../functions-and-mixins/palette' as palette;

$borderRadius: 3px;

.dialog {
  width: 800px;
  min-height: 200px;
  background: var(--dialog__general-background);
  border-radius: $borderRadius;

  .dialog__header {
    //Dimensions
    width: 100%;
    height: 50px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    //Border
    border-radius: $borderRadius $borderRadius 0 0;

    //Colors
    background: var(--dialog__header-background);

    .title-text {
      font: {
        size: 1.25rem;
        weight: 600;
      }
      color: var(--dialog__header-text-color);
    }

    .exit_icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .dialog__main {
    min-height: 400px;
    flex-grow: 1;
    width: 100%;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;

    &.ll_h_c {
      align-items: stretch;
    }

    .dialog__main__section {
      &:not(.no-grow) {
        flex: 1;
      }

      &.ll_v_l {
        padding: 0 8px;
        flex-basis: 0;

        &:not(:first-child) {
          border-left: 1px solid palette.dark-blue(2);
        }
      }

      &.ll_h_c {
        width: 100%;
      }

      .ts-prop-renderer {
        padding: 8px 0;
      }
    }
  }

  .dialog__buttons {
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    flex-shrink: 0;
  }

  .dialog__field-title, .ts-prop-renderer__label {
    font: {
      size: 0.8125rem;
      weight: 600;
    }
    color: var(--dialog__field-text-color);
  }
}