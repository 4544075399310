@use '../../functions-and-mixins/palette' as palette;

@mixin dark-theme__ts-table {
  //Header
  --table__header-text-color: #000000;
  --table__header-background-color: #{palette.gray(6)};

  //Body
  --table__body-text-color: #000000;

  //Row
  --table__body-row-background-color: #FFFFFF;
  --table__body-row-background-selected: #{palette.dark-blue(6)};
}