@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

#editor-organization-general-information {
  grid-column: data;
  height: 100%;
  padding: 0 20px;
  gap: 20px;

  //Form Grid
  .ts-grid {
    @extend %prop-renderer-base-design;
    grid-template-columns: max-content 1fr;
    row-gap: 10px;
    column-gap: 40px;
    width: 100%;

    .grid-data {
      font-size: 14px;
      line-height: 30px;
      color: $font-color;
    }
  }

  &.edit-mode {
    .ts-prop-renderer__label {
      min-width: 100px;
    }

    .ts-grid {
      row-gap: 20px;
    }
  }

  &:not(.edit-mode) {
    .ts-prop-renderer__label {
      line-height: 30px;
    }
  }

  .ts-input, .ts-dropdown {
    height: 40px;
  }
}