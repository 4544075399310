.manager-subheader {
  position: sticky;
  top: 0;
  background: #EFF3F5;
  padding: 7px;
  height: fit-content;
  justify-content: flex-end;
  gap: 5px;
  border-bottom: 1px solid #e3e9f2;
  min-height: 55px;

  .selected {
    background: #6C87B0FF !important;
    color: white !important;

    &:hover {
      color: black !important;
      background: white !important;
    }
  }

  .ts-button {
    background: white;
    font-size: 12px;
    border-radius: 5px;
    color: inherit;
    padding: 2px 10px 2px 10px;
    height: 40px;
    font-weight: 400;
    border: 1px #e3e9f2 solid;
    margin-inline: 0;

    &:hover {
      background: #6C87B0FF;
      color: white;
    }
  }

}