@use '@app/styles' as *;

$colorChunks: 7;
@mixin createColorClasses($name,$limit) {
  @for $i from 1 through $limit {
    .#{$name}-#{$i} {
      @if $name == 'black' {
        background: black($i);
      }
      @if $name == 'gray-blue' {
        background: gray-blue($i);
      }
      @if $name == 'dark-blue' {
        background: dark-blue($i);
      }
      @if $name == 'dark-green' {
        background: dark-green($i);
      }
      @if $name == 'purple' {
        background: purple($i);
      }
      @if $name == 'pink' {
        background: pink($i);
      }
      @if $name == 'ochre' {
        background: ochre($i);
      }
      @if $name == 'orange' {
        background: orange($i);
      }
      @if $name == 'red' {
        background: red($i);
      }
      @if $name == 'monochromatic' {
        background: monochromatic($i);
      }
      width: 75px;
      height: 75px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }
}

.palette-container {
  height: 100%;
  padding: 20px;

  .square {
    width: 75px;
    height: 75px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre;
  }

  .palette-container__row {
    width: 100%;
    align-items: flex-start;
  }

  .cold-container {
    flex: 1;
    align-items: flex-start;
    gap: 20px;

    .color-row {
      width: 100%;
      display: flex;
      @include createColorClasses('black', $colorChunks);
      @include createColorClasses('gray-blue', $colorChunks);
      @include createColorClasses('dark-blue', $colorChunks);
      @include createColorClasses('dark-green', $colorChunks);
      @include createColorClasses('blue', $colorChunks);
      @include createColorClasses('purple', $colorChunks);
    }
  }

  .warm-container {
    width: 100%;
    align-items: flex-start;
    gap: 20px;
    flex: 1;

    .color-row {
      width: 100%;
      display: flex;
      @include createColorClasses('pink', $colorChunks);
      @include createColorClasses('ochre', $colorChunks);
      @include createColorClasses('orange', $colorChunks);
      @include createColorClasses('red', $colorChunks);
      @include createColorClasses('monochromatic', $colorChunks)
    }
  }
  
  .gradient-container {
    align-items: flex-start;

    .gradients {
      display: flex;
      justify-content: flex-start;
      gap: 30px;

      & > div {
        width: 50px;
        height: 50px;
      }

      .blue-gradient {
        background: linear-gradient(0deg, #66D7F7 0%, #54D2F5 8%, #35CAF4 23%, #1EC3F2 40%, #0DBFF1 57%, #03BCF1 76%, #00BCF1 100%);
      }

      .green-gradient {
        background: linear-gradient(180deg, #4DB5BD 0%, #BBD9D6 100%);
      }

      .orange-gradient {
        background: linear-gradient(180deg, #FBD140 0%, #FBD140 100%)
      }

      .blue-inverted-gradient {
        background: linear-gradient(180deg, #66D7F7 0%, #54D2F5 8%, #35CAF4 23%, #1EC3F2 40%, #0DBFF1 57%, #03BCF1 76%, #00BCF1 100%);
      }

      .blue--gray-gradient {
        background: linear-gradient(180deg, #d9e6ef 0%, #d9ebf5 100%);
      }

      .gray-gradient {
        background: linear-gradient(0deg, #ECF8FF 0%, #d9ebf5 100%);
      }
    }

  }
}