@use '@app/styles' as styles;

.sub-header__status {
  .disease-dropdown {
    flex-grow: 1;
    //Dropdown
    .ts-dropdown {
      min-width: 300px;
      height: 25px;
      width: unset;
      flex-shrink: 0;

      .ts-dropdown__header {
        height: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid black;

        .ts-dropdown__placeholder {
          font-size: var(--workspace__default-font-size);
        }

        .ts-input {
          min-height: unset;
          font-size: 14px;
        }
      }

      .disease-node {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 30px;

        .disease-node__label {
          font-size: var(--workspace__default-font-size);
        }

        .disease-node__initials-container {
          width: fit-content;

          .dp-icon {
            @include styles.disease-profile-initials-icon;
          }
        }
      }
    }

    //Buttons
    .disease-dropdown__buttons {
      flex-grow: 1;
      margin: 0 20px;
      gap: 30px;

      .add-disease-profile {
        width: 20px;
        height: 20px;
        font: {
          size: 18px;
          weight: bold;
        }
        border: 2px solid black;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon--wrapper {
        cursor: pointer;
      }
    }

    //More Button
    .disease-dropdown__more-button {
      cursor: pointer;
      margin-right: 10px;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  .ts-drag-and-drop {
    width: 30px;
    height: 30px;
    padding: unset;
    border-radius: unset;

    .ts-drag-and-drop__idle {
      border: unset;
    }
  }
}