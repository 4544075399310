.formdata-group {
  &.ts-card {
    min-width: unset;
    max-width: 60%;
  }

  .q-scroll-wrapper {
    height: unset;
    max-height: 600px;
  }

  .top-prop {
    align-items: start;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .ts-multi-select__list {
    .ts-multi-select__list-value {
      border: 1px solid #e3e9f2;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:nth-last-child(2) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}