@use '../../functions-and-mixins/palette' as palette;

@mixin light-theme__ts-tabs {
  //Header
  --tabs__header-background-color: #FFFFFF;

  //Individual tabs
  --tabs__tab-border-bottom: 2px solid transparent;
  --tabs__tab-border-bottom-focused: 2px solid #{palette.dark-blue(2)};

  --tabs__tab-text-color: #{palette.black(4)};
  --tabs__tab-text-color-selected: #{palette.dark-blue(2)};

  --tabs__tab-background-color: #{palette.gray(6)};
  --tabs__tab-background-color-selected: #FFFFFF;
  --tabs__tab-background-color-hover: #{palette.gray(5)};
  --tabs__tab-background-color-active: #{palette.gray(4)};

  //Content container
  --tabs__content-background-color: #FFFFFF;
}
