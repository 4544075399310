@use '@app/styles' as styles;

@mixin spinners($type) {
  height: 100%;
  width: 100%;
  position: relative;

  @if $type == 'basic' {
    margin: 0 auto;
    position: relative;
    -webkit-animation: basic-spinner__rotation 1s infinite linear;
    -moz-animation: basic-spinner__rotation 1s infinite linear;
    -o-animation: basic-spinner__rotation 1s infinite linear;
    animation: basic-spinner__rotation 1s infinite linear;
    border: 6px solid rgba(0, 0, 0, 8%);
    border-radius: 100%;

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: -6px;
      top: -6px;
      height: 100%;
      width: 100%;
      border-top: 6px solid styles.dark-blue(1);
      border-left: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-right: 6px solid transparent;
      border-radius: 100%;
    }
  }
}

.qloader {
  width: 100px;
  height: 100px;

  .spinner {
    &.basic {
      @include spinners('basic')
    }
  }
}

//Animations
//***Basic Spinner***
@-webkit-keyframes basic-spinner__rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes basic-spinner__rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes basic-spinner__rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes basic-spinner__rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}