@use "@app/styles" as styles;

#hcs-variables {

  .resolution-function-editor-panel__body {
    @include styles.customScrollbar('vertical');
    gap: 10px;
    height: 100%;

    .not-found-title {
      font-size: 14px;
      color: #333333;

    }

    .var-name {
      font-size: 14px;
      color: #333333;
      cursor: pointer;
      white-space: break-spaces;

      &:hover {
        color: #2970f6;
      }
    }

  }
}