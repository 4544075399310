@use '@app/styles' as styles;

.variable-selector {
  width: 100%;

  .ts-dropdown,
  .variable-name {
    width: unset;
    flex-grow: 1;
  }

  .edit-button {
    min-width: unset;
    padding: 5px;

    border-radius: 8px;
    border: 1px solid black;
    transition: all 200ms linear;
    background: white;

    .icon--wrapper {
      width: 20px;
      height: 20px;

      svg path {
        transition: all 200ms linear;
      }
    }

    &:hover {
      background: styles.dark-blue(2);
      border: 1px solid styles.dark-blue(2);

      .icon--wrapper svg path {
        fill: white
      }
    }

    &.ts-button__disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}