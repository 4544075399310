@use '@app/styles' as styles;

#resolve-dependency-conflicts-panel {
  max-width: 400px;
  min-width: 300px;
  //width: 300px;
  height: 94.5%;
  position: absolute;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border: 0.5px solid #006fab;
  box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.43);

  .header-position {
    position: sticky;
    top: 0
  }

  .header-title {
    font-size: 0.8rem;
    white-space: break-spaces;
    color: white;
  }

  .target-to-delete {
    &:not(:last-child) {
      border-bottom: 12px solid #ccc;
    }

    width: 100%;
    padding: 4px;

    .target_header {
      gap: 8px;

      .ts-prop-renderer {
        margin-bottom: 8px;
      }

      .target-collection-name {
        padding-left: 4px;
      }

      .item-label {
        padding-left: 4px;
      }
    }

    //.collapsable {
    //  width: 100%;
    //
    //  .collapsable-header {
    //    padding: 5px;
    //  }
    //
    //  .collapsable-item {
    //    white-space: break-spaces;
    //    padding: 4px;
    //  }
    //}

    .ts-collapsable-container__header {
      padding-inline: 10px;
      gap: 10px;
      box-shadow: none;
      border: none;
      border-radius: 6px;
      justify-content: flex-start;
      min-height: 40px;
      height: unset;
      align-items: center;

      .ts-collapsable-container__header__caret {
        width: unset;

        .icon--wrapper {
          @include styles.mouseInteractiveIcon(#000000);
          width: 10px;
          height: 10px;
        }

        &:not(.collapsed) .icon--wrapper {
          rotate: 90deg;
        }
      }

      .ts-collapsable-container__header__text {
        text-transform: capitalize;
      }

      &.collapsed {
        background: transparent;
        border-radius: 0;
      }
    }

    .ts-collapsable-container__container {
      background: transparent;

      .ts-collapsable-container__container-wrapper {
        min-height: 60px;
      }
    }

    .multivar-textual-row, .diseasevalue-textual-row, .value-textual-row {
      .ts-prop-renderer:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}