@use '@app/styles' as styles;

.dialog-edit-children-order {
  width: 560px;

  .dialog__header {
    .info-tooltip {
      margin-right: 10px;
      border-color: white;
      color: white;
      padding: 9px;
      font-weight: bold;
    }
  }

  .dialog__main {
    width: 100%;
    padding: 20px;
    row-gap: 10px;

    .item {
      position: relative;
      width: 100%;
      -webkit-user-drag: element;
      cursor: pointer;

      &.dragged::after {
        content: '';
        position: absolute;
        inset: 0;
        background: styles.dark-blue(5);
      }
    }
  }
}