.time-range-editor {
  width: 100%;
  gap: 5px;
  justify-content: space-between;

  .time-range-input {
    &.infinity {
      .ts-input {
        &::placeholder {
          font-size: 30px;
          position: absolute;
          top: 2px;
          left: 35px;
        }
      }
    }
  }
}