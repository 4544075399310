@use '@app/styles' as styles;

.resource-editor {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #EFF3F5;
  padding: 15px 35px 35px 35px;

  @mixin tag($lightColor,$darkColor) {
    background: $lightColor;
    border: 1px solid $darkColor;
    border-radius: 5px;
    min-width: 70px;
    height: 28px;
    padding-block: 4px;
    padding-inline: 8px;
    flex-shrink: 0;
  }

  .variable-list {
    gap: 12px;
    flex-wrap: wrap;

    .tag {
      @include tag(styles.$white, #e3e9f2);
      @include styles.mouseInteractiveBackground(styles.$white, #e3e9f2);
      cursor: default;
    }
  }

  .ts-values-list {
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;

    .ts-values-list__value {
      @include tag(styles.$white, #e3e9f2);
      @include styles.mouseInteractiveBackground(styles.$white, #e3e9f2);
      cursor: default;

      .ts-icon__small {
        margin-right: 4px;
        cursor: pointer;

        svg {
          height: 14px;
          width: 14px;
        }
      }
    }
  }


  .item-renderer {
    .ts-prop-renderer__label {
      margin: 0;
    }
  }

  .remove-btn {
    cursor: pointer;
  }

  .title-container {
    justify-content: space-between;
    align-items: flex-end;
  }

  .container {
    background-color: white;
    border-radius: 4px;
    width: 650px;
    padding: 14px;
    height: fit-content;

    .ts-prop-renderer__label {
      font: {
        size: 0.8rem;
        weight: 600;
      }
      width: 157px;
      color: var(--prop-renderer__label-color);
    }

  }

  .box-container {
    width: 650px;
    margin-top: 25px;
  }

  .ts-input {
    padding: 2px 10px 2px 10px;
    border: 1px #e3e9f2 solid;
    border-radius: 4px;
    background: none;
    border: 1px solid #e3e9f2;

    &::placeholder {
      font-style: normal;
    }
  }

  .form-title {
    color: var(--prop-renderer__label-color);
    font-weight: bold;
    font-size: 1rem;
  }

  .checkbox-titles {
    font-weight: 600;
    font-size: 0.8rem;
    color: var(--prop-renderer__label-color);
  }

  .ll_v_l {
    gap: 5px;
  }

  .ts-dropdown {

    .ts-dropdown__items-container {
      margin: 0;
    }

    .ts-dropdown__header {
      background: none;
      border-radius: 4px;
      border: 1px solid #e3e9f2;
      text-align: center;

      .ts-dropdown__placeholder {
        font-style: normal;
        color: #333333;
      }
    }

    .ts-dropdown__items-container {
      border: none;
    }


    .ts-tree {
      .ts-tree__children-container {
        .ts-tree__node {
          background: none;
          padding: 4px;
          cursor: pointer;
          border: 1px solid #e3e9f2;

          &:hover {
            background: rgb(210, 219, 234);
          }
        }
      }
    }
  }

  .ts-button {
    background: white;
    font-size: 12px;
    border-radius: 5px;
    color: inherit;
    padding: 2px 10px 2px 10px;
    height: 40px;
    font-weight: 400;
    border: 1px #e3e9f2 solid;
    margin: 10px 0px 0px 0px;

    &:hover {
      background: #6C87B0FF;
    }
  }
}