@use "@nu-art/ts-styles" as styles;

.smart-editor-container {
  width: 100%;
  height: 100%;
  gap: 5px;

  .buttons {
    grid-template-columns: repeat(auto-fill, 112px) !important;
    gap: 2px !important;

    .calc-btn {
      cursor: pointer;
      background: white;
      font-size: 12px;
      border-radius: 5px;
      color: inherit;
      padding: 10px;
      font-weight: 400;
      border: 1px styles.gray(5) solid;
      margin-inline: 0;
      min-width: 62px;
      text-align: center;

      &:hover {
        background: #6C87B0FF;
        color: white;
      }
    }
  }

  .monaco-editor .monaco-scrollable-element .lines-content {
    height: 100% !important;
    width: 100% !important;
  }
}