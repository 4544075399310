@use '@app/styles' as styles;

.health-care-space .value-area {
  height: 100%;
  width: 100%;
  background: var(--color__background__general-panel);

  .value-area__header {
    width: 100%;
    height: var(--workspace__default-row-height);
    justify-content: flex-end;
    background: var(--color__header__values);
    padding: 0 10px;

    .value-area__header__title {
      font-size: 16px;
      font-weight: var(--workspace__header-font-weight);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .qsearch {
      background: var(--color__header__values-search);
      width: 200px;
      min-width: 100px;
      margin-inline: 12px;
      box-shadow: var(--shadow__general-inset);

      .ts-input {
        display: flex;
        padding-bottom: 4px;
        font-size: var(--workspace__default-font-size);
        color: var(--color__header__search-text);

        &::placeholder {
          color: var(--color__header__search-text);
          font-size: var(--workspace__default-font-size);
        }
      }
    }

    .value-area__header__icon {
      width: 16px;
      cursor: pointer;
    }
  }

  .ts-tabs {
    flex-grow: 1;

    .ts-tabs__tabs-header {
      height: var(--workspace__default-row-height);

      .ts-tabs__tab {
        background: var(--color__value-tabs-header-background);
        border: none;
        color: var(--color__text__gray);
        font-size: var(--workspace__default-font-size);
        font-weight: var(--workspace__header-font-weight);

        &:hover {
          color: var(--color__text__focused-blue);
        }

        &.ts-tabs__focused {
          color: var(--color__text__focused-blue);
          border-bottom: var(--border__general-solid-light);
        }
      }
    }

    .ts-tabs__content {
      height: 0;
      flex-grow: 1;
      border: none;
      flex-direction: column;
      align-items: flex-start;
      background: var(--color__background__general-panel);

      .values-list {
        width: 100%;
        height: 100%;
      }
    }
  }

  .attributes-tab {
    @include styles.customScrollbar('vertical');
    width: 100%;
    height: 100%;

    .ts-collapsable-container {
      width: 100%;

      &:first-child {
        border-top: 1px solid black;
      }

      .ts-collapsable-container__header {
        height: 51px;
        background: white;
        box-shadow: none;

        .ts-collapsable-container__header__caret {
          transition: rotate 100ms linear;

          &.collapsed {
            rotate: -90deg;
          }
        }
      }

      .ts-collapsable-container__container {
        &:not(.collapsed) {
          max-height: 300px;
        }

        background: white;

        .values {
          width: 100%;
          height: 100%;
          padding: 20px;
          flex-wrap: wrap;
          gap: 10px;
          border-bottom: 1px solid black;

          .value-tag {
            @include styles.tag(styles.dark-green(5), styles.dark-green(3));
          }
        }
      }
    }
  }
}