@use '@app/styles' as styles;

.editor__order {
  padding: 30px;
  width: unset;

  .quai-manager-ui__data-grid__label {
    line-height: 40px;
    color: var(--prop-renderer__label-color);
    font: {
      size: 0.8rem;
      weight: 600;
    }
  }

  .editor__order__related-variables {
    width: 300px;

    .quai-manager-ui__data-grid__data {
      white-space: pre-wrap;
      line-height: unset;
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
}

.editor__order__uneditables {
  background: styles.gray(7);
  padding: 30px;
  margin-top: 30px;
  margin-right: 20px;
  border-radius: 10px;
  max-width: 400px;
  gap: 10px 10px;

  .quai-manager-ui__data-grid__data {
    white-space: break-spaces;
  }

  .q-json-viewer * {
    font-size: 14px;
  }

  i {
    font-size: 12px;
  }
}