@use "@app/styles" as styles;


.refs-tooltip {
  width: 25px;
  height: 25px;
  font: {
    size: 14px;
    weight: 800;
  }
  display: flex;
  justify-content: center;
  align-self: center;
  text-decoration: none;
  color: styles.dark-blue(2);
  border: 3px solid styles.dark-blue(2);
  flex-shrink: 0;
  border-radius: 50%;

  &.error {
    color: styles.red(4);
    border: 3px solid styles.red(4);
  }
}

.refs-tooltip__content {
  height: 100%;
  background: white;
  padding: 10px;
  max-width: 300px;
  width: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  .ref-id,
  .ref-name {
    white-space: break-spaces;
    gap: 5px;

    & > div {
      white-space: pre-wrap;

      &, & > b {
        font-size: 14px;
      }
    }
  }

  .ref-link {
    text-decoration: none;
    font-size: 12px;
    font-weight: 600;
    background: var(--color__dialog-header-background);
    color: white;
    border-radius: 10px;
    padding: 2px 10px;
    min-width: 100px;
    text-align: center;

    &.snomed {
      background: styles.dark-blue(2);
    }

    &.loinc {
      background: styles.red(2);
    }
  }

  .ref-warning {
    .warning-message {
      color: styles.red(4);
      font: {
        size: 12px;
        weight: 600;
      };
      margin: 0 5px;
    }

    .warning-icon {
      border: 2px solid styles.red(4);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: {
        size: 11px;
        weight: 600;
      }
      color: styles.red(4);
    }
  }


}
