@use '@app/styles' as styles;
@use '@nu-art/ts-styles' as tsStyles;

.variable-attribute-card {
  width: max(300px, 30%);
  border: 2px solid black;
  border-radius: 10px;
  overflow: hidden;

  .variable-attribute-card__header {
    width: 100%;
    height: 30px;
    padding: 0 8px;
    gap: 8px;
    border-bottom: 2px solid black;

    .icon--wrapper {
      @include tsStyles.color-svg(styles.red(2));
      margin-left: auto;
      width: 22px;
      height: 22px;
    }
  }

  .page-message {
    height: 150px;
  }

  .variable-attribute-card__body {
    @include styles.customScrollbar('vertical');
    width: 100%;
    height: 150px;
    flex-wrap: wrap;
    padding: 10px;
    align-content: flex-start;
    gap: 8px;

    .value-tag {
      height: 30px;
      padding: 4px 8px;
      position: relative;
      font: {
        size: 14px;
        weight: 500;
      }
      color: styles.dark-blue(2);
      background: styles.dark-blue(7);
      border: 2px solid styles.dark-blue(2);
      border-radius: 10px;
      overflow: hidden;

      .icon--wrapper {
        @include styles.mouseInteractiveBackground(styles.red(5), styles.red(4));
        @include tsStyles.color-svg(white);
        position: absolute;
        height: 100%;
        width: 20px;
        padding: 0 4px;
        display: none;
        top: 0;
        right: 0;
      }

      &:hover {
        .icon--wrapper {
          display: block;
        }
      }
    }

    .ts-dropdown {
      height: 30px;
      width: 180px;

      .ts-dropdown__header {
        background: styles.dark-blue(7);
        border: 2px solid styles.dark-blue(2);
        align-items: center;

        .ts-dropdown__placeholder {
          color: styles.dark-blue(2);
          padding: 0 5px;
          font: {
            weight: bold;
            size: 13px
          }
        }
      }

      .ts-dropdown__items-container {
        background: styles.dark-blue(7);
        border: 2px solid styles.dark-blue(2);

        .ts-dropdown__items {
          .ts-tree__node {
            .node-data {
              color: styles.dark-blue(2);
              font-size: 13px;
            }

            &:hover {
              background: styles.dark-blue(6);;
            }
          }
        }
      }

      .caret {
        @include tsStyles.color-svg(styles.dark-blue(2));
        margin-right: 4px;

        &.close {
          transform: rotateZ(180deg);
        }
      }
    }
  }
}