.q-json-viewer {
  width: 100%;
  .ts-tree__node .node-icon {
    width: 16px;
    height: 16px;

    &.expanded {
      rotate: 90deg;
    }
  }
}