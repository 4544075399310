@use '@app/styles/index' as styles;

.user-viewer {
  width: 100%;
  height: 100%;
  background: white;

  .user-viewer__body {
    width: 100%;
    padding: 30px;
    gap: 10px;

    .account-details {
      gap: 8px;
    }

    .user-permissions {
      width: 650px;

      .ts-prop-renderer {
        width: 100%;
        gap: 8px;
        justify-content: end;
        font-size: 14px;

        .ts-prop-renderer__label {
          margin-right: auto;
        }

        .ts-dropdown {
          width: 200px;
          height: 30px;

          .ts-dropdown__header {
            border: 1px solid styles.gray-blue(7);
            border-radius: 4px;

            .icon--wrapper {
              height: 100%;
              margin-right: 4px;

              &.close {
                rotate: 180deg;
              }
            }
          }

          .ts-dropdown__items-container {
            border: 1px solid styles.gray-blue(7);

            .ts-tree__node {
              font: {
                size: 14px;
              }

              &:hover {
                background: styles.gray-blue(7);
              }
            }
          }
        }

        &.user-permissions__role {
          border-bottom: 2px solid styles.gray(5);
          padding-bottom: 8px;

          .ts-prop-renderer__label {
            font: {
              size: 14px;
            }
          }
        }
      }
    }
  }
}