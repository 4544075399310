//Here be a list of toasts and the Quai level design for them

.ts-toaster {
  background: transparent;
}

.item-dependency-toast {
  background: var(--toast-background);
  border: var(--toast-border);
  border-radius: 8px;
  padding: 8px 16px;
}

.ts-toast {
  //Shadow
  box-shadow: var(--shadow__general-light);

  .icon--wrapper {
    width: 24px;
    height: 24px;

    svg path {
      fill: black;
      stroke: black;
      stroke-width: 1px;
    }
  }

  //Variants
  &.info {
    .icon--wrapper {
      svg path {
        fill: var(--color__toast-info-color);
        stroke: var(--color__toast-info-color);
      }
    }
  }

  &.success {
    .icon--wrapper {
      svg path {
        fill: var(--color__toast-success-color);
        stroke: var(--color__toast-success-color);
      }
    }
  }

  &.warning {
    .icon--wrapper {
      svg path {
        fill: var(--color__toast-warning-color);
        stroke: var(--color__toast-warning-color);

        &[data-name="Exclamation-Mark"] {
          fill: #FFFFFF;
        }
      }
    }
  }

  &.error {
    background: #FFFFFF;
    border-color: #FF0000;

    .icon--wrapper {
      svg path {
        fill: var(--color__toast-error-color);
        stroke: var(--color__toast-error-color);
      }
    }
  }


}