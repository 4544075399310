@use '@app/styles' as styles;

#warning-dialog {
  .ts-dialog__header {
    .header__buttons {
      .icon--wrapper {
        @include styles.mouseInteractiveIcon(white);
        width: 20px;
        height: 20px;
      }
    }
  }

  .ts-dialog__main {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    .warning-icon {
      @include styles.mouseInteractiveIcon(styles.dark-blue(2));
      height: 70px;
      width: 70px;
      min-height: 70px;
      min-width: 70px;
    }

    .dialog-main__text {
      font-size: 20px;
      white-space: pre-wrap;
    }
  }

  &.delete {
    .ts-dialog__header {
      background: styles.red(4);
    }

    .ts-dialog__buttons .desired-action-button {
      @include styles.mouseInteractiveBackground(styles.red(4), styles.red(3));

    }

    .ts-dialog__main .warning-icon {
      @include styles.mouseInteractiveIcon(styles.red(4), styles.red(3));
      cursor: unset;
    }

  }
}