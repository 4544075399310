@use '@app/styles' as styles;

.tags-section {

  width: 100%;
  height: 0;
  gap: 10px;
  flex: 1;

  .dialog__field-content {
    @include styles.customScrollbar('vertical');
    overflow-y: auto !important;
    width: 100%;
    flex: 1;
    gap: 10px;
  }

  .tag-group {
    width: 100%;
    border: 2px solid styles.gray(2);
    border-radius: 10px;

    .tag-group__title {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-bottom: 2px solid styles.gray(2);
      justify-content: space-between;

      .icon--wrapper {
        width: 12px;
        height: 12px;
        cursor: pointer;

        path {
          stroke: black;
          stroke-width: 1px;
          fill: black;
        }
      }

      span {
        font: {
          size: 14px;
          weight: bold;
        }
      }
    }

    .tags {
      width: 100%;
      padding: 10px;
      gap: 10px;
      flex-wrap: wrap;

      .tag {
        @include styles.tag(styles.dark-blue(7), styles.dark-blue(2));
        padding-left: 0;
        overflow: hidden;

        span {
          height: 100%;
          display: flex;
          align-items: center;
          color: styles.dark-blue(2);
          font: {
            weight: bold;
            size: 13px;
          };
        }

        .ts-button {
          height: 100%;
          width: 26px;
          margin: 0 5px 0 0;
          padding: 0 0 0 2px;
          border-radius: 8px 0 0 8px;
          align-items: center;
          justify-content: center;
          background: transparent;
          min-width: unset;

          &:hover {
            background: styles.dark-blue(5);
          }

          &:active {
            background: styles.dark-blue(4);
          }

          .icon--wrapper {
            width: 10px;
            height: 10px;
            cursor: pointer;

            path {
              fill: styles.dark-blue(2);
              stroke: styles.dark-blue(2);
              stroke-width: 1;
            }
          }
        }
      }

      .tag-dropdown {
        width: 200px;
        height: 28px;
        cursor: pointer;

        .ts-dropdown__header {
          background: styles.dark-blue(7);
          border: 2px solid styles.dark-blue(2);
          border-radius: 10px;

          .caret {
            height: 100%;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            transition: transform 200ms linear;

            path[data-name="Path 12166"] {
              fill: styles.dark-blue(2);
              stroke: styles.dark-blue(2);
              stroke-width: 1;
            }

            &.close {
              transform: rotateX(180deg);
            }
          }

          .ts-dropdown__placeholder {
            color: styles.dark-blue(2);
            padding: 0 5px;
            font: {
              weight: bold;
              size: 13px
            }
          }
        }

        .ts-dropdown__items-container {
          background: styles.dark-blue(7);
          border: 2px solid styles.dark-blue(2);

          .ts-dropdown__items {
            .ts-tree__node {
              .node-data {
                color: styles.dark-blue(2);
                font-size: 13px;
              }

              &:hover {
                background: styles.dark-blue(6);
              }

              &.ts-tree__selected-node {
                background: styles.dark-blue(6);
              }
            }
          }
        }
      }
    }
  }
}