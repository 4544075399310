@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.pathway__criticality-table {
  width: 100%;
  flex-grow: 1;

  .ts-grid {
    width: 100%;
    grid-template-columns: 2fr repeat(3, 1fr);
    gap: 10px;

    &.pathway__criticality-table__headers {
      padding: 15px 9px 15px 0;
    }
  }

  .q-scroll-wrapper {
    height: 0;
    flex-grow: 1;
  }

  .criticality-table__header {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    .criticality-table__header__text {
      @include styles.text-ellipsis;
      width: 0;
      max-width: fit-content;
      flex-grow: 1;
      font: {
        size: 14px;
        family: $font-family;
        weight: 600;
      }
      color: $font-color;
    }

    .icon--wrapper {
      @include styles.mouseInteractiveIcon($font-color);
      height: 12px;

      &.asc {
        [data-name="top-arrow"] {
          fill: styles.dark-blue(5);
        }
      }

      &.dsc {
        [data-name="bottom-arrow"] {
          fill: styles.dark-blue(5);
        }
      }
    }
  }

  .criticality-table__data {
    @include styles.text-ellipsis;
    font: {
      size: 14px;
      family: $font-family;
    }
    color: $font-color;
  }
}