@use "@nu-art/ts-styles" as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;


.expression-builder {
  width: 100%;
  height: 100%;
  align-items: flex-start;

  .expression-builder__view-mode-label {
    line-height: 40px;
    font-size: 14px;
    color: $font-color;
  }

  .builder-grid {
    @include styles.customScrollbar('vertical');
    width: 100%;
    padding: 20px;
    gap: 20px;
    grid-template-columns: max-content;

    .ts-list-organizer__list {
      display: grid;
      grid-template-columns: max-content;
      gap: 20px;
    }
  }

  // TS overrides

  .score-info .ts-prop-renderer {
    gap: 10px;

    &.horizontal {
      gap: 75px;
    }

    .ts-prop-renderer__label {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      width: 100px;
    }

  }

  .ts-tabs {

    .ts-tabs__content {
      @include styles.customScrollbar('both', true);
      @include styles.hide-scroll-bar();
      height: 350px;
      width: 250px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;


      .ts-textarea {
        resize: none;
        border-radius: 0;
      }

    }

    .ts-tabs__tabs-header {
      height: 42px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .ts-tabs__tab {
        @include styles.mouse-interactive-background(none, styles.gray(7));
        min-width: 50%;
        color: #333333;
        font-size: 14px;

        &.ts-tabs__focused {
          color: #333333;
          border-bottom: 2px solid #333333;
        }
      }

    }
  }

  .ts-checkbox {
    gap: 8px;

    &.quai {
      .ts-checkbox__button.ts-checkbox__button__checked {
        border: none;
        background-color: #0067FF;
      }

      .ts-checkbox__content {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }
    }

  }

  .ts-dropdown {
    max-width: 350px;
    width: 300px;

    .ts-dropdown__header {
      height: 40px;
      max-width: 350px;

      .ts-dropdown__selected, .ts-dropdown__placeholder {
        .node-data {
          color: #4f4f4f;
          font-weight: 400;
        }
      }

    }

    &.variable-dropdown {
      .node-data {
        .variable-name {
          color: #4f4f4f;
          font-weight: 400;
        }
      }

      .ts-dropdown__items-container {
        border: 1px solid #e3e9f2;
        border-radius: 0 0 4px 4px;
        translate: 0 -2px;

        .ts-tree__node {
          @include styles.mouse-interactive-background(transparent, #efefef);
          font: {
            size: 14px;
            family: $font-family;
          }
          color: $font-color;
          white-space: pre-wrap;
          border-bottom: 1px solid #e3e9f2;
        }
      }
    }
  }

  .ts-input {
    max-width: 350px;
    height: 40px;
    font-size: 14px;
    color: $font-color;

    &.disabled {
      background: styles.gray(7);
    }

    &.expression-input {
      max-width: 100%;
    }

    &::placeholder {
      font-size: 14px;
    }
  }

  .subtitle {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
  }

  .references-list {
    gap: 5px;

    .ref-row {
      justify-content: space-between;
      width: 350px;

      .ts-link {
        white-space: break-spaces;
      }

      .icon--wrapper {
        cursor: pointer;
      }
    }

    .add-ref-btn {
      background: white;
      color: #016fa0;
      font-size: 20px;
      min-width: 35px;
      max-width: 35px;
      min-height: 35px;
      font-weight: 400;
      border-radius: 4px;
      border: 1px solid #d7d7d7;
      margin: 0;
    }
  }

  .score-info {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-flow: column;
    gap: 10px;

    .basic-info-row {
      align-items: flex-start;
      width: 100%;
      gap: 5px;
    }

    .yes-no-container {
      width: 350px;

      .yes-no {
        height: 40px;
        padding: 4px;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &.yes {
          background: #f6cf97;

          &:hover {
            background: #e3e9f2;
          }
        }

        &.no {
          background: #e3e9f2;

          &:hover {
            background: #f6cf97;
          }
        }
      }
    }
  }

  .add-term-btn {
    min-height: 40px;
    padding: 2px 10px;
    width: unset;
    max-width: fit-content;
    margin: 0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
  }

  .value-table-container {
    padding-top: 15px;

    .title {
      font-weight: 600;
    }

    .value-table {
      display: flex;
      flex-flow: column;
      gap: 12px;

      .ts-table {
        min-width: 400px;
        max-width: 830px;

        th {
          width: 30px;
          height: 30px;
          text-align: center;
        }

        .ts-table__tr {
          height: 30px;
        }

        .ts-table__body td.ts-table__td {
          height: 30px;
        }
      }
    }
  }

  .header {
    justify-content: space-between;
    background: #eff3f5;
    height: 50px;
    width: 100%;
    padding: 2px 15px;

    .status-components {
      margin-left: auto;
      gap: 10px;

      .component--focused-object {
        min-width: calc(26px * 3);
      }
    }

    .ts-editable-item-status {
      font-size: 14px;
      color: #33333388;
    }

    .util-btn {
      @include styles.mouse-interactive-background(none, #ebf1f4);
      height: 50px;
      font-size: 14px;
      padding: 2px 10px 2px 10px;
      cursor: pointer;
      font-weight: 400;
      margin: 0;
      min-width: 50px;
      color: #197aceab;
      border: none;
      text-transform: none;

      &.ts-busy-button__disabled {
        color: #9ba5adab;
      }

      .icon--wrapper {
        @include styles.mouse-interactive-icon(#197aceab);
      }
    }

    .main-title {
      color: #333333;
      font-size: 16px;
    }
  }

  .clear-icon {
    margin: 5px;
    background: #696969;
    height: 16px;
    width: 16px;
    border-radius: 50%;

    svg {
      height: 8px;
      width: 15px;

      path {
        fill: white;
      }
    }
  }
}

#expression-tool-tip {
  padding: 10px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.6);
  -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.6);
}