.pgdev-dialog-indexing {
  padding: 40px;

  .open-dialog-button {
    background: #54D2F5;
    padding: 5px 10px;
    width: max-content;
    border: none;
    border-radius: 10px;
    border-bottom: 5px solid #808080;
    cursor: pointer;
    box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.4);

    &:hover {
      background: #007CBE;
    }

    &:active {
      border-bottom: 2px solid #808080;
      transform: translateY(3px);
      box-shadow: 0px 3px 9px -3px rgba(0,0,0,0.4);
    }
  }
}

.test-dialog {
  background: white;
  width: 500px;
  height: 500px;
  display: flex;
  flex-flow: column;
  padding: 20px;
  border-radius: 10px;

  .test-dialog__header {
    display: flex;
    justify-content: space-between;
    .exit-button {
      background: #54D2F5;
      padding: 5px 10px;
      width: max-content;
      border: none;
      border-radius: 10px;
      border-bottom: 5px solid #808080;
      cursor: pointer;
      box-shadow: 0px 6px 9px -3px rgba(0,0,0,0.4);

      &:hover {
        background: #007CBE;
      }

      &:active {
        border-bottom: 2px solid #808080;
        transform: translateY(3px);
        box-shadow: 0px 3px 9px -3px rgba(0,0,0,0.4);
      }
    }

  }

  .test-dialog__content {
    flex-grow: 1;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    justify-content: space-around;
    align-items: center;

    .indexed-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 170px;
      height: 170px;
      background: #808080;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }
}