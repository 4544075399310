@use '@app/styles/index' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

$border-color: #e3e9f2;
$tabs-color: styles.dark-blue(1);

#editor-organization-ehr-instances {
  height: 100%;
  width: 100%;
  
  .ts-grid {
    @include styles.customScrollbar('vertical');
    @extend %prop-renderer-base-design;
    grid-template-columns: max-content 1fr;
    padding-block: 20px;
    padding-left: 20px;
    height: fit-content;
    row-gap: 10px;
    column-gap: 40px;
    width: 100%;

    .ts-prop-renderer__label {
      &.span-row {
        grid-column: 1/-1;
      }

      &.indent {
        padding-left: 15px;
      }
    }

    .grid-data {
      font-size: 14px;
      line-height: 30px;
      color: $font-color;
    }

    .ts-input, .ts-dropdown {
      height: 40px;
    }

    .ts-multi-select__list-value {
      flex-direction: row-reverse;
    }

    .sof-param-key {
      width: 140px;
      margin-left: 15px;
    }

    .sof-param-value {
      width: 300px;
      position: relative;

      .ts-input {
        padding-right: 20px;
      }

      .icon--wrapper {
        position: absolute;
        top: 50%;
        right: 5px;
        translate: 0 -50%;
        padding: 5px;
        height: 100%;
        width: 20px;
        cursor: pointer;
      }
    }

    &.edit-mode {
      row-gap: 20px;

      .ts-prop-renderer__label {
        min-width: 100px;
      }
    }

    &:not(.edit-mode) {
      .ts-prop-renderer__label {
        line-height: 30px;
      }
    }
  }

  .ts-tabs {
    height: 100%;

    .ts-tabs__tabs-header {
      height: 30px;

      .ts-tabs__tab {
        background: white;
        color: $font-color;
        border-top: 2px solid transparent;
        border-bottom: 2px solid $tabs-color;

        &:not(:first-child) {
          border-left: 2px solid $tabs-color;
        }

        &.ts-tabs__focused {
          color: $tabs-color;
          border-top-color: $tabs-color;
          border-bottom-color: transparent;

          &:last-of-type {
            border-right: 2px solid $tabs-color;
          }
        }
      }

      .ts-tabs__empty-tab {
        border-bottom: 2px solid $tabs-color;
      }
    }
  }

  .editor-organization-ehr-instances__add-more-button {
    @include styles.mouseInteractiveBackground(transparent, $border-color);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 6px;
  }

  .editor-organization-ehr-instances__urls {
    gap: 20px;

    .editor-organization-ehr-instances__url {
      position: relative;

      .ts-input {
        padding-right: 20px;
      }

      .icon--wrapper {
        position: absolute;
        top: 50%;
        right: 5px;
        translate: 0 -50%;
        padding: 5px;
        height: 100%;
        width: 20px;
        cursor: pointer;
      }
    }
  }
}