@use '../../../index' as styles;
@use "../ui-manager/consts" as *;
@use '@nu-art/ts-styles' as tsStyles;

.search-and-results {
  width: 100%;
  height: 100%;
  padding-top: 10px;

  .search-area {
    width: 100%;
    padding: 10px 20px;

    .search-area__utils-bar {
      justify-content: space-between;
      flex-direction: row-reverse;
    }

    .search-timer {
      height: 25px;
      width: 100%;
      padding: 0 5px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 10px;
      color: #cecece;
    }

    .search-and-results__search {
      width: 100%;
      height: 45px;
      padding-right: 8px;
      border: 1px solid #aaaaaa;
      border-radius: 5px;
      gap: 10px;

      .ts-input {
        border: none !important;
        outline: none !important;
        padding-left: 8px;
        height: 100%;
        flex-grow: 1;
        max-width: unset;
      }

      .search-and-results__search__glass {

      }

      .search-and-results__search__clear {
        background: #696969;
        height: 18px;
        width: 18px;
        border-radius: 50%;

        svg {
          height: 8px;
          width: 15px;

          path {
            fill: white;
          }
        }
      }
    }

    .qsearch {
      width: 100%;
      height: 45px;
      max-width: none;
      padding-right: 7px;
      margin: 0;

      flex-direction: row-reverse;
      background: transparent;
      border: 1px solid #aaaaaa;
      border-radius: 5px;

      .clear-icon {
        margin: 5px;
        background: #696969;
        height: 16px;
        width: 18px;
        border-radius: 50%;

        svg {
          height: 8px;
          width: 15px;

          path {
            fill: white;
          }
        }

      }

      .qsearch-icon {
        svg path {
          fill: none;
          stroke: #d2dbea;
        }
      }

      .ts-input {
        outline: none;
        padding-inline: 10px;

        &::placeholder {
          font-style: normal;
        }
      }
    }

    .search-results-amount {
      font-size: 12px;
      color: #aaaaaa;
    }

    .search-filters {
      width: 100%;

      .search-filters__by-search-groups {
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;

        .search-filters__by-search-groups__item {
          @include styles.mouseInteractiveBackground(white, styles.dark-blue(2), styles.dark-blue(1));
          @include styles.mouseInteractiveColor(styles.dark-blue(2), white, white);
          border: 1px solid styles.dark-blue(2);
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 12px;

          &.selected {
            background: styles.dark-blue(1);
            border-color: styles.dark-blue(1);
            color: white;
          }
        }
      }

      .search-filters__by-search-item {
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;

        .search-filters__by-search-item__item {
          @include styles.mouseInteractiveBackground(white, styles.dark-blue(2), styles.dark-blue(1));
          @include styles.mouseInteractiveColor(styles.dark-blue(2), white, white);
          border: 1px solid styles.dark-blue(2);
          padding: 5px 10px;
          border-radius: 20px;
          font-size: 12px;

          &.selected {
            background: styles.dark-blue(1);
            border-color: styles.dark-blue(1);
            color: white;
          }
        }
      }
    }

    .search-and-results__sort-list {
      cursor: pointer;
      gap: 5px;

      .search-and-results__sort-list-title {
        font-size: 12px;
        color: #aaaaaa;
      }

      .icon--wrapper {
        @include tsStyles.color-svg(#d2dbea);
      }
    }
  }

  .search-result-list-container {
    height: 0;
    flex-grow: 1;
    width: 100%;

    .search-results {
      height: 100% !important;
      width: 100%;
    }
  }
}

#search-and-results__action-list {
  @extend %actions-popup;
  width: 135px;

  .popup-action {
    padding-inline: 10px;

    .icon--wrapper {
      @include tsStyles.color-svg(#4f4f4f);
      height: 12px;
    }

    div {
      font-size: 12px;
    }
  }
}