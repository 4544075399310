@use '@app/styles' as styles;

.dropdown-container {
  flex: 1;
  height: 100%;
  justify-content: flex-start;

  &:last-child {
    justify-content: flex-end;
  }

  .ts-dropdown {
    //@include styles.fancyDropDown;
    height: 30px;
  }
}