@use '../functions-and-mixins/colors' as colors;
@use '../functions-and-mixins/palette' as palette;
@use "../components/Dialogs/darkTheme" as *;
@use "../components/TS_PropRenderer/darkTheme" as *;
@use "../components/TS_BusyButton/darkTheme" as *;
@use "../components/TS_Button/darkTheme" as *;
@use "../components/TS_Checkbox/darkTheme" as *;
@use "../components/TS_Dropdown/darkTheme" as *;
@use "../components/TS_Input/darkTheme" as *;
@use "../components/TS_Radio/darkTheme" as *;
@use "../components/TS_Table/darkTheme" as *;
@use "../components/TS_Tabs/darkTheme" as *;
@use "../components/TS_TextArea/darkTheme" as *;
@use "../components/TS_Tooltip/darkTheme" as *;
@use "../components/TS_Tree/darkTheme" as *;
@use "../components/TS_Toast/darkTheme" as *;

@mixin theme-css-vars_dark {
  @include dark-theme__ts-prop-renderer;
  @include dark-theme__dialogs;
  @include dark-theme__ts-tooltip;
  @include dark-theme__ts-table;
  @include dark-theme__ts-tabs;
  @include dark-theme__ts-textarea;
  @include dark-theme__ts-input;
  @include dark-theme__ts-dropdown;
  @include dark-theme__ts-button;
  @include dark-theme__ts-busy-button;
  @include dark-theme__ts-tree;
  @include dark-theme__ts-toast;

  //Text
  --color__text__placeholder-1: #{palette.black(6)};
  --color__text__placeholder-2: #{palette.black(5)};
  --color__text__gray: #{palette.black(6)};
  --color__sub-header__font-color: #{palette.dark-blue(1)};

  //Headers
  --color__header__categories: #{palette.ochre(3)};
  --color__header__categories-search: #{palette.ochre(5)};
  --color__header__values: #{palette.gray-blue(3)};
  --color__header__values-search: #{palette.gray-blue(5)};
  --color__header__search-icon: black;
  --color__header__search-text: black;

  //Workspace
  --color__background__general-panel: #{palette.black(4)};
  --ws__border__item-hover: 1px solid #4CBBE5;
  --ws__color__item-selected: #{palette.dark-blue(1)};
  --ws__shadow__item-hover: 0px 3px 6px #ffffff29;

  //Disease Value Counter
  --color__disease-value-count__background: #d0d0d0;

  //List Separator
  --color__list-seperator: #{palette.black(6)};

  //Tooltips
  --color__tooltip__variable-information: #{palette.dark-blue(5)};

  //Scrollbar
  --color__scrollbar-track: #d0d0d0;
  --color__scrollbar-thumb: #{palette.black(3)};
  --color__scrollbar-thumb-hover: #{palette.dark-blue(2)};
}