@use '@app/styles' as styles;

.quai-discussions-container {
  position: fixed;
  width: 300px;
  height: calc(100vh - 50px);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  z-index: 290;
  isolation: isolate;
  background: white;

  box-shadow: -5px 4px 5px -1px rgba(174, 193, 218, 0.75);
  box-sizing: border-box;

  bottom: 0;
  right: 0;

  transform: translateX(100%);
  transition: transform linear;
  transition-duration: inherit;

  .display-options-menu {
    width: 100%;
    height: 40px;
    justify-content: space-between;
    border: 1px solid #d4dbe9;
    flex-shrink: 0;

    .sort-discussion-button {
      @include styles.mouseInteractiveIcon(#d4dbe9);
      width: 20px;
      height: 20px;
      rotate: 90deg;
      margin-inline-end: 20px;
    }
  }

  .ts-notification {
    background: #FFFFFF;
    border: 2px solid #00709f;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;

    .ts-notification__header {
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .ts-notification__title {
        font: {
          size: 14px;
          weight: bold;
        }
      }

      .ts-notification__close {
        cursor: pointer;
        background: #FF0000;
        width: 19px;
        height: 19px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font: {
          size: 14px;
          weight: bold;
        }

        &:hover {
          background: #B70000;
        }

        &:active {
          background: #AB0000;
        }
      }
    }

    .ts-notification__body {
      padding: 8px;

      .ts-notification__message {
        font-size: 12px;
        white-space: pre-wrap;
      }
    }

    .ts-notification__footer {
      .ts-notification__timestamp {
        font-size: 12px;
        color: #808080;
      }
    }

    //Status

    &.ts-notification__failed {
      border: 2px solid #ab0000;
    }

    &.ts-notification__resolved {
      border: 2px solid #009f40;
    }
  }

  &.enter, &.enter-done {
    transform: translateX(0);
  }

  .discussions-scrolling-container {

    //&.list {
    //  //noinspection CssInvalidPropertyValue - STFU this works...
    //  -webkit-mask-image: linear-gradient(to bottom, transparent 2%, #000000 5%, #000000 95%, transparent 98%);
    //  background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.2) 20%);
    //}

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#filtering-options {
  height: unset;
  padding-block: 10px;
  padding-inline-start: 10px;
  padding-inline-end: 20px;
  z-index: 300;
  gap: 8px;
  box-shadow: -5px 4px 5px -1px rgba(174, 193, 218, 0.75);

  .ts-checkbox {
    gap: unset;

    .ts-checkbox__button {
      width: 18px;
      height: 18px;
      border: unset;
      padding: unset;
    }

    .ts-checkbox__content {
      font-size: 12px;
      margin-inline-start: 5px
    }
  }


  .filtering-options-radio {
    height: unset;
    flex-flow: column;
    justify-content: start;
    align-items: start;
    gap: 4px;

    .ts-radio__label {
      font-size: 12px;
      color: #4B4B4B;
    }
  }
}