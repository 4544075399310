.message-container {
  display: flex;
  justify-content: start;
  margin-block: 5px;
  width: 100%;
  max-width: 233px;

  .message-header {
    justify-content: start;
    margin-bottom: 2px;
    width: 100%;

    .sender-name {
      font-weight: 700;
      font-size: 13px;
      color: black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date-format {
      font-size: 13px;
      color: black;
      margin-left: 3px;
    }
  }

  .message-text {
    font-size: 13px;
    color: black;
    white-space: pre-wrap;
    width: 100%;
  }
}