@use '@app/styles/index' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

$border-color: #e3e9f2;

.editor-organization-ehr-orders {
  align-items: flex-start;
  height: 100%;
  width: 100%;

  .editor-organization-ehr-orders__orders {
    height: 100%;
    width: 200px;
    flex-shrink: 0;
    gap: 10px;
    padding-block: 10px;
    border-right: 1px solid $border-color;

    .q-scroll-wrapper {
      scrollbar-gutter: unset;
      flex-grow: 1;
    }

    .editor-organization-ehr-orders__order {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 25px;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;
      position: relative;

      &.selected {
        background: #f6f8fc;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }

      .editor-organization-ehr-orders__order__incomplete {
        position: absolute;
        left: 5px;
      }
    }
  }

  .editor-organization-ehr-orders__orders__buttons {
    width: 100%;
    padding: 0 20px;
    gap: 10px;

    .ts-button {
      width: 100%;
      height: 30px;
      text-transform: unset;
    }
  }

  & > .ts-grid {
    @include styles.customScrollbar('vertical');
    @extend %prop-renderer-base-design;
    flex-grow: 1;
    grid-template-columns: max-content 1fr;
    width: 100%;
    height: fit-content;
    padding-left: 20px;

    row-gap: 10px;
    column-gap: 40px;

    .grid-data {
      font-size: 14px;
      line-height: 30px;
      color: $font-color;
    }

    .ts-input, .ts-dropdown {
      height: 40px;
    }

    &.edit-mode {
      .ts-prop-renderer__label {
        min-width: 100px;
      }
    }

    &:not(.edit-mode) {
      .ts-prop-renderer__label {
        line-height: 30px;
      }
    }
  }
}

#order-pop-up-menu {
  @extend %actions-popup;
}

#editor-organization-ehr-orders__order__incomplete-tooltip {
  $border-color: $border-color;
  border: 1px solid $border-color;
  border-radius: 4px;
  background: #ffffff;
  width: 200px;
  min-height: 50px;
  font-size: 12px;
  white-space: pre-wrap;
  padding: 10px;
}