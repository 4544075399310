.expression-description {
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 100%;

  .ts-textarea {
    max-height: 95%;
    height: 95%;
    width: 100%;
    box-shadow: none;
    border: 1px solid #d7d7d7;
    background: none;
    font-size: 15px;
    font-weight: 400;
    resize: none;

    &.over-limit {
      box-shadow: 0px 0px 5px 1px #a82a2a;
      -webkit-box-shadow: 0px 0px 5px 1px #a82a2a;
      -moz-box-shadow: 0px 0px 5px 1px #a82a2a;
    }
  }

  .length-info-container {
    gap: 5px;

    .description-length {
      &.over-limit {
        color: #a82a2a;
      }
    }
  }
}