.panel__disease-properties-data {
  width: 100%;
  height: 100%;

  .panel-header {
    background: var(--color__background__general-panel);
    width: 100%;
    height: 40px;
    padding: 0 10px;
    flex-shrink: 0;

    .panel-header__title {
      font: {
        size: 16px;
        weight: 600;
      }
    }
  }

  .disease-profile-data-renderer {
    width: 100%;
    height: 100%;
  }

  .no-prop-message {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font: {
      size: 60px;
      weight: 600;
    }

    color: #616161;
    background: #bababa;
    text-shadow: #e0e0e0 1px 1px 0;
  }
}