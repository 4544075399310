@use '@app/styles' as styles;

.pg-dev-general-testing {
  position: relative;
  width: 100%;
  height: 100%;

  .isolated-container {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;

    .isolated-container-overlay {
      width: 100%;
      height: 100%;
      //background: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(1px);

      &.c-1 {
        z-index: 4
      }

      &.c-2 {
        z-index: 3
      }

      &.c-3 {
        z-index: 2
      }

      &.c-4 {
        z-index: 1
      }
    }
  }
}