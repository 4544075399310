@use '@app/styles/index' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

$height: 40px;

.ehr-order-time {
  width: 300px;
  gap: 15px;
  height: 30px;
  font-size: 14px;
  color: $font-color;

  .ehr-order-time__separator {
    line-height: $height;
    text-align: center;
    flex-shrink: 0;
  }

  .ehr-order-time__input {
    height: 100%;
    border: 1px solid $border-color;
    flex-grow: 1;
    border-radius: 4px;
    overflow: hidden;

    .ts-input {
      width: 65%;
      height: 100%;
      border: none;

      &::-webkit-calendar-picker-indicator {
        display: none;
      }
    }

    .label-radio {
      flex-direction: column;
      width: 35%;
      min-width: unset;
      border: none;
      border-radius: 4px;
      overflow: hidden;

      .ts-radio__container {
        @include styles.mouseInteractiveBackground(rgba(227, 233, 242, 0.63), #d2dbea);
        min-width: unset;
        border: none !important;

        &.checked {
          background: #8ca5ca !important;
        }
      }

      .ts-radio__label {
        padding: 0;
      }
    }
  }

  &.edit-mode {
    height: $height
  }
}