@use '../../functions-and-mixins' as styles;
@use '@nu-art/ts-styles' as tsStyles;

.ts-checkbox.quai {
  flex-shrink: 0;
  cursor: pointer;

  .ts-checkbox__button {
    width: 14px;
    height: 14px;
    box-shadow: none;
    padding: 0;
    border: 1px solid #E5E7EB;
    background: white;
    border-radius: 2px;
    flex-shrink: 0;

    .ts-checkbox__button__inner {
      width: 100%;
      height: 100%;
      background: transparent;
    }

    .icon--wrapper {
      @include tsStyles.color-svg(white);
      width: 100%;
      height: 100%;
      padding: 2px;

      path {
        stroke-width: 2;
      }
    }

    &.ts-checkbox__button__checked {
      background: #2E4454;
      border: 1px solid #2E4454;
    }
  }

  .ts-checkbox__content {
    margin: 0;
    font: {
      size: 14px;
      weight: bold;
    }
  }
}