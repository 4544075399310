@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as consts;

.pathway__transition-header {
  width: 100%;
  align-items: flex-start;
  gap: 10px;

  .quai-manager-ui__header {
    //Origin State Header
    &:first-child {
      width: unset;
      flex-shrink: 0;
      flex-basis: 0;
    }

    //Target State Header
    &:last-child {
      width: unset;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }
  }

  .pathway__transition-header__icon {
    @include styles.mouseInteractiveIcon(consts.$selected-green);
    cursor: default;
    transform: translateY(10px);
  }
}