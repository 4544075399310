.flex-toast-base {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .click-here-label {
    text-decoration: underline;
    color: #00BCF1;
    cursor: pointer;
  }
}