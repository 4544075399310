#resource-selector__search {
  padding: 30px 0 0 0;
  align-items: flex-end;
  gap: 15px;

  .button-container {
    gap: 10px;
    margin-inline: 30px;

    .btn-create {

    }
  }

  .search-and-results {
    padding-top: 0;
    gap: 5px;

    .search-area {
      padding: 0 30px;
      gap: 10px;

      .search-timer {
        display: none;
      }
    }
  }
}