@use '../../functions-and-mixins/palette' as palette;

.ts-tree {

  .ts-tree__children-container {
    .ts-tree__node {
      cursor: pointer;

      &.ts-tree__selected-node {
        background: palette.dark-blue(7);
      }
    }
  }
}