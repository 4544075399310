.inputs-test {
  padding: 20px;
  width: 100%;
  gap: 20px;
  align-items: flex-start;

  .oncancel-indicator {
    width: 200px;
    height: 40px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    border-radius: 10px;
    font: {
      size: 24px;
      weight: bold;
    }
    color: white;
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.8);

    &.active {
      background: green;
    }
  }
}