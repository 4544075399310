.pathway-state-editor {
  width: 100%;
  height: 100%;

  .ts-multi-select__list {
    gap: 10px;

    .ts-multi-select__list-value {
      align-items: flex-start;
      min-height: 25px !important;
    }
  }
}