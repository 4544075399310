@use '@app/styles' as styles;

$font-family: 'Open Sans', sans-serif;
$font-color: #333;
$border-radius: 4px;
$border-color: #e3e9f2;

#manager__hcs {
  width: 100%;
  height: 100%;
  grid-template-areas:
          "header header"
          "vars info";
  grid-template-columns: 300px 1fr;
  grid-template-rows: 100px 1fr;

  .manager__hcs__variables {
    @include styles.customScrollbar('vertical');

    .manager__hcs__variable {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      width: 100%;
      height: 50px;
      flex-shrink: 0;
      padding: 0 10px;
      display: flex;
      align-items: center;
      white-space: pre-wrap;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      &.selected {
        background: #f6f8fc;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }

      &.open-discussion {
        position: relative;

        &::after {
          content: '';
          background: #f24822;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 10px;
          translate: 0 -50%;
        }
      }
    }
  }

  #manager__variable {
    overflow: auto;
  }
}