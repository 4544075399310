@use 'palette' as palette;
@use 'components' as components;

@mixin advisor-button-colors {
  @include components.mouseInteractiveBackground(palette.gray(7), white);
  border: 1px solid #CFD4D8;
}

@mixin advisor-rule-out-item {
  cursor: pointer;
  width: 100%;
  height: 26px;
  background: #EDF1F3;
  border: 1px solid #CFD4D8;
  padding: 0 10px;
  justify-content: space-between;
  border-radius: 1px;
  margin: 4px 0;
  color: black;

  .rule-out-action__name {
    font: {
      size: 14px;
      family: Arial;
    }
  }

  &:hover {
    background: #F5F7F8;
  }

  &:active {
    background: #D6DBDF;
  }

  &.pending {
    box-shadow: inset 1px 1px 0px #00000029;
    background: #F5F7F8;

    .rule-out-action__name {
      color: #001E95;
    }

    .icon--wrapper {
      svg path {
        fill: #001E95;
      }
    }
  }
}

@mixin advisorScrollBar($direction, $forceShow: false) {
  scrollbar-gutter: stable;

  //The Entire Scrollbar
  &::-webkit-scrollbar {
    background: transparent;
  }

  //The Scrollbar clickable buttons to move the scrollbar
  &::-webkit-scrollbar-button {
    display: none;
  }

  //The draggable scrollbar part to move it
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 9999px;
    background-clip: padding-box;
    border: 3px solid transparent;

    &:hover {
      border-width: 2px;
    }
  }

  //If the direction is vertical or both
  @if $direction == 'vertical' or $direction == 'both' {
    ///Set overflow-y
    overflow-y: auto;
    //noinspection ALL
    @supports (overflow-y: overlay) {
      overflow-y: overlay;
    }

    ///Set vertical scrollbar width
    &::-webkit-scrollbar:vertical {
      width: 9px;
    }
  }

  //If the direction is horizontal or both
  @if $direction == 'horizontal' or $direction == 'both' {
    ///Set overflow-y
    overflow-x: auto;
    //noinspection ALL
    @supports (overflow-x: overlay) {
      overflow-x: overlay;
    }

    ///Set vertical scrollbar width
    &::-webkit-scrollbar:horizontal {
      height: 9px;
    }
  }

  //If forceShow is false
  @if not $forceShow {
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}