@use '@nu-art/ts-styles' as ts-styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.value-editor__time {
  height: 100%;
  width: 100%;
  padding: 20px 20px 0 20px;

  .editor-title {
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
  }

  .value-editor__time__time-ranges {
    @include ts-styles.customScrollbar('vertical');
    height: 95%;
    width: 100%;
    gap: 15px;

    .ts-card {
      @extend %ui-manager__ts-card;
      display: flex;
      width: fit-content;
      height: 0;
      align-items: center;
      min-width: 30%;
      min-height: 100px;
      gap: 20px;

      .icon--wrapper {
        cursor: pointer;
      }
    }
  }

  .ts-button {
    @extend %pathway-button-design;
    margin-top: auto;
  }
}