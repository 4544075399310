@use "@app/styles" as styles;

.variables-panel {
  gap: 10px;
  min-width: 350px;
  max-width: 400px;
  height: 100%;

  .variables-panel__header {
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f;
  }

  .variables-panel__panel-body {
    @include styles.customScrollbar('vertical');
    height: 100%;
    width: 100%;
    padding: 5px;

    .variables-panel__list-item {
      width: 100%;
      padding: 5px;
      font-size: 14px;
      white-space: break-spaces;
      line-height: 30px;
    }
  }
}