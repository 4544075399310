@use "@nu-art/ts-styles" as styles;

.screen {
  align-items: flex-end;

  .ts-textarea {
    background: white;
    width: 45%;
    height: 100%;
  }


  .second-row-container {
    justify-content: space-between;
    align-items: flex-start;
    height: 55%;
    width: 100%;

    .buttons-bar {
      gap: 20px;
      padding: 20px;
      flex-wrap: wrap;
    }
  }

  .expression_composer {
    width: 100%;
    height: 45%;

    .editor {
      width: 55%;
    }


    .simulator-panel {
      width: 45%;
      height: 100%;
      justify-content: flex-start;
      align-items: center;
      padding: 5px;

      .title {
        color: var(--prop-renderer__label-color);
        font-weight: 650;
        font-size: 17px;
        padding: 5px;
      }

      .var-list {
        height: 90%;
        width: 95%;
        overflow-y: auto;
        padding: 5px;
        gap: 5px;

        .ts-prop-renderer {
          justify-content: space-between;

          .ts-prop-renderer__label {
            font-size: 15px;
            white-space: break-spaces;
          }

          .ts-dropdown {
            max-width: 300px;

            .ts-dropdown__items-container {
              margin: 0;
            }

            .ts-dropdown__header {
              background: white;
              border-radius: 4px;
              border: 1px solid #e3e9f2;
              padding: 2px 10px 2px 10px;
              text-align: center;

              .ts-dropdown__placeholder {
                font-style: normal;
                padding: 0;
              }
            }

            .ts-dropdown__items-container {
              border: none;
            }

            .ts-input {
              border: none;
            }

            .ts-tree {
              .ts-tree__children-container {
                .ts-tree__node {
                  background: none;
                  padding: 4px;
                  cursor: pointer;
                  border: none;

                  &:hover {
                    background: rgb(210, 219, 234);
                  }
                }
              }
            }
          }

          .ts-input {
            padding: 2px 10px 2px 10px;
            border-radius: 4px;
            background: white;
            border: 1px solid #e3e9f2;

            &::placeholder {
              font-style: normal;
            }

          }
        }
      }

      .calculation-panel {
        align-items: center;

        .ts-button {
          background: #8ca5ca;
          height: 35px;
          padding-inline: 10px;
          border-radius: 5px;
          font-weight: 400;
          margin: 0 5px;

          &:hover {
            background: rgb(108, 135, 176);
          }
        }
      }
    }
  }
}
