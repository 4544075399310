@use '@app/styles/index' as styles;

%dropdown-data-design {
  width: 100%;
  height: 40px;
  white-space: break-spaces;
  padding: 5px;
  font: {
    size: 12px;
  }
  display: flex;
  align-items: center;

  &.header {
    background: styles.gray-blue(7);
  }

  &.no-access {
    background: styles.dark-blue(7);
  }

  &.read {
    background: styles.dark-blue(6);
  }

  &.write {
    background: styles.dark-blue(5);
  }

  &.admin {
    background: styles.dark-blue(4);
  }

}

.user-permissions__info {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  border: 2px solid styles.black(3);
  display: flex;
  justify-content: center;
  align-items: center;
  font: {
    size: 14px;
    weight: bold;
  }
  cursor: pointer;
  color: styles.black(3);
}

#user-types-tooltip {
  width: 150px;
  background: white;
  border-radius: 10px;
  border: 2px solid styles.dark-blue(1);
  overflow: hidden;
  white-space: break-spaces;
  padding: 10px;
  font: {
    size: 12px;
  }
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);

  b {
    font-size: inherit;
  }
}

#user-types-popup {
  width: 700px;
  border-radius: 10px;
  border: 3px solid styles.dark-blue(1);
  overflow: hidden;
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.35);

  .ts-table {
    .ts-table__head .ts-table__tr {
      background: styles.gray-blue(7);

      .ts-table__th {
        white-space: break-spaces;
        font: {
          size: 12px;
          weight: bold;
        }
        border-top: none;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .ts-table__body .ts-table__tr {
      height: 40px;

      .ts-table__td {
        padding: 0;

        & > div {
          @extend %dropdown-data-design;
          padding: 0 5px;
          white-space: pre-wrap;
        }

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }

      &:last-child {
        .ts-table__td {
          border-bottom: none;
        }
      }
    }
  }
}