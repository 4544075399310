@use '@app/styles' as styles;

$footerHeight: 80px;
$fontColor: #2f2f2f;
$sectionMargin: 20px;

@page {
  margin: 50px 50px 8mm 50px;
}

%section-title {
  grid-column: 1/-1;
  padding: 10px 0;
  border-bottom: 1px solid #2f2f2f;
}

%section-sub-title {
  grid-column: 1/-1;
  padding: 30px 0 20px 0;
}

%grid-primer {
  width: 100%;
  grid-template-columns: max-content auto;
  margin-top: $sectionMargin;
  row-gap: 10px;
  column-gap: 20px;
}

#printable__disease-profile {
  background: white;
  width: 100%;
  height: 100%;

  h1 {
    font: {
      size: 20px;
      weight: 600;
      family: "Open Sans";
    }
    color: $fontColor;
    margin: 0;
    padding: 0;
  }

  h2 {
    font: {
      size: 18px;
      weight: normal;
      family: "Open Sans";
    }
    color: $fontColor;
    margin: 0;
    padding: 0;
  }

  h3 {
    font: {
      size: 16px;
      weight: 600;
      family: "Open Sans";
    }
    color: $fontColor;
    margin: 0;
    padding: 0;
  }

  h4 {
    font: {
      size: 14px;
      weight: 600;
      family: "Open Sans";
    }
    color: $fontColor;
    margin: 0;
    padding: 0;

    &.bold {
      font-weight: 700;
    }
  }

  p {
    font: {
      size: 14px;
      weight: 400;
      family: "Open Sans";
    }
    color: $fontColor;
    white-space: break-spaces;
    margin: 0;
    padding: 0;
  }

  .dot {
    font: {
      size: inherit;
      family: inherit;
      weight: inherit;
    }
    color: red;
  }

  .printable__disease-profile__title {
    width: 100%;
    justify-content: space-between;

    h1 {
      text-transform: capitalize;
    }
  }

  .printable__disease-profile__general-information,
  .printable__disease-profile__view {
    @extend %grid-primer;

    h2 {
      @extend %section-title;
    }

    h3 {
      @extend %section-sub-title;
    }
  }

  .printable__disease-profile__view {
    margin-top: 40px;

    h3 sup {
      font: {
        size: 10px;
        weight: normal;
        family: inherit;
      }
      color: inherit;
    }
  }

  .printable__disease-profile__value-separator {
    grid-column: 1/-1;
    padding: 15px 0;
    position: relative;

    &::after {
      content: '';
      height: 1px;
      width: 50%;
      background: #E3E9F2;
      position: absolute;
      top: 50%;
      left: 0;
      translate: 0 -50%;
    }
  }

  table {
    width: 100%;

    tfoot {
      height: $footerHeight;
    }
  }

  .printable__disease-profile__footer {
    width: 100%;
    height: $footerHeight;
    position: fixed;
    bottom: -30px;

    .printable__disease-profile__footer__disease-name,
    .printable__disease-profile__footer__copyright,
    &::after {
      width: 33%;
      height: $footerHeight;
      display: inline-block;
      line-height: $footerHeight;
      font: {
        size: 14px;
      }
      color: #797979;
    }

    .printable__disease-profile__footer__disease-name {
      text-transform: capitalize;

    }

    .printable__disease-profile__footer__copyright {
      text-align: center;
    }

    //&::after {
    //  counter-increment: page-num;
    //  content: counter(page);
    //  text-align: right;
    //  page-break-before: always;
    //}
  }
}