.panel-header {
  padding-inline: 10px;

  .panel-header__title {
    font: {
      size: 16px;
      weight: 600;
    }
  }
}

