@use '../../../../index' as styles;

$font-color: #4f4f4f;
$font-family: "Open Sans", sans-serif;

#search-selector {
  width: 100%;
  padding: 30px 0 0 0;
  align-items: flex-end;
  gap: 15px;

  .search-and-results {
    padding-top: 0;
    gap: 5px;

    .search-area {
      padding: 0 30px;
      gap: 10px;

      .search-timer {
        display: none;
      }
    }
  }

  //General TS component Designs
  .button-container {
    padding-inline: 30px;
    justify-content: end;
  }

  .ts-button,
  .ts-busy-button {
    @include styles.mouseInteractiveBackground(#ffffff);
    height: 40px;
    width: unset;
    min-width: 85px;
    padding: 2px 10px;
    margin: 0;
    border: 1px solid #e3e9f2;
    font: {
      size: 14px;
      family: $font-family;
      weight: normal;
    }
    color: $font-color;
    text-transform: uppercase;

    &.preferred-action {
      @include styles.mouseInteractiveBackground(#ee9b00, #f38f0b);
      color: #ffffff;
      border: none;
    }

    &.ts-busy-button__disabled {
      @include styles.mouseInteractiveBackground(#ffffff);
    }
  }

  .ts-input {
    width: 300px;
    height: 40px;
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    padding: 0 10px;
    font: {
      size: 14px;
      family: $font-family;
      weight: normal;
    }
    color: $font-color;
  }

  .ts-dropdown {
    width: 300px;
    height: 40px;

    .ts-dropdown__header {
      border: 1px solid #e3e9f2;
      border-radius: 4px;
      gap: 4px;
      padding: 0 4px 0 10px;
      align-items: center;

      .ts-input {
        padding: 0;
        border: none;
      }

      .ts-dropdown__selected {
        font: {
          size: 14px;
          family: $font-family;
          weight: normal;
        }
        color: $font-color;
      }

      .icon--wrapper {
        @include styles.mouseInteractiveIcon($font-color);

        &.flip {
          rotate: 180deg;
        }
      }
    }

    .ts-dropdown__items-container {
      border: 1px solid #e3e9f2;
      border-radius: 0 0 4px 4px;
      translate: 0 -2px;

      .ts-tree__node {
        @include styles.mouseInteractiveBackground(transparent, #efefef);
        font: {
          size: 14px;
          family: $font-family;
        }
        color: $font-color;
        white-space: pre-wrap;
        border-bottom: 1px solid #e3e9f2;
      }
    }
  }

  .ts-textarea {
    width: 300px;
    height: 200px;
    box-shadow: none;
    font: {
      size: 14px;
      family: $font-family;
      weight: normal;
    }
    color: $font-color;
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    background: #ffffff;
    resize: none;
  }

  //General Q component Designs
  .qsearch {
    .ts-input {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  //Selector inner parts
  .search-selector__header {
    width: 100%;

    .search-selector__header__title {
      color: #4f4f4f;
      font: {
        family: $font-family;
        size: 18px;
      }
      text-transform: capitalize;
    }

    .search-selector__header__subtitle {
      color: #4f4f4f;
      font: {
        family: $font-family;
        size: 12px;
      }
      text-transform: capitalize;
    }

    &.underline {
      border-bottom: 1px solid #e3e9f2;
      padding-bottom: 5px;
    }
  }

  .search-selector__form {
    gap: 10px;

    .search-selector__form__label {
      color: #4f4f4f;
      font: {
        family: $font-family;
        size: 14px;
      }

      sup {
        font: {
          family: inherit;
          size: inherit;
        }
        color: inherit;
        position: relative;
        top: 5px;
      }
    }
  }

  .search-selector__buttons {
    margin-top: auto;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
  }
}