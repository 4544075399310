@use '@app/styles' as styles;

@mixin light-theme-side-nav-bar {
  //Side Nav background
  --side-nav-bar__background-color: #{styles.$very-light-grey};
  --side-nav-bar__background-color-hover: #C5D6DF;
  --side-nav-bar__background-color-active: #004A72;


  //Side nav toggler
  --color__side-nav-bar__open-icon_hover: #{styles.dark-green(4)};

  //Side nav icon colors
  --side-nav-bar__icon-color: #303436;
  --side-nav-bar__icon-hover-color: #303436;
  --side-nav-bar__icon-click-color: #FFFFFF;
  --side-nav-bar__icon-disabled-color: #B2B2B2;
  --side-nav-bar__icon-selected-color: #FFFFFF;

  //Side nav icon background colors
  --side-nav-bar__icon-background-color: #F7F7F7;
  --side-nav-bar__icon-background-hover-color: #C5D6DF;
  --side-nav-bar__icon-background-click-color: #004A72;
  --side-nav-bar__icon-background-disabled-color: #F7F7F7;
  --side-nav-bar__icon-background-selected-color: #004A72;
}