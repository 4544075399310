#pg-dev__pop-up-test {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  & > * {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background: grey;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#pg-dev__custom-pop-up {
  padding: 8px;
  white-space: pre-wrap;
  text-align: center;
}