@use '../../../../index' as styles;
@use '../consts' as *;

.quai-manager-ui {
  //General TS component Designs
  .ts-button,
  .ts-busy-button {
    @extend %pathway-button-design;
  }

  .ts-input {
    @extend %ui-manager__ts-input-design;
  }

  .ts-dropdown {
    @extend %ui-manager__ts-dropdown-design;
  }

  .ts-textarea {
    @extend %ui-manager__ts-text-area-design;
  }

  .ts-card {
    @extend %ui-manager__ts-card;
  }

  .ts-collapsable-container {
    width: 100%;

    .ts-collapsable-container__header {
      justify-content: flex-start;
      box-shadow: none;
      background: transparent;
      border-bottom: none;
      font: {
        size: 14px;
        weight: 600;
        family: $font-family;
      }
      color: $font-color;

      .ts-collapsable-container__header__caret {
        margin: 0;

        .icon--wrapper {
          @include styles.mouseInteractiveIcon($font-color);

          svg path {
            stroke-width: 0.5;
          }
        }

        &.collapsed {
          rotate: -90deg;
        }
      }
    }

    .ts-collapsable-container__container {
      background: transparent;
      padding-left: 20px;

      .ts-collapsable-container__container-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;

        * {
          font: {
            size: 14px;
            family: $font-family;
          }
          color: $font-color;
          white-space: pre-wrap;

          &.clickable:hover {
            color: $font-color-clickable;
          }
        }
      }

      .ts-grid {
        align-items: center;
        grid-template-columns: 1fr 16px 1fr;
        gap: 5px 10px;

        .transition-icon {
          @include styles.mouseInteractiveIcon($font-color)
        }
      }
    }
  }

  .ts-multi-select__list {
    max-width: 300px;
    gap: 5px;

    .ts-multi-select__list-value {
      width: 100%;
      height: 40px;
      justify-content: space-between;
      padding-inline: 10px 6px;

      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      .icon--wrapper {
        @include styles.mouseInteractiveIcon($font-color);
        padding: 3px;
      }

      //Can happen by nesting multi-selects
      .ts-multi-select__list-value {
        padding-inline: 10px 0;
      }
    }
  }

  //General Q component Designs
  .qsearch {
    .ts-input {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  .tags-section {
    @extend %ui-manager__tag-group-design;
  }
}