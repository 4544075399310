.basic {

}

.placeholder-page-message {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  font: {
    size: 60px;
    weight: 600;
  }

  color: #616161;
  background: #bababa;
  text-shadow: #e0e0e0 1px 1px 0;
}