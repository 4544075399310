@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

#pathway-editor {

  .pathway__form {
    @include styles.customScrollbar('vertical', true);
    gap: 10px;
    width: 100%;

    .ts-checkbox {
      margin-top: 20px;
    }

    .pathway__form__label {
      color: #4f4f4f;
      font: {
        family: $font-family;
        size: 14px;
      }

      sup {
        font: {
          family: inherit;
          size: inherit;
        }
        color: inherit;
        position: relative;
        top: 5px;
      }

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    .pathway__form__data {
      color: #4f4f4f;
      font: {
        family: $font-family;
        size: 14px;
      }
      white-space: pre-wrap;
    }

    .ts-multi-select__list-value {
      min-height: 40px;
      height: unset;
    }
  }

  .pathway__top-buttons {
    width: 100%;
    height: 40px;
    justify-content: space-between;

    .pathway__top-buttons__back {
      @include styles.mouseInteractiveBackground(#ffffff, #ebf1f4);
      padding: 0 10px;
      min-width: unset;
      color: rgba(29, 73, 111, 0.67);
      border: none;
      font: {
        size: 14px;
        family: $font-family;
      }
      text-transform: unset;
    }
  }

  .pathway__bottom-buttons {
    margin-top: auto;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
  }

  .criticality-table-button {
    margin-left: auto;
    font: {
      size: 14px;
      weight: 400
    }
    cursor: pointer;
  }

  .pathway__description {
    width: 100%;
    white-space: pre-wrap;
    font-size: 12px;
    margin: 0;
  }

  .ts-collapsable-container {
    .pathway__transition {
      .pathway__transition__source,
      .pathway__transition__target {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
      }

      .icon--wrapper {
        flex-shrink: 0;
      }
    }
  }

  .pathway-transition-details .ts-grid {
    grid-template-columns: 200px 1fr;

    .section-value {
      width: 400px;
      grid-column: 1;
    }
  }

  .pathway-state-details, .pathway-transition-details {

    .grid-label-with-items {
      cursor: pointer;
      line-height: 30px;
      font-size: 14px;
      color: #4f4f4f;

      &:hover {
        color: $font-color-clickable;
      }
    }

    .section-value {
      .no-result-line {
        padding-left: 20px;
        line-height: 20px;
        font-size: 14px;

        &.main-section {
          font-size: 14px;
        }
      }

      .order-grid {
        grid-template-columns: max-content 1fr;
        margin-left: 30px;
        gap: 0 25px;
        align-items: start;

        .quai-manager-ui__data-grid__label {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .ts-collapsable-container {
        margin-left: 15px;

        .ts-collapsable-container__header {
          height: 30px;

          .collapsable-label {
            gap: 5px;
            font-weight: normal;
            font-size: 14px;

            &.main-title {
              font-size: 14px;
              font-weight: 600;
            }
          }
        }

        .ts-collapsable-container__container {
          padding-left: 0;

          #expression-pretty-printer {
            padding: 0 10px 0 10px;

            .pretty-print__sub-title {
              line-height: 15px;
              font-size: 14px;
            }

            .pretty-print__term-list {
              padding: 0 10px 0 10px;
            }

            .pretty-print__term-info__title {
              line-height: 15px;
              font-size: 14px;
            }

            .pretty-print__atoms-list {
              padding-left: 25px;
            }

            .pretty-print__atom-container {
              line-height: 20px;
            }

            .pretty-print__atom-container .content {
              font-size: 14px;

              i {
                font-size: 14px;
              }
            }
          }
        }

        &.remove-indentation {
          margin-left: unset;
        }
      }
    }

    .ts-grid {
      .quai-manager-ui__data-grid__data {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }

    & .loader-wrapper {
      display: inline-block;
      height: 10px;
      width: 20px;

      .ts-button-loader {
        width: 100%;
        height: 100%;
        transform: scale(0.5);
        margin-left: 10px;

        div {
          background: $font-color;
        }
      }
    }

    .details-renderer__wrapper {
      @include styles.customScrollbar('vertical', true);
      overflow-x: hidden;
      height: 100%;
      width: 100%;
      gap: 10px;

      .ts-collapsable-container {
        position: relative;
        right: 5px;
      }
    }

  }

  .pathway__transition__metapathway-action {
    width: 100%;
    font: inherit;
    gap: 10px;

    .pathway__transition__metapathway-action__label {
      width: 100%;
      font: inherit;
      justify-content: space-between;
    }

    .pathway__transition__metapathway-action__protocols {
      width: 100%;
      padding-left: 10px;
      font: inherit;
      gap: 10px;

      .pathway__transition__metapathway-action__protocol {
        width: 100%;
        font: inherit;
        justify-content: space-between;
      }
    }
  }
}

#pathway-selector__search {
  width: 100%;
  padding: 30px 0 0 0;
  align-items: flex-end;
  gap: 15px;

  .button__create-new-pathway {
    margin-right: 30px;
  }

  .search-and-results {
    padding-top: 0;
    gap: 5px;

    .search-area {
      padding: 0 30px;
      gap: 10px;

      .search-timer {
        display: none;
      }
    }
  }
}