@use '@app/styles' as styles;

.dp-report__tab {
  @include styles.customScrollbar('vertical');
  width: 100%;
  height: 100%;
  display: grid;
  //grid-auto-columns: minmax(0, 1fr);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background: black;
  grid-column-gap: 2px;

  .profile {
    min-width: 1fr;
    background: white;
    height: max-content;
    min-height: 100%;
    position: relative;
    width: 100%;

    .profile-title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      top: 0;
      flex-shrink: 0;
      z-index: 2;

      height: 40px;
      width: 100%;
      font: {
        size: 20px;
        weight: bold;
      }
    }

    .profile-data {
      padding: 20px;
      width: 100%;

      .view-container {
        width: 100%;
        min-height: 60px;
        display: flex;
        flex-direction: column;

        border-bottom: 1px solid styles.black(5);
        gap: 12px;
        padding-block: 12px;

        .view-container__header {
          flex-shrink: 0;
          width: 100%;
          height: 30px;
          font: {
            size: 16px;
            weight: bold;
          }
        }

        .view-container__multivars {
          flex-wrap: wrap;
          gap: 12px;
          align-items: flex-start;

          .multivar {
            border: 2px solid styles.black(4);
            border-radius: 4px;
            overflow: hidden;
            height: fit-content;
            max-width: 100%;

            .multivar-table {
              width: 100%;
              display: grid;
              gap: 0;
              background: white;


              .grid-cell {
                padding: 4px 8px;
                min-height: 30px;
                white-space: pre-wrap;
                height: -webkit-fill-available;

                &:not(:last-child):not(:has(+ .grid-separator)) {
                  border-right: 1px solid styles.black(4);
                }

                &.header {
                  background: styles.black(7);
                }
              }

              .grid-separator {
                height: 1px;
                background: styles.black(4);
                grid-column-start: 1;
                grid-column-end: -1;
              }
            }

            .multivar-references {
              width: 100%;

              .field-title {
                width: 100%;
                text-align: center;
                height: 25px;
                font: {
                  size: 14px;
                  weight: bold;
                }
                background: styles.black(7);
                margin: 0;
              }

              .references-list .ts-table {
                width: unset;

                .ts-table__head .ts-table__tr {
                  height: 25px;
                }

                .ts-table__body .ts-table__tr .ts-table__td .td-data {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .field {
      width: 100%;
      gap: 5px;

      &.ll_v_l {
        align-items: flex-start;
      }

      &.ll_h_c {
        align-items: center;
        justify-content: flex-start;
      }

      .field-title {
        font: {
          size: 16px;
          weight: bold;
        }
        color: styles.dark-blue(2);
      }

      .field-data {
        width: 100%;
        white-space: pre-wrap;
        font: {
          size: 14px
        }
      }

      .references-list {
        border: 1px solid black;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    &.empty {
      .ts-dropdown {
        position: sticky;
        top: 0;
      }
    }

  }

  @media print {
    overflow-y: visible;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    height: max-content;

    & > * {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }

    &:has(.profile.empty) {
      grid-auto-columns: unset;
      grid-template-columns: 1fr;
    }

    .profile.empty {
      display: none;
    }

    .profile {

      &.empty {
        display: none;
      }

      .profile-data {
        .view-container {
          page-break-before: auto;
          border-bottom: none;
          display: block;

          .view-container__header {
            background: styles.gray-blue(6);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 8px;
          }

          .view-container__multivars {
            page-break-inside: auto;

            .multivar {
              page-break-inside: avoid;
              flex-shrink: 0;
            }
          }


          &.empty {
            display: none !important;
          }

          &:not(.empty) {
            height: fit-content !important;
          }
        }
      }
    }
  }
}

.pg-dev-dp-report {
  width: 100%;
  height: 100%;

  .pgdev-header {
    width: 100%;

    .ts-dropdown {
      width: unset;
      flex-grow: 1;
    }
  }
}

.dp-report {
  width: 100%;
  height: 100%;

  .ts-tabs {
    width: 100%;
    height: 100%;
  }
}