@use '@app/styles' as styles;

#search-panel {
  box-shadow: 8px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 8px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 8px 0px 5px 0px rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  font-weight: 400;
  
  .results-container {
    margin-top: 1rem;
    overflow: hidden;
    height: 100%;

    span {
      font-size: inherit;
    }

    .result-totals {
      margin-inline-start: 7.5%;
      font-size: 0.7rem;
      position: sticky;
      display: flex;
      justify-content: space-between;
      width: 87%;
      margin-bottom: 0.5rem;
    }

    .results-list {
      overflow: hidden;
    }

    .selected-result {
      background: white;
      border: 1px solid #8b8b8b;
    }

  }

  .break-spaces {
    white-space: break-spaces;
  }
}