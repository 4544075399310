#dialog-article-editor,
#dialog-up-to-date-editor,
#dialog-snomed-editor,
#dialog-loinc-editor {
  width: 360px;

  .ts-dialog__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
  }
}

#dialog-book-editor {
  .ts-dialog__main {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;

    .lower-inputs {
      width: 100%;
      gap: 16px;
    }
  }
}