@use "@app/styles" as styles;

.field {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  column-gap: 20px;
  row-gap: 10px;

  .field-title {
    font: {
      size: 14px;
      weight: bold;
    }
  }

  .field-data {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    //In-case field-data is not an element but a container
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 3px;

    .field-data__item {
      font-size: 14px;
    }
  }
}

.variable-tags {
  gap: 5px;
  flex-wrap: wrap;

  .variable-tag {
    @include styles.categoryPathItem;
    max-width: 120px;
    width: fit-content;

    &.orange {
      background: styles.orange(5);
    }
  }

  .separator-arrow {
    font: {
      weight: bold;
    }
  }
}