@use '../../functions-and-mixins' as mixins;

.ts-dropdown {
  height: 40px;

  .ts-dropdown__header {
    height: 100%;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;

    background: var(--dropdown__background-color);

    .ts-dropdown__placeholder,
    .ts-dropdown__selected {
      @include mixins.text-ellipsis;
      font: {
        size: 14px;
        weight: 500;
      }

      .node-data {
        @include mixins.text-ellipsis;
        font: {
          size: 14px;
          weight: 500;
          style: normal;
        }
        color: black;
      }
    }

    .ts-dropdown__placeholder {
      font: {
        style: italic;
      }
      color: mixins.black(5);
    }

    .ts-input {
      background: transparent;
      font: {
        size: 0.8rem;
        weight: 600;
      }
    }
  }

  .ts-dropdown__items-container {
    @include mixins.customScrollbar('vertical');

    .ts-tree {
      height: fit-content;
    }
  }

  &.variable-dropdown {
    .node-data {
      display: flex;
      gap: 4px;
      justify-content: flex-start;

      .variable-name {
        font: {
          size: 14px;
        }
      }
    }

    .pass-reason {
      font: {
        size: 12px;
        weight: bold;
      }
    }
  }

  &.fancy {
    @include mixins.fancyDropDown;
  }
}