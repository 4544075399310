@use '@app/styles' as styles;

$height: 30px;

.q-expanding-search {
  max-width: $height;
  height: $height;
  border: 1px solid styles.gray(4);
  background: white;

  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  transition: max-width 100ms linear;

  .icon--wrapper {
    height: $height;
    width: $height;
    cursor: pointer;

    svg {
      height: 18px;
      width: 18px;
    }

    &.search {
      @include styles.mouseInteractiveIcon(styles.gray(3));
      flex-shrink: 0;
    }

    &.accept {
      @include styles.mouseInteractiveIcon(black, white);
      @include styles.mouseInteractiveBackground(styles.gray(7), styles.dark-blue(2));
      border-left: 1px solid styles.gray(4);

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .ts-input {
    height: $height;
    min-height: unset;
    flex-grow: 1;
    font-size: 13px;
    max-width: unset;

    &:focus-visible {
      outline: none;
    }
  }

  &.expanded {
    max-width: 100%;
    width: 100%;
  }
}