@use '../../functions-and-mixins' as mixins;

$font-family: 'Open Sans', sans-serif;
$font-color: #333;
$border-radius: 4px;

%ts-dropdown-data-design {
  @include mixins.mouseInteractiveBackground(transparent, rgba(246, 248, 252, 1));
  font: {
    size: 14px;
    family: $font-family;
  }
  color: $font-color;
  white-space: break-spaces;
  line-height: 20px;
}

.quai-item-manager {

  //General TS_Input Design
  .ts-input {
    width: 300px;
    height: 30px;
    min-height: 30px;
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    outline: none !important;
    font: {
      size: 14px;
      family: $font-family;
    }
    color: $font-color;
    padding: 2px 10px;
  }

  //General TS_TextArea Design
  .ts-textarea {
    @include mixins.customScrollbar('vertical', true);
    width: 300px;
    height: 170px;
    border: 1px solid #e3e9f2;
    box-shadow: none;
    resize: none;
    background: transparent;
    font: {
      size: 14px;
      weight: normal;
      family: $font-family;
    }
    color: $font-color;
  }

  //General TS_Dropdown Design
  .ts-dropdown {
    height: 30px;
    width: 300px;

    .ts-dropdown__header {
      border: 1px solid #e3e9f2;
      border-radius: 4px;
      padding: 0 10px;

      .ts-input {
        border: none;
        padding: 0;
      }

      .ts-dropdown__selected {
        font: {
          size: 14px;
          family: $font-family;
          weight: normal;
        }
        color: $font-color;
        padding: 0;

        .node-data {
          font-weight: normal;
        }
      }

      .icon--wrapper {
        height: 100%;

        &.close {
          rotate: 180deg;
        }
      }
    }

    .ts-dropdown__items-container {
      border: 1px solid #e3e9f2;
      border-radius: 0 0 4px 4px;

      .ts-dropdown__unselect-item {
        @extend %ts-dropdown-data-design;
      }

      .ts-tree__node {
        @extend %ts-dropdown-data-design;

        .node-data {
          @extend %ts-dropdown-data-design;
        }
      }
    }

    .variable-name {
      @extend %ts-dropdown-data-design;
    }

    &.disabled {
      .ts-dropdown__header {
        background: mixins.gray(7);
      }
    }
  }

  .manager__header {
    grid-area: header;
    height: 100px;
    justify-content: space-between;
    gap: 5px;
    padding: 0 20px;
    position: relative;

    .manager__header__main {
      justify-content: center;
      gap: 5px;
      height: 100%;
      flex-grow: 1;
    }

    .manager__header__tail {
      flex-shrink: 0;
      align-items: center;
      gap: 5px;
    }

    .manager__header__title {
      gap: 10px;
      height: 30px;
      align-items: center;

      .manager__header__title__label {
        font: {
          size: 18px;
          family: $font-family;
        }
        text-transform: capitalize;
        color: $font-color;
      }

      .icon--wrapper {
        @include mixins.mouseInteractiveBackground(transparent, rgba(129, 211, 248, 0.15), rgba(129, 211, 248, 0.1));
        width: 30px;
        height: 30px;
        padding: 5px;
        border-radius: 4px;
      }

      .manager__header__title__color {
        width: 20px;
        height: 20px;
        border-radius: 50%;

        .icon--wrapper {
          @include mixins.mouseInteractiveBackground(transparent);
          @include mixins.mouseInteractiveIcon(transparent, transparent, transparent, 200ms linear);
          width: 100%;
          height: 100%;
          cursor: default;

          &.completed {
            @include mixins.mouseInteractiveIcon(white);
            cursor: default;
          }

          svg path {
            stroke-width: 2;
          }
        }
      }
    }

    .manager__header__sub-title {
      font-size: 12px;
      gap: 5px;
      color: $font-color;

      .manager__header__sub-title__item,
      .manager__header__sub-title__hierarchy {
        font-size: 10px;
        color: inherit;
        padding: 4px 5px;
        border-radius: $border-radius;
      }

      .manager__header__sub-title__item {
        border: 1px solid #d2dbea;
      }

      .manager__header__sub-title__hierarchy {
        background: #e3e9f2;
      }
    }

    .manager__header__exit-edit {
      @include mixins.mouseInteractiveBackground(#8ca5ca, #f38f0b);
      width: 75px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #8ca5ca;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: #fff;
      border-radius: $border-radius;
      position: absolute;
      top: 50%;
      right: 20px;
      translate: 0 -50%;
    }

    &.edit-mode {
      background: #f6f8fc;
    }
  }

  .manager__data-grid {
    grid-template-columns: max-content 1fr;
    column-gap: 30px;
    row-gap: 10px;

    font: {
      size: 14px;
      family: $font-family;
    }
    color: $font-color;

    .data-grid__label {
      height: 30px;
      display: flex;
      align-items: center;
      font: {
        size: inherit;
        weight: 600;
      }

      &.full-span {
        grid-column: 1/-1;
      }
    }

    .data-grid__data {
      min-height: 30px;
      display: flex;
      align-items: center;
      font: {
        size: 14px;
      }
      white-space: break-spaces;
      line-height: 30px;

      &.full-span {
        grid-column: 1/-1;
      }
    }

    .data-grid__separator {
      grid-column: 1/-1;
      padding: 20px 0;
      position: relative;

      &::after {
        content: '';
        height: 1px;
        width: 50%;
        background: #E3E9F2;
        position: absolute;
        top: 50%;
        left: 0;
        translate: 0 -50%;
      }
    }
  }
}