@use '../../functions-and-mixins' as styles;

.ts-dialog {
  .ts-dialog__header {
    background: styles.dark-blue(2);
    color: white;

    .header__title {
      color: inherit;
    }

    .header__buttons {

      .exit_icon {
        @include styles.mouseInteractiveIcon(white);
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }
}