@use '@app/styles/index' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.macro-tag-editor {
  padding: 20px;
  gap: 20px;

  .ts-input {
    @extend %ui-manager__ts-input-design;
  }

  .ts-dropdown {
    @extend %ui-manager__ts-dropdown-design;
  }

  .tags-section {
    .tag-group .ts-dropdown {
      height: 30px;

      .ts-dropdown__header {
        border: 2px solid styles.dark-blue(2);
        border-radius: 10px;
      }
    }

    &.edit-disabled {
      .tag {
        padding-left: 10px;
      }
    }
  }
}