@use "@nu-art/ts-styles" as styles;

.utils-panel {
  width: 100%;
  height: 100%;

  .header {
    background: #eff3f5;
    height: 50px;
    width: 100%;
    padding: 2px 15px;
    gap: 40px;
  }

  .ts-tabs {
    height: 100%;
    width: 100%;

    .ts-tabs__tabs-header {
      background: #ebf1f4;

      .ts-tabs__tab {
        font-weight: 400;
        font-size: 14px;

        &.ts-tabs__focused {
          color: #1d496f;
          background: none;
          border-bottom: 2px solid #1d496f;
        }

        &:not(.ts-tabs__focused) {
          @include styles.mouse-interactive-background(none, #ebf1f4);
          color: rgba(29, 73, 111, 0.67);
          background: none;
          border-bottom: 1px solid white;
        }
      }

      .ts-tabs__content {
        display: none;
      }
    }
  }
}