@use "@app/styles" as styles;

#expression-pretty-printer {
  gap: 10px;
  padding: 10px;

  .pretty-print__title {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    white-space: break-spaces;
  }

  .pretty-print__sub-title {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    white-space: break-spaces;
  }

  .pretty-print__term-list {
    padding: 0 10px 10px 10px;
    gap: 5px;
    height: 100%;
    width: 100%;

    .pretty-print__term-list-item {
      gap: 5px;
      height: 100%;
      width: 100%;

      .term-label {
        font-size: 14px;
        color: #333333;
        font-weight: normal;
      }

      .pretty-print__term-info {
        margin-left: 10px;
        width: 100%;

        .pretty-print__term-info__title {
          font-size: 14px;
          color: #333333;
          white-space: break-spaces;
        }

        .pretty-print__atoms-list {
          margin-block: 5px;
          width: 100%;

          .pretty-print__atom-container {
            font-size: 13px;
            color: #333333;
            white-space: break-spaces;

            .pretty-print__atom-cases {
              padding-inline-start: 20px;
            }

            .pretty-print__atom-multicase-label {
              list-style-type: none;
              font-size: 14px;
              color: #333333;
              font-weight: 400;
            }

            i {
              font-size: 13px;
              font-weight: 500;
              text-wrap: balance;
            }

            .pretty-print_case-container {
              width: 100%;
              align-items: flex-start;
              gap: 15px;

              .content {
                width: 80%;

              }

              .value {
                white-space: nowrap;
              }
            }
          }
        }

      }
    }
  }
}