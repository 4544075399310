@use '@nu-art/ts-styles/index' as styles;

#generate-token {
  width: 200px;
  height: 250px;

  .ts-dialog__header {
    padding: 7px;
    background: white;
    border-bottom: 1px solid styles.gray(6);

    .header__buttons {
      .icon--wrapper {
        @include styles.color-svg(var(--prop-renderer__label-color));
        width: 12px;
      }
    }

    .header__title {
      color: var(--prop-renderer__label-color);
      font-size: 15px;
    }

  }

  .ts-dialog__main {
    padding-top: 15px;

    .created-title {
      gap: 5px;
      padding-top: 15px;
      align-items: center;

      .title {
        white-space: break-spaces;
        text-align: center;
      }
    }

    .ts-prop-renderer {
      width: 100%;
      align-items: center;

      .ts-prop-renderer__label {
        width: 180px;
      }

      .ts-input {
        max-width: 180px;
        padding: 2px 10px 2px 10px;
        border-radius: 4px;
        background: none;
        border: 1px solid #e3e9f2;

        &::placeholder {
          font-style: normal;
        }

      }
    }
  }

  .ts-dialog__buttons {
    .ts-dialog__buttons__center {
      padding-bottom: 20px;

      .ts-busy-button {
        margin: 0;
        background: #8ca5ca;
        height: 35px;
        padding-inline: 10px;
        border-radius: 5px;
        font-weight: 400;

        &:hover {
          background: rgb(108, 135, 176);
        }
      }
    }
  }
}