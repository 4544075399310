@use '@app/styles' as styles;

#page__disease-profile {
  height: 100%;

  .dp-page-message {
    @include styles.placeholderMessage
  }

  //General design for tables in this page
  .values-list,
  .variable-list,
  .reference-list {
    .ts-table {
      height: 100%;
      border: none;
      table-layout: auto;

      .ts-table__head {
        position: sticky;
        top: 0;
        z-index: 1;

        .ts-table__tr {
          height: var(--workspace__default-row-height);
          border: none;

          .ts-table__th {
            border: var(--border__values-table-regular);
            border-left: none;

            & > div {
              width: min-content;
            }
          }
        }
      }

      .ts-table__body {
        .ts-table__tr {
          height: var(--workspace__default-row-height);
          cursor: pointer;

          //Children
          .ts-table__td {
            padding-left: 5px;
            border: none;
            border-right: var(--border__values-table-regular);
            border-bottom: var(--border__values-table-regular);
          }

          &.selected {
            background: var(--color__selected_row);
          }
        }
      }
    }
  }

  .variable-list .ts-table .ts-table__head .ts-table__tr .ts-table__th {
    border-top: none
  }

  .qworkspace {
    .horizontal-space {
      .panel {
        overflow-x: hidden;
      }
    }
  }

  .disease-value-count {
    @include styles.disease-value-count;
  }
}



