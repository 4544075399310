@use '@app/styles' as styles;

.epidemiology-editor {
  height: 100%;
  width: 100%;
  gap: 20px;

  .ts-button {
    @include styles.mouseInteractiveBackground(white, styles.black(7));
    @include styles.mouseInteractiveIcon(styles.black(3));
    color: styles.black(3);
    border: 1px solid styles.black(3);
    border-radius: 6px;
    margin: 0;
  }

  .ts-prop-renderer {
    p {
      width: 100%;
      white-space: pre-wrap;
      font: {
        size: 14px;
      }
    }

    .ts-textarea {
      min-height: 150px;
      height: unset;
    }
  }

  .q-scroll-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
    align-items: stretch;

    .epidemiology-card {
      width: calc(50% - 20px);
      padding: 10px;
      border: 1px solid black;
      border-radius: 10px;
      position: relative;

      &.edit-mode {
        padding-block: 30px;
      }

      .epidemiology-card__delete {
        @include styles.mouseInteractiveIcon(styles.red(2));
        position: absolute;
        top: 5px;
        left: 5px;
        width: 20px;
        height: 20px;
      }
    }
  }
}