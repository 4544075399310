@use '@app/styles' as styles;
@use '@nu-art/ts-styles' as tsStyles;

#relations-tab-content {
  padding: 10px;
  gap: 10px;
  width: 100%;
  height: 100%;
  align-items: flex-end;
}

#add-relation-button {
  margin: 0;
  flex: none;
  gap: 10px;
  padding: 2px 8px;

  .icon--wrapper {
    @include tsStyles.color-svg(white);
    width: 22px;
    height: 22px;
    border: 2px solid white;
    border-radius: 50%;
  }

  span {
    font: {
      size: 17px;
      weight: bold;
    }
    color: white;
  }
}

#relations-table-wrapper {
  width: 100%;
  height: 0;
  flex-grow: 1;

  .ts-dropdown {
    .ts-dropdown__header {
      border: none;
    }
  }

  .ts-table {
    .ts-table__body {
      .ts-table__tr {
        height: 40px;

        .ts-table__td {
          margin: 0;
          padding: 0;
          height: 40px;

          .due-dropdown-flex {
            display: flex;

            .separator {
              width: 1px;
              height: 40px;
              background: black;
            }

            .ts-dropdown {
              width: unset;
              flex: 1;
            }
          }

          .bin-size {
            @include tsStyles.color-svg(white);
            background: styles.red(3);
            border-radius: 2px;
            height: 22px;
            width: 22px;
          }

          .inner-values__actions__accept {
            @include tsStyles.color-svg(white);
            background: styles.dark-green(3);
            border-radius: 2px;
            width: 22px;
            height: 22px;
            cursor: pointer;
            padding: 3px;
          }
        }
      }
    }
  }
}

.tooltip__content {
  height: 100%;
  background: white;
  padding: 10px;
  max-width: 350px;
  width: max-content;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  cursor: pointer;
  border: 2px solid var(--color__dialog-header-background);

  .tooltip {
    border-radius: 50%;
    border: 2px solid var(--color__table-item__color);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tooltip__title {
    text-decoration: underline;
    font: {
      size: 14px;
      weight: 600;
    };
  }
}