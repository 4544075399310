@use '@app/styles' as styles;

$font-family: 'Open Sans', sans-serif;
$font-color: #333;
$border-radius: 4px;

//Pop up for header actions
#disease-profile-manager__header-actions {
  $border-color: rgba(227, 233, 242, 1);

  background: white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  overflow: hidden;

  & > div {
    @include styles.mouseInteractiveBackground(transparent, rgba(246, 248, 252, 1));
    width: 150px;
    height: 38px;
    padding: 10px;
    font: {
      size: 13px;
      family: $font-family;
    }
    color: $font-color;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
  }
}

#manager__disease-profile {
  width: 100%;
  height: 100%;
  grid-template-areas:
          "header header header"
          "views vars info";
  grid-template-columns: 200px 220px auto;
  grid-template-rows: 100px auto;
  background: #fff;
  row-gap: 20px;

  .manager__disease-profile__views {
    $border-color: #e3e9f2;
    grid-area: views;
    border-right: 1px solid $border-color;
    padding-left: 20px;

    .manager__disease-profile__views__item {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      height: 30px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      &.selected {
        background: #f6f8fc;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }
    }
  }

  .manager__disease-profile__vars {
    @include styles.customScrollbar('vertical', true);
    $border-color: #e3e9f2;
    grid-area: vars;
    border-right: 1px solid $border-color;
    padding-left: 10px;

    .manager__disease-profile__vars__item {
      @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
      width: 100%;

      //Padding + Line height + borders = 30px height consistent until text-wrap
      padding: 4px 10px;
      line-height: 20px;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;

      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;

      .manager__disease-profile__vars__item__label {
        font: {
          size: inherit;
          family: inherit;
        }
        color: inherit;
        white-space: break-spaces;
        gap: 3px;

        .manager__disease-profile__vars__item__label__cc {
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }

      .manager__disease-profile__vars__item__variants {
        padding-left: 10px;

        .manager__disease-profile__vars__item__variants__item {
          font: {
            size: 12px;
            family: $font-family;
          }
          color: #ABABAB;
          line-height: 20px;
          white-space: break-spaces;
        }
      }

      &.selected {
        background: #f6f8fc;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
      }
    }
  }
}

.manager__disease-profile__general-info {
  grid-area: info;
  padding: 4px 50px 4px 20px;
  gap: 20px;

  .manager__disease-profile__general-info__header {
    font: {
      size: 16px;
      family: $font-family;
    }
    color: $font-color;
  }

  .manager__disease-profile__general-info__body {
    .dp-color-group-example {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}

.manager__disease-profile__disease-variable-data {
  $border-color: #e3e9f2;
  grid-area: info;
  padding: 4px 50px 4px 20px;
  gap: 20px;

  .manager__disease-profile__disease-variable-data__header {
    width: 100%;
    gap: 4px;
    flex: 0;

    .manager__disease-profile__disease-variable-data__header__title {
      width: 100%;
      font: {
        size: 16px;
        family: $font-family;
      }
      color: $font-color;
      white-space: break-spaces;
    }

    .manager__disease-profile__disease-variable-data__header__sub-title {
      width: 100%;
      font: {
        size: 12px;
        family: $font-family;
      }
      color: #7f7f7f;
      white-space: break-spaces;
    }
  }

  .manager__disease-profile__disease-variable-data__body {
    width: 100%;
    height: 0;
    flex-grow: 1;
    align-items: flex-start;

    .manager__disease-profile__disease-variable-data__section-selector {
      width: 120px;
      height: 100%;
      border-right: 1px solid $border-color;
      flex-shrink: 0;

      .manager__disease-profile__disease-variable-data__section-selector__item {
        @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
        height: 30px;
        width: 100%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font: {
          size: 14px;
          family: $font-family;
        }
        color: $font-color;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;

        &.selected {
          background: #f6f8fc;
          border-top: 1px solid $border-color;
          border-bottom: 1px solid $border-color;
        }
      }
    }

    .manager__disease-profile__disease-variable-data__edit-actions {
      gap: 15px;
      padding: 0 20px;

      .manager__disease-profile__disease-variable-data__edit-action {
        @include styles.mouseInteractiveBackground(transparent, #e3e9f2);
        height: 30px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font: {
          size: 14px;
          family: $font-family;
        }
        color: black;
        border-radius: 4px;
        border: 1px solid #e3e9f2;
      }
    }

    .manager__disease-profile__disease-variable-data__section-data {
      padding: 0 20px 20px;
      flex: 1;

      .q-scroll-wrapper {
        height: 0;
        flex-grow: 1;
      }

      .grid-separator {
        padding: 10px 0;
        grid-column: -1/1;
      }

      .dv-likelihood {
        width: 300px;
        gap: 10px;

        .ts-dropdown {
          width: 100%;
          min-width: unset;
        }

        .dv-likelihood__range {
          width: 100%;
          gap: 5px;
          justify-content: space-between;
          border: 1px solid #e3e9f2;
          border-radius: 4px;
          padding-right: 12px;

          .dv-likelihood__range__percent {
            font: {
              size: 14px;
              weight: 600;
              family: $font-family;
            }
            color: $font-color;
            flex-shrink: 0;
          }

          .ts-input {
            padding: 0;
            border: none;
          }
        }
      }
    }
  }
}