@use '@app/styles' as styles;

.tab-placeholder {
  @include styles.placeholderMessage
}

.panel-disease-values-list {
  width: 100%;
  height: 100%;

  .default_panel_header {
    height: 50px;
    flex-shrink: 0;
  }

  .ts-tabs {
    flex-grow: 1;
  }

  .q-scroll-wrapper {
    .ts-table {
      table-layout: fixed;

      .ts-table__head {
        .ts-table__tr {
          .ts-table__th {
            padding: 0;

            .node-data {
              width: 100%;
              height: 100%;
              padding: 0 5px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;

              .atributee {
                white-space: break-spaces;
                font: {
                  size: 10px;
                  weight: bold;
                }
              }

              .variable-name {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                white-space: break-spaces;
                font: {
                  size: 14px;
                  weight: bold;
                }
              }
            }
          }
        }
      }

      .ts-table__body {
        .ts-table__tr {
          .ts-table__td {
            padding: 0;

            .ts-dropdown {
              height: 50px;

              .ts-dropdown__header {
                border-bottom: none;
                cursor: pointer;
                align-items: center;

                .icon--wrapper {
                  width: 30px;
                }
              }

              .node-data,
              .ts-dropdown__unselect-item {
                font: {
                  size: 14px;
                };
                cursor: pointer;
                white-space: pre-wrap;
              }
            }

            .likelihood-cell {
              height: 100%;

              .ts-input {
                width: 50%;
                height: 100%;
                border: none;

              }

              .ts-dropdown {
                width: 30%;
                border-left: 1px solid black;
              }
            }
          }

          &.loading {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
  }
}
