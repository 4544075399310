$selected-color: #8ac927;
$text-color-hover: #2970F6;

.pathway-graph-view {
  width: 100%;
  height: 100%;
  background: #EFF3F5;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .pathway-graph-view__pathway-state {
    cursor: pointer;

    text {
      font: {
        size: 12px;
      }
    }

    //Hover on non selected nodes
    &:hover:not(.selected) {
      text {
        fill: $text-color-hover;
      }
    }

    //Design for selected nodes
    &.selected {
      rect {
        stroke: $selected-color;
      }
    }
  }

  .pathway-graph-view__pathway-transition {
    &.selected {
      path {
        stroke: $selected-color;
        stroke-width: 2;
      }

      polygon {
        fill: $selected-color;
        stroke: $selected-color;
      }
    }
  }

  .pathway-graph-view__transition-node {
    cursor: pointer;
  }

  .component--focused-object {
    position: absolute;
    right: 15px;
    bottom: 65px;
  }

  .pathway-graph-view__a-b {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    border: 2px solid #4f4f4f;
    border-radius: 6px;
    gap: 0;
    background: white;
  }
}