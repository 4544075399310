@use '@app/styles/frontend/ui/ui-manager/consts' as *;
@use "@nu-art/ts-styles" as styles;

#editor-advisor-context {
  height: 100%;
  width: 100%;
  background: #fff;
  row-gap: 20px;

  .manager__header {
    flex-shrink: 0;
    width: 100%;

    .manager__header__main {
      justify-content: flex-start;
      gap: 20px;

      .manager__header__title {
        gap: 0;
        height: unset;
        align-items: flex-start;
      }
    }

    .editor-advisor-context__header__tail {
      gap: 20px;
    }
  }

  .context-manager__editor-body {
    padding: 30px;
    //width: 100%;


    .ts-prop-renderer {
      .ts-prop-renderer__label {
        min-width: 100px;
        align-items: flex-start;
      }

      margin-bottom: 15px;
    }

    .ts-input {
      height: 40px;
    }

    .ts-dropdown {
      height: 40px;
    }

    .formdata-groups {
      gap: 20px;
      min-height: 300px;
      max-height: 55vh;
      width: unset;
      overflow-y: auto;
    }

    .formdata-single__container {
      align-items: flex-start;
      gap: 5px;

      .formdata-single__body {
        overflow-y: auto;
        max-height: 65vh;
        min-width: 65%;

        .rules-container {
          width: unset;
          align-items: flex-start;

          .ts-card {
            width: unset;
          }
        }

        .rules-list {
          gap: 20px;
          min-height: 300px;
          max-height: 55vh;
          overflow-y: auto;
          margin-bottom: 12px;

          .add-rule-button {
            min-height: 40px;
          }

          .rule-container {
            display: flex;
            flex-flow: column;
            height: fit-content;
            gap: 10px;
            //width: 80%;
            padding: 10px 15px 15px 15px;
            border: 1px solid #E3E9F2;
            border-radius: 5px;

            .rule-header {
              //width: 100%;
              justify-content: space-between;

              .icon--wrapper {
                @include styles.color-svg(#4f4f4f);
                cursor: pointer;
                height: 12px;
                width: 12px;
              }
            }

            .rule-title {
              color: #4f4f4f;
            }
          }
        }
      }
    }

    .variables-panel {
      max-height: 65vh;
    }

    //.ts-grid {
    //  @extend %prop-renderer-base-design;
    //  @include styles.customScrollbar('vertical');
    //  overflow-y: unset;
    //  height: 100%;
    //  min-height: 300px;
    //  row-gap: 20px;
    //  grid-template-columns: max-content 1fr;
    //  grid-template-rows: repeat(auto-fill, 50px);
    //  column-gap: 40px;
    //  width: auto;
    //
    //
    //
    //}

    .variables-panel {
      height: 85%;
      border-left: 1px solid #e3e9f2;
      padding-left: 10px;
    }
  }
}