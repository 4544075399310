.population-editor__variable-dropdown {
  grid-column: 2/3;
}

.population-editor__value-dropdown {
  grid-column: 3/-1;
}

.population-editor__add-specification {
  grid-column: 2/3;
  width: 30px;
  min-width: 30px !important;
}

.population-editor__variable {
  grid-column: 2/3;
  font: {
    size: 14px;
  }
  line-height: 30px;
}

.population-editor__value {
  grid-column: 3/-1;
  font: {
    size: 14px;
  }
  line-height: 30px;
}

.population-item-editor__data {
  line-height: 30px;
  font: {
    size: 14px;
  }

  &.span-row {
    grid-column: 2/-1;
  }
}