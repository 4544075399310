@use '../../functions-and-mixins/palette' as palette;

@mixin dark-theme__dialogs {
  //General
  --dialog__general-background: #FFFFFF;

  //Header
  --dialog__header-background: #{palette.dark-blue(2)};
  --dialog__header-text-color: #FFFFFF;

  //Fields
  --dialog__field-text-color: #{palette.gray-blue(3)};
}
