.health-care-space {
  height: 100%;

  .ts-workspace__panel {
    background: var(--color__background__general-panel);
  }

  .variable-list .ts-table .ts-table__head .ts-table__tr .ts-table__th {
    border-top: none
  }

  .qworkspace {
    .horizontal-space {
      .panel {
        overflow-x: hidden;
      }
    }
  }
}

