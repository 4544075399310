@use '../../functions-and-mixins/palette' as palette;

.ts-prop-renderer {

  .ts-prop-renderer__label {
    font: {
      size: 0.8rem;
      weight: 600;
    }
    color: var(--prop-renderer__label-color);
  }

  &.checkbox-field {
    gap: 12px;
    flex-direction: row-reverse;
    cursor: pointer;
    padding: 4px 8px;
    border: 1px solid palette.dark-blue(2);
    border-radius: 4px;
  }

  &.horizontal {
    gap: 4px;
  }

  &.vertical {
    .ts-prop-renderer__label {
      margin-bottom: 4px;
    }
  }
}

.ts-props-renderer__horizontal-chain {
  width: 100%;
  gap: 12px;
}