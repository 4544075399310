@use '@nu-art/thunderstorm/styles' as *;
@use '@app/styles' as styles;

$headerHeight: 50px;

@function envColour($env: '') {
  @if $env == 'local' {
    @return styles.dark-green(3);
  }
  @if $env == 'dev' {
    @return styles.purple(2);
  }
  @if $env == 'staging' {
    @return styles.pink(1);
  }
  @return styles.dark-blue(1);
}

.knowledge-manager {
  height: 100%;
  width: 100%;
  isolation: isolate;

  .knowledge-manager__header {
    //Dimensions
    height: $headerHeight;
    width: 100%;
    flex-shrink: 0;

    //Colors
    background-color: envColour();
    color: white;

    &.local {
      background-color: envColour('local');
    }

    &.dev {
      background-color: envColour('dev');
    }

    &.staging {
      background-color: envColour('staging');
    }

    //Display
    display: flex;
    justify-content: center;
    align-items: center;

    //Children
    .header__left-container {
      justify-content: flex-start;

      //Children
      .icon--wrapper {
        @include styles.mouseInteractiveIcon(white, var(--color__side-nav-bar__open-icon_hover));
        margin-left: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: transform 200ms ease-out;

        &.open {
          transform: rotateY(180deg);
          transition: transform 400ms ease-out;
        }
      }


      .header__left-container__title {
        margin-left: 35px;
        font-weight: 300;
      }
    }

    .header__center-container {
      justify-content: center;

      .qsearch {
        background: styles.gray-blue(4);
        width: 360px;

        .qsearch-icon {
          background: var(--color__search-bar-icon);
        }

        & > span {
          background: var(--color__search-bar-text);
        }

        .ts-input {
          &::placeholder {
            color: var(--color__search-bar-text)
          }

          color: var(--color__search-bar-text);
        }
      }
    }

    .header__right-container {
      justify-content: flex-end;
      margin-right: 30px;
      gap: 30px;

      .header__logo {
        width: 26px;
        height: 26px;

        [data-name='blue-rect'] {
          fill: white;
        }
      }

      .notifications-button {
        width: 26px;
        height: 26px;
        cursor: pointer;

        svg path {
          stroke: white;
          stroke-width: 0.4;
          fill: white;
        }
      }

      .container-discussions-button {
        position: relative;

        .discussions-button {
          @include styles.mouseInteractiveIcon(white);
          width: 26px;
          height: 26px;

          svg path {
            stroke: white;
          }
        }

        .there-are-unresolved-discussions {
          position: absolute;
          top: 0;
          right: -2px;
          width: 8px;
          height: 8px;
          background-color: #f24822;
          border-radius: 50%;
          border: 2px solid envColour();
          box-sizing: content-box;

          &.local {
            border-color: envColour('local');
          }

          &.dev {
            border-color: envColour('dev');
          }

          &.staging {
            border-color: envColour('staging');
          }
        }
      }
    }
  }

  //Children
  .knowledge-manager__main {
    height: calc(100% - $headerHeight);
    width: 100%;
    background-color: white;

    .knowledge-manager__main__content {
      flex-grow: 1;
      height: 100%;
      align-items: flex-start;
    }
  }
}