@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as consts;

.pathway__transition {
  gap: 10px;

  .pathway__transition__source,
  .pathway__transition__target {
    color: consts.$font-color;
    font: {
      size: 14px;
      family: consts.$font-family;
    }
  }

  .icon--wrapper {
    @include styles.mouseInteractiveIcon(consts.$font-color);
    cursor: default;
  }

  &.clickable:hover {
    .pathway__transition__source,
    .pathway__transition__target {
      color: consts.$font-color-clickable;
    }
  }

  &.green {
    .icon--wrapper {
      @include styles.mouseInteractiveIcon(#8AC927);
      cursor: default;
    }
  }
}