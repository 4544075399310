@use '@app/styles' as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.categories-area {
  height: 100%;
  width: 100%;
  //over
  .tags-area__header {
    width: 100%;
    height: var(--workspace__default-row-height);
    justify-content: flex-start;
    padding: 0 10px;
    background: var(--color__header__categories);

    .qsearch {
      background: var(--color__header__categories-search);
      flex-grow: 1;
      margin: 0 10px 0 0;
      padding-inline: 5px;
      box-shadow: var(--shadow__general-inset);

      & > span {
        background: var(--color__background__gray);
      }

      .ts-input {
        display: flex;
        padding-bottom: 4px;
        font-size: var(--workspace__default-font-size);
        color: var(--color__header__search-text);

        &::placeholder {
          color: var(--color__header__search-text);
          font-size: var(--workspace__default-font-size);
        }
      }
    }

    .categories-area__header__icon {
      width: 16px;
      cursor: pointer;
    }
  }

  .q-scroll-wrapper {
    height: 0;
    flex-grow: 1;
  }

  .categories-tree {
    width: 100%;
    display: flex;
    justify-content: left;

    .ts-tree {
      width: 100%;
      height: fit-content;

      .depth-0 {
        padding-left: 4px;
      }


      .ts-tree__node {
        border: 1px solid transparent;
        height: var(--workspace__default-row-height);
        cursor: pointer;

        .ll_h_c {
          height: 100%;
          align-items: center;
          gap: 4px;
        }

        .node-data {
          width: 100%;
          margin-right: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ts-editable-text__text {
            @include styles.text-ellipsis;
            width: 0;
            flex-grow: 1;
            font-size: var(--workspace__default-font-size);
          }
        }

        .node-icon {
          & > div {
            width: 12px;
            height: 12px;

            svg path {
              fill: #A1A1A1;
            }
          }

          &.expanded > div {
            transform: rotateZ(90deg);
          }
        }

        &:hover {
          background: transparent;
          border: var(--ws__border__item-hover);
          box-shadow: var(--ws__shadow__item-hover);
        }

        &.ts-tree__selected-node {
          background: var(--ws__color__item-selected);
        }
      }
    }
  }

  .categories-area__macro-tags {
    width: 100%;

    .ts-collapsable-container__header {
      background: transparent;
      box-shadow: none;
      border: none;
      justify-content: flex-start;
      font: {
        size: 14px;
      }

      .ts-collapsable-container__header__caret {
        margin: 0;

        .icon--wrapper {
          @include styles.mouseInteractiveIcon(#A1A1A1);
          height: 9px;
        }

        &:not(.collapsed) {
          rotate: 90deg;
        }
      }
    }

    .ts-collapsable-container__container {
      background: transparent;

      .categories-area__macro-tag {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 30px;
        cursor: pointer;
        font: {
          size: 14px;
        }
        text-transform: capitalize;
      }
    }
  }
}

#tags__menu {
  @extend %actions-popup;
}