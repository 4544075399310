@use '@app/styles' as styles;

.panel__disease-properties {
  width: 100%;
  height: 100%;

  .panel-header {
    width: 100%;
    height: 40px;
    background: var(--color__header__categories);
    padding: 0 10px;
  }

  .panel__main {
    @include styles.customScrollbar('vertical', true);
    background: var(--color__background__general-panel);
    width: 100%;
    height: 0;
    flex-grow: 1;

    .ts-tree {

      //Nodes
      .ts-tree__children-container {
        .ts-tree__node {
          padding: 0;
          height: var(--workspace__default-row-height);
          cursor: pointer;

          //Data in node
          .node-data {
            padding: 0 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            border-bottom: 1px solid var(--color__table-row__border);

            label {
              @include styles.text-ellipsis;
              font-size: 14px;
            }

            &.misc {
              background: styles.red(7);
            }
          }

          //Selected Node
          &.ts-tree__selected-node {
            background: var(--color__selected_row);
          }
        }
      }
    }
  }
}