//@use 'node_modules/@nu-art/thunderstorm/styles' as *;

.qsearch {
  margin-inline-start: 12px;
  height: 24px;
  //max-width: 250px;
  flex-grow: 1;
  border-radius: var(--border__general-radius);
  background: magenta;

  .qsearch-icon {
    cursor: pointer;
    margin: 5px;

    svg path {
      fill: black;
    }
  }

  .clear-icon {
    cursor: pointer;

    svg {
      height: 13px;
    }
  }

  & > span {
    margin-left: 8px;
    margin-right: 4px;
    height: 13px;
    width: 13px;
  }

  .ts-input {
    min-width: unset;
    max-width: none;
    background: transparent;
    height: 100%;
    min-height: unset;
    padding-inline: 4px;
    border: none;
    outline: none;

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }

    &:focus {
      &::-webkit-input-placeholder {
        opacity: 0;
      }
    }
  }
}
