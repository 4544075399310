@use '../../../../index' as styles;
@use '../../../ui/ui-manager/consts' as *;

.quai-manager-ui__confirmation-dialog {
  background: white;
  padding: 20px 30px;
  border-radius: 4px;
  border: 1px solid $border-color;
  gap: 20px;
  align-items: center;

  .quai-manager-ui__confirmation-dialog__header {
    justify-content: center;
    align-items: center;
    font: {
      size: 18px;
      family: $font-family;
    }
    color: $font-color;

    * {
      font-size: inherit;
      font-family: inherit;
      color: inherit;
    }
  }

  .quai-manager-ui__confirmation-dialog__content {
    gap: 10px;
    justify-content: center;
    align-items: center;
    font: {
      size: 16px;
      family: $font-family;
    }
    color: $font-color;
    max-width: 280px;
    white-space: pre-wrap;
    text-align: center;

    * {
      max-width: 100%;
      font-size: inherit;
      font-family: inherit;
      color: inherit;
      white-space: pre-wrap;
      text-align: inherit;
    }
  }

  .quai-manager-ui__confirmation-dialog__buttons {
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    .quai-manager-ui__confirmation-dialog__button {
      @extend %pathway-button-design;
    }
  }
}