@use '../../../index' as styles;
@use "@nu-art/ts-styles" as tsStyles;

$font-color: #4f4f4f;
$font-color-clickable: #2970f6;
$font-family: "Open Sans", sans-serif;
$border-color: #e3e9f2;
$selected-green: #8AC927;

%actions-popup {
  $border-color: $border-color;
  border: 1px solid $border-color;
  border-radius: 4px;
  overflow: hidden;
  background: #ffffff;
  translate: calc(-50% + 15px) 0;

  .popup-action {
    @include styles.mouseInteractiveBackground(transparent, #f6f8fc);
    height: 40px;
    font: {
      size: 13px;
      family: $font-family;
    }
    color: $font-color;
    line-height: 40px;
    padding-inline: 10px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &.not-ready {
      opacity: 0.5;
    }
  }
}

%ui-manager__ts-input-design {
  width: 300px;
  height: 40px;
  border: 1px solid #e3e9f2;
  border-radius: 4px;
  padding: 0 10px;
  font: {
    size: 14px;
    family: $font-family;
    weight: normal;
  }
  color: $font-color;
  outline: none !important;

  &[data-error-level="error"] {
    border-color: red;
  }

  &.error {
    border-color: styles.red(3);
  }
}

%ui-manager__ts-dropdown-design {
  &[data-error-level="error"] {
    .ts-dropdown__header {
      border-color: red;
    }
  }

  max-width: 300px;
  height: 40px;

  .ts-dropdown__header {
    border: 1px solid #e3e9f2;
    border-radius: 4px;
    gap: 4px;
    padding: 0 4px 0 10px;
    align-items: center;

    .ts-input {
      padding: 0;
      border: none;
    }

    .ts-dropdown__selected {
      font: {
        size: 14px;
        family: $font-family;
        weight: normal;
      }
      color: $font-color;
    }

    .icon--wrapper {
      @include styles.mouseInteractiveIcon($font-color);

      &.flip {
        rotate: 180deg;
      }
    }
  }

  .ts-dropdown__items-container {
    border: 1px solid #e3e9f2;
    border-radius: 0 0 4px 4px;
    translate: 0 -2px;

    .ts-tree__node {
      @include styles.mouseInteractiveBackground(transparent, #efefef);
      font: {
        size: 14px;
        family: $font-family;
      }
      color: $font-color;
      white-space: pre-wrap;
      border-bottom: 1px solid #e3e9f2;
    }
  }
}

%ui-manager__ts-text-area-design {
  width: 300px;
  height: 200px;
  box-shadow: none;
  font: {
    size: 14px;
    family: $font-family;
    weight: normal;
  }
  color: $font-color;
  border: 1px solid #e3e9f2;
  border-radius: 4px;
  background: #ffffff;
  resize: none;

  &[data-error-level="error"] {
    border-color: red;
  }
}

%pathway-button-design {
  @include styles.mouseInteractiveBackground(#ffffff);
  height: 40px;
  width: unset;
  min-width: 85px;
  padding: 2px 10px;
  margin: 0;
  border: 1px solid #e3e9f2;
  border-radius: 4px;
  font: {
    size: 14px;
    family: $font-family;
    weight: normal;
  }
  color: $font-color;
  text-transform: uppercase;

  &.ts-busy-button__disabled {
    @include styles.mouseInteractiveBackground(#ffffff);
    opacity: 0.5;
  }

  &.preferred-action, &.preferred-action.ts-busy-button__disabled {
    @include styles.mouseInteractiveBackground(#ee9b00, #f38f0b);
    color: #ffffff;
    border: none;
  }
}

%prop-renderer-base-design {
  .ts-prop-renderer__label {
    position: relative;
    color: $font-color;
    font: {
      size: 14px;
      family: $font-family;
      weight: 600;
    }
    line-height: 40px;

    .ts-prop-renderer__error {
      height: 10px;
      width: 100%;

      position: absolute;
      left: 0;
      bottom: 0;
      translate: 0 calc(100% - 8px);

      color: styles.red(4);
      line-height: 10px;
      font-size: 8px;
    }
  }
}

%ui-manager__ts-card {
  min-width: 700px;
  max-width: 100%;
  border-radius: 4px;
  background: white;
  padding: 20px;
  border: 1px solid #eff3f5;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.15);
}

%ui-manager__tag-group-design {
  height: 100%;
  width: 75%;

  .ts-dropdown {
    height: 40px;
  }

  .tag-group {
    border: 1px solid #E3E9F2;
    border-radius: 3px;

    .tag-group__title {
      border-bottom: 1px solid #E3E9F2;

      .icon--wrapper {
        @include tsStyles.color-svg(#4f4f4f);
        width: 10px;
        height: 10px;
      }

      span {
        font-weight: 400;
        font-size: 15px;
        color: #4f4f4f;
      }
    }

    .tag {
      background: unset;
      border: 1px solid #E3E9F2;
      border-radius: 3px;
      height: 30px;

      .ts-button {
        border-radius: unset;
        border: none;
        border-right: 1px solid #E3E9F2;

        &:hover {
          background: tsStyles.gray(7);
        }

        &:active {
          background: tsStyles.gray(6);
        }

        .icon--wrapper {
          @include tsStyles.color-svg(#4f4f4f);
        }
      }

      span {
        color: #4f4f4f;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .ts-dropdown {
      width: 200px;
      height: 30px;
    }
  }
}