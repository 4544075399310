@use "@app/styles" as styles;

.panel__variables {
  height: 100%;
  width: 100%;
  background: var(--color__background__general-panel);

  .panel__variables__header {
    width: 100%;
    height: var(--workspace__default-row-height);
    justify-content: flex-end;
    background: styles.dark-green(7);
    padding: 0 10px;

    .panel__variables__header__icon {
      cursor: pointer;
      width: 16px;
    }

    .panel__variables__header__title {
      font: {
        size: 16px;
        weight: 600;
      };
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .qsearch {
      background: var(--color__header__variables-search);
      margin-inline: 12px;
      box-shadow: var(--shadow__general-inset);
      min-width: 100px;

      .ts-input {
        padding-bottom: 4px;
        font-size: var(--workspace__default-font-size);
        color: var(--color__header__search-text);

        &::placeholder {
          color: var(--color__header__search-text);
          font-size: var(--workspace__default-font-size);
        }
      }
    }

    .add-button {
      @include styles.circleButton;
    }
  }

  .variable-renderer {
    height: 25px;
    line-height: 28px;
    display: flex;
    text-align: center;
    background: #3000FF;
  }

  .list-wrapper {
    @include styles.customScrollbar('vertical', true);
    height: 100%;
    width: 100%;
    overflow: hidden;

    .variable-list {
      .var-item {
        height: 50px;
        gap: 12px;
        border-bottom: 1px solid black;
        flex-shrink: 0;
        padding: 0 8px;

        &.selected {
          background: styles.dark-blue(7);
        }

        .var-name {
          @include styles.text-ellipsis;

          &.attribute {
            padding-left: 32px;
          }

          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: bold;
          }

          .filter-pass-reason {
            @include styles.text-ellipsis;
            flex-shrink: 1;
            font: {
              size: 12px;
              weight: bold;
            }
          }
        }

        .var-item-tail {
          gap: 10px;
          flex-grow: 1;
          justify-content: flex-end;
          overflow: hidden;

          .edit-button {
            display: none;
            padding: 5px;
            border-radius: 8px;
            transition: all 200ms linear;

            .icon--wrapper {
              width: 20px;
              height: 20px;

              svg path {
                transition: all 200ms linear;
              }
            }

            &:hover {
              background: styles.dark-blue(2);

              .icon--wrapper svg path {
                fill: white
              }
            }
          }
        }

        &:hover {
          .var-item-tail .edit-button {
            display: block;
          }
        }
      }
    }
  }

}

//Panel of DP properties
.panel__disease-properties-data {
  .panel__variables .variable-list {
    height: 0;
    flex-grow: 1;
  }
}