@use '@app/styles' as styles;

.edit-user-details {
  width: 100%;
  flex-grow: 1;
  gap: 10px;
  padding: 10px 20px;

  .ts-input {
    border: 1px solid styles.dark-blue(1);
    border-radius: 6px;
  }

  .ts-busy-button {
    @include styles.mouseInteractiveBackground(styles.dark-blue(2), styles.dark-blue(1));
    width: 100%;
    margin: auto 0 0;
  }
}

.edit-user-details__no-user {
  width: 100%;
  font: {
    size: 18px;
    weight: 600;
  }
  white-space: pre-wrap;
}