@use '@app/styles' as styles;

.ts-tree.ts-tree__children-container.ts-tree__node {
  cursor: default;
  box-shadow: inset 2px 2px 0 rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .07);

  &:hover {
    background: white;
  }
}

.ts-popup {
  .ts-popup__content {
    width: unset;
    border-radius: 8px;
    background: white;
  }
}

.create-discussion__content {
  cursor: default;
  width: 350px;
  display: flex;
  flex-grow: 1;
  box-shadow: -1px 1px 5px 5px rgba(140, 153, 169, 0.75);
  padding: 10px;
  border-radius: 8px;

  .create-discussion__header {
    width: 100%;
    padding: 10px;
    gap: 2px;
    background: #8A8A8A10;

    .entity-type {
      text-align: start;
      color: #4b4b4b;
      font-weight: 600;
      font-size: 14px;
    }

    .entity-label {
      font-size: 16px;
      color: #1e98d7;
      padding: 2px;
      display: flex;
      flex-grow: 1;
      font-weight: 650;
      white-space: break-spaces;
    }
  }
}