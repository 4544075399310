@use "@app/styles" as styles;

.variable-info-tooltip-icon {
  margin-left: 12px;
  width: 25px;
  height: 25px;
  font: {
    size: 14px;
    weight: 800;
  }
  display: flex;
  justify-content: center;
  align-self: center;
  text-decoration: none;
  color: var(--color__tooltip__variable-information);
  border: 3px solid var(--color__tooltip__variable-information);
  flex-shrink: 0;
  border-radius: 50%;
  cursor: help;

  &.error {
    color: styles.red(4);
    border: 3px solid styles.red(4);
  }
}

#variable-info-tooltip {
  background: white;
  padding: 10px;
  width: fit-content;
  max-width: unset;
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  -webkit-box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 50px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  border: 2px solid var(--color__dialog-header-background);

  //General field design
  .field {
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    column-gap: 20px;
    row-gap: 10px;

    .field-title {
      font: {
        size: 14px;
        weight: bold;
      }
    }

    .field-data {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      //In-case field-data is not an element but a container
      flex-wrap: wrap;
      column-gap: 5px;
      row-gap: 3px;

      .field-data__item {
        font-size: 14px;
      }
    }
  }

  //Variable specific info container
  .variable-info-tooltip__var-data {
    height: 100%;
    max-width: 270px;
    justify-content: flex-start;
    padding: 10px;
    row-gap: 5px;
  }

  //Reference specific info container
  .variable-info-tooltip__refs-data {
    height: 100%;
    max-width: 300px;
    justify-content: flex-start;
    border-left: 2px solid black;

    .ref-item {
      padding: 10px;
      width: 100%;
      row-gap: 5px;

      &:not(:last-child) {
        border-bottom: 1px solid black;
      }

      .ref-link {
        text-decoration: none;
        font-size: 12px;
        font-weight: 600;
        background: styles.black(6);
        color: white;
        border-radius: 10px;
        padding: 2px 10px;
        min-width: 100px;
        text-align: center;

        &.snomed {
          background: styles.dark-blue(2);
        }

        &.loinc {
          background: styles.purple(2);
        }
      }

      .ref-warning {
        width: 200px;
        border: 1px solid styles.red(4);
        border-radius: 6px;
        background: transparent;
        margin: 0;
        justify-content: flex-start;
        gap: 2px;
        padding-left: 8px;
        min-height: unset;

        .warning-message,
        .hover-message {
          color: styles.red(4);
          font: {
            size: 12px;
            weight: 600;
          };
          margin: 0 5px;
        }

        .hover-message {
          display: none;
        }

        .warning-icon {
          border: 2px solid styles.red(4);
          border-radius: 50%;
          width: 15px;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          font: {
            size: 10px;
            weight: 600;
          }
          color: styles.red(4);
        }

        &:hover {
          .warning-message {
            display: none;
          }

          .hover-message {
            display: unset;
          }
        }
      }
    }
  }
}
