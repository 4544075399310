@use '@app/styles' as styles;

@mixin toRGBA($hexColor, $opacity) {
  background-color: rgba($hexColor, $opacity)
}

#disease-profile-editor {
  width: 900px;
  height: 90vh;

  .missing-tag {
    font-size: 12px;
    padding: 5px;
    color: styles.red(3);
  }

  .permissions-editable__value {
    white-space: break-spaces;

    &.text-area {
      @include styles.customScrollbar('vertical');
      background: rgba(0, 0, 0, 0.02);
      box-shadow: inset 1px 1px 5px #00000030;
      padding: 8px;
      border-radius: 4px;
      height: 135px;
      width: 100%;

      font: {
        size: 12px;
        weight: 600;
      }
    }
  }

  .ts-dialog__header {
    .header__title {
      @include styles.text-ellipsis;
      margin-right: 20px;
    }

    .dialog__header__buttons {
      align-items: center;
      gap: 16px;

      .exit_icon {
        svg path {
          fill: white;
        }
      }

      .dp-complete {
        gap: 8px;

        .dialog__field-title {
          color: white;
        }

        .ts-checkbox.quai {
          .ts-checkbox__button {
            border: 1px solid white;

            &.ts-checkbox__button__unchecked {
              background: white;
            }
          }
        }
      }
    }
  }

  .ts-dialog__main {
    gap: 20px;

    .ts-tabs {
      height: 100%;
      width: 100%;

      .ts-tabs__content {
        padding: 20px;

        .columns-container {
          width: 100%;
          column-gap: 20px;
        }

        .col {
          flex-grow: 1;
          flex-basis: 0;
          flex-shrink: 0;
          justify-content: flex-start;
          height: 100%;
          gap: 20px;
        }
      }
    }

    .list-wrapper {
      margin-top: 10px;
      width: 100%;
      height: 100%;
    }

    .general-tab {
      width: 100%;
      height: 100%;

      .columns-container {
        margin-bottom: 20px;

        .ts-textarea {
          height: 135px;
        }
      }

      .disease-editor__container__disease-name {
        width: 100%;
      }

      .disease-editor__container__disease-initials {
        width: 100%;
        column-gap: 5px;
        row-gap: 5px;
        align-items: flex-end;
        flex-wrap: wrap;

        .ts-input {
          flex-grow: 1;
          color: black;
          width: 100%;
          max-width: unset;
          font-size: 14px;
          //Border
          border: none;
          border-bottom: 1px solid #{styles.dark-blue(1)};
          border-radius: 0;

          &:focus {
            outline: none;
          }

          &::placeholder {
            font-size: 14px;
            color: var(--color__text__placeholder-1);
            font-style: italic;
          }

          &.error {
            border-bottom: 1px solid #{styles.red(2)};
          }
        }
      }

      .ts-textarea {
        margin: 5px 0 10px;
        height: 90px;
      }

      .ts-input {
        max-width: unset;
      }
    }

    .epidemiology-tab {
      width: 100%;
      height: 100%;
      gap: 20px;

      .ts-textarea {
        margin-top: 10px;
        height: 90px;
      }

      .sex-ratio-inputs {
        background: rgba(0, 0, 0, 2%);
        border-radius: 10px;
        box-shadow: inset 1px 1px 5px #00000030;
        margin-top: 10px;

        .ts-input {
          max-width: 50px;
          border: none;
          text-align: center;
          background: transparent;

          &:first-child {
            border-right: 2px solid rgba(0, 0, 0, 0.15);
          }

          /* Chrome, Safari, Edge, Opera */
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type=number] {
            -moz-appearance: textfield;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    .references-tab {
      height: 100%;
      width: 100%;

      .ref-title {
        width: 100%;
        font-size: 14px;
        margin-top: 15px;
      }

      .ref-data {
        width: 100%;
        font-size: 14px;
        white-space: normal;
      }

      //Refs table
      .list-wrapper {
        box-shadow: inset 0px 0px 15px -1px rgba(0, 0, 0, 0.15);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        overflow: hidden;

        .custom-scrollbar__vertical {
          width: 100%;
          height: 100%;
        }

        .ts-table {
          border-top: none;

          .ts-table__body .ts-table__tr .ts-table__td {
            .td-data {
              width: 70px;
            }

            &:not(:first-child) {
              .td-data {
                width: 370px;
              }
            }
          }
        }
      }
    }

    .tags {
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;

      .tag-group-container {
        flex: 1;
        gap: 5px;

        span {
          width: 100%;
          font: {
            size: 13px;
            weight: bold;
          }
        }

        .tags-container {
          width: 100%;
          gap: 5px;

          .tag {
            @include styles.categoryPathItem;
            background: styles.orange(5);
          }
        }
      }
    }
  }

  .score-reference {
    width: 100%;
    gap: 5px;


    & > * {
      width: unset;
      flex-grow: 1;
      white-space: pre-wrap;
      flex-basis: 0;
    }
  }
}

#initials-error {
  padding: 10px;
  background: white;
  border: 2px solid styles.red(2);
  border-radius: 6px;

  & > div {
    width: 250px;
    white-space: pre-wrap;
  }
}