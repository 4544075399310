@use "@app/styles" as styles;

#editor-wrapper__order {
  width: 100%;
  height: 100%;

  .quai-manager-ui__header {
    width: unset;
    min-width: 40%;
    padding: 10px 0 10px 10px;
  }

  .manager__header {
    width: 100%;

    &.edit-mode {
      min-height: 70px;
    }

    .manager__header__main {
      justify-content: flex-start;
    }
  }

  .variables-panel {
    @include styles.customScrollbar('verical', true);
    padding-top: 30px;
    max-height: 90%;
  }
}