@use '@nu-art/thunderstorm/styles' as *;
@use "@app/styles" as styles;
@use "./knowledge-manager/components/themes" as kmThemes;

//General Imports
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

//Import Library Styles
@import "@app/styles/defaults";

* {
  -webkit-user-drag: none;
  user-select: none;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;;
  font-size: 1rem;
  white-space: nowrap;
}

#app {
  height: 100%;
  position: relative;
}

body {
  @include styles.get-device-css-vars__base('default');
  margin: 0;
  height: 100vh;
  overflow: hidden;

  @each $theme in styles.$themes {
    &[theme=#{$theme}] {
      @include styles.get-theme-css-vars__base($theme);
      @include kmThemes.get-theme-css-vars__km($theme);
    }
  }

  @each $device in styles.$devices {
    &[device=#{$device}] {
      @include styles.get-device-css-vars__base($device);
    }
  }
}

img {
  display: inline-block;
}

input {
  font-size: var(--font-size);
}

.page-container {
  width: 100%;
  flex-grow: 1;
}

.unselectable {
  //Make text and image unselectable
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-transparent-container {
  inset: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.custom-scrollbar__vertical {
  @include styles.customScrollbar('vertical')
}

.custom-scrollbar__horizontal {
  @include styles.customScrollbar('horizontal')
}

.a {
  &:hover {
    background: var(--color__popup-menu-item-hover);
  }
}

.ts-memory-monitor {
  display: none !important;
}

.ts-popup-menu {
  .ts-popup-menu__menu {
    width: unset;
    border: 1px solid styles.dark-blue(2);

    .ts-tree {
      .ts-tree__node {
        .node-data {
          font-size: 14px;
        }

        &:hover {
          background: var(--color__popup-menu-item-hover);
        }
      }
    }
  }
}

.icon--wrapper {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}

.ts-app-tools .ts-app-tools__header {
  .user-thumbnail {
    margin-inline: auto 20px;
  }
}