@use '../../functions-and-mixins/palette' as palette;

@mixin dark-theme__ts-button {
  //Background
  --button__background-color: #{palette.black(6)};
  --button__background-color-hover: #{palette.black(5)};
  --button__background-color-active: #{palette.black(4)};
  --button__background-color-disabled: #{palette.gray-blue(2)};
  --button__background-color-desired: #{palette.dark-blue(3)};
  --button__background-color-desired-hover: #{palette.dark-blue(2)};
  --button__background-color-desired-active: #{palette.dark-blue(1)};
  //Color
  --button__text-color: white;
  --button__text-color-desired: white;
}