@use '@app/styles/frontend/ui/ui-manager/consts' as *;
@use "@nu-art/ts-styles" as styles;

#editor-log-template {
  height: 100%;
  width: 100%;
  background: #fff;
  row-gap: 20px;


  .manager__header {
    flex-shrink: 0;
    width: 100%;

    .manager__header__main {
      justify-content: flex-start;
      gap: 20px;

      .manager__header__title {
        gap: 0;
        height: unset;
        align-items: flex-start;
      }
    }

    .editor-log-template__header__tail {
      gap: 20px;

      .ts-editable-item-status {
        font-size: 14px;
        color: #33333388;
      }
    }
  }

  .log-template-manager__editor-body {
    @include styles.customScrollbar('vertical');
    padding: 20px;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    flex-grow: 1;

    .ts-prop-renderer {
      margin-top: 20px;

      .ts-prop-renderer__label {
        color: #4f4f4f;
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        line-height: 40px;
      }
    }

    .ts-grid {
      @extend %prop-renderer-base-design;
      grid-template-columns: max-content 1fr;
      column-gap: 40px;
      row-gap: 20px;
      width: 100%;

      .ts-prop-renderer__label {
        min-width: 100px;
        height: fit-content;
      }

      .ts-input {
        height: 40px;
        max-width: unset;
        width: 40%;
      }

      .ts-textarea {
        width: 40%;

        &.template-title {
          height: 80px;
        }
      }
    }
  }

  #log-templates-params-editor, #log-templates-external-params-editor {
    grid-template-columns: max-content;

    .params-editor__row {
      width: 100%;
      gap: 2%;

      .parameter-key {
        text-transform: capitalize;
        color: #4f4f4f;
        font-size: 14px;
        max-width: 110px;
        min-width: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .row-params {
        justify-content: flex-start;
        gap: 10px;

        .time-format-editor {
          gap: 10px;
        }
      }

      .log-templates__type-dropdown {
        width: 200px;
      }

      .log-templates__declaration-dropdown {
        width: 150px;
      }

      &.external {
        gap: 10%;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;

        .parameter-key {
          min-width: 120px;
          max-width: unset;
          text-overflow: unset;
          overflow: auto;
          text-transform: unset;
          font-size: 13px;

          &.key {
            font-weight: 600;
          }
        }
      }
    }

    .ts-dropdown {
      height: 40px;
    }
  }

}