$input-border: 1px solid black;
$border-radius: 5px;
$line-height: 30px;

.prognosis-item-editor {
  grid-template-columns: 100px 1fr 1fr;
  column-gap: 10px;
  width: 100%;

  &.edit-mode {
    row-gap: 5px;
  }

  .ts-prop-renderer__label {
    line-height: $line-height;
    font: {
      size: 14px !important;
      weight: normal !important;
    }
    margin-bottom: 0 !important;
  }

  .ts-input {
    border: $input-border;
    border-radius: $border-radius;
    height: $line-height;
    min-height: unset;
    font-size: 14px;
    outline: none !important;
  }

  .ts-dropdown {
    height: $line-height;
    border: $input-border;
    border-radius: $border-radius;
    max-width: 300px;

    .ts-dropdown__header {
      border: none;
      background: transparent;
      align-items: center;
      padding-right: 5px;
    }
  }

  .prognosis-item-editor__percentage-input {
    border: $input-border;
    border-radius: $border-radius;
    height: $line-height;
    gap: 5px;
    padding-right: 5px;
    max-width: 300px;

    .ts-input {
      height: $line-height;
      min-height: unset;
      border: none;
      font-size: 14px;
      flex-grow: 1;
      width: 0;
      min-width: unset;
      outline: none !important;
    }

    .prognosis-item-editor__percentage-input__icon {
      font: {
        size: 14px;
        weight: bold;
      }
      flex-shrink: 0;
    }
  }

  .prognosis-item-editor__data {
    line-height: $line-height;
    font: {
      size: 14px;
    }

    &.span-row {
      grid-column: 2/-1;
    }
  }
}