@use '@app/styles' as styles;

.new-discussion {
  padding: 20px;
  gap: 10px;
  position: relative;

  .new-discussion__title {
    color: #1e98d7;
    font: {
      size: 13px;
    }
  }

  .ts-textarea {
    height: 77px;

    color: #4b4b4b;
    font-size: 13px;

    padding: 10px;
    border-radius: 4px;
    background-color: white;
    box-shadow: -2px 2px 6px 0px rgba(174, 193, 218);
    resize: none;
  }

  .ts-busy-button {
    @include styles.mouseInteractiveBackground(transparent);
    margin: 0 0 0 auto;
    background: transparent;
    font: {
      size: 14px;
      weight: normal;
    }
    padding: 0 10px;
    min-width: unset;
    color: #1e98d7;
  }

  &::after {
    content: '';
    width: 86%;
    height: 1px;
    position: absolute;
    bottom: 0;
    background: #d2dbea;
  }
}