.ts-tabs {
  .ts-tabs__tabs-header {
    height: 50px;
    background: var(--tabs__header-background-color);

    .ts-tabs__tab {
      //Border
      border: none;
      border-bottom: var(--tabs__tab-border-bottom);

      //Colors
      background: var(--tabs__tab-background-color);
      color: var(--tabs__tab-text-color);

      //Text
      font: {
        size: 0.9rem;
        weight: 600;
      }

      //Pseudos
      &:hover {
        background: var(--tabs__tab-background-color-hover);
      }

      &:active {
        background: var(--tabs__tab-background-color-active);
      }

      //Concat Classes
      &.disabled {
        background: var(--tabs__tab-background-color);
        cursor: not-allowed;
        opacity: 0.5;

        &:hover, &:active {
          background: var(--tabs__tab-background-color);
          opacity: 0.5;
        }
      }

      &.ts-tabs__focused {
        background: var(--tabs__tab-background-color-selected);
        color: var(--tabs__tab-text-color-selected);
        border-bottom: var(--tabs__tab-border-bottom-focused);
      }
    }
  }

  .ts-tabs__content {
    height: 0;
    flex-grow: 1;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    background: var(--tabs__content-background-color);
  }
}