@use 'palette' as palette;
@use 'sass:list';

@mixin customScrollbar($direction,$showOnlyOnHover: false) {
  $trackBG: palette.gray(7);
  $thumbBG: palette.dark-blue(1);
  //Scrollbar
  &::-webkit-scrollbar {
    background-color: $trackBG;
  }

  //Scrollbar Handle
  &::-webkit-scrollbar-thumb {
    background-color: $thumbBG;
    border: 2px solid $trackBG;
    border-radius: 4px;
  }

  //Direction Diff
  @if $direction == 'horizontal' {
    overflow-x: auto;
    //noinspection ALL
    @supports (overflow-x: overlay) {
      overflow-x: overlay;
    }
    &::-webkit-scrollbar {
      height: 12px;
    }
  }

  @if $direction == 'vertical' {
    overflow-y: auto;
    //noinspection ALL
    @supports (overflow-y: overlay) {
      overflow-y: overlay;
    }
    &::-webkit-scrollbar {
      width: 9px;
    }
  }

  @if $direction == 'both' {
    overflow: auto;
    //noinspection ALL
    @supports (overflow: overlay) {
      overflow: overlay;
    }
    &::-webkit-scrollbar {
      width: 9px;
      height: 12px;
    }
  }

  @if $showOnlyOnHover {
    scrollbar-gutter: stable;
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
    }

    &:hover {
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
}

@mixin fancyDropDown {
  .ts-overlay {
    background: transparent;
  }

  .ts-dropdown__header {
    border: 2px solid black;
    border-radius: 10px;
    position: relative;

    .ts-input {
      background: transparent;
      padding: 0 5px;
      border: none;
      font: {
        size: 13px;
        weight: bold;
      }
    }
  }

  .ts-dropdown__items-container {
    @include customScrollbar('both');
    border: 2px solid black;
    border-top: none;
    padding-top: 18px;
    transform: translateY(-18px);

    .ts-dropdown__unselect-item {
      font-size: 13px;
    }

    .ts-dropdown__items {
      overflow-y: visible;
      border: none
    }

    .ts-tree__node {
      .node-data {
        font-size: 13px;
      }
    }

    &.inverted {
      border-top: 2px solid black;
      border-bottom: none;
      padding-top: 0;
      padding-bottom: 18px;
      transform: translateY(18px);
    }
  }
}

@mixin mouseInteractiveBackground($background,$hoverBackground: $background, $activeBackground: $hoverBackground) {
  background: $background;
  cursor: pointer;

  &:hover {
    background: $hoverBackground;
  }

  &:active {
    background: $activeBackground;
  }
}

@mixin mouseInteractiveBorder($border,$hoverBorder: $border, $activeBorder: $hoverBorder) {
  border: $border;
  cursor: pointer;

  &:hover {
    border: $hoverBorder;
  }

  &:active {
    border: $activeBorder;
  }
}

@mixin mouseInteractiveColor($color,$hoverColor:$color,$activeColor:$hoverColor) {
  color: $color;
  cursor: pointer;

  &:hover {
    color: $hoverColor;
  }
  &:active {
    color: $activeColor
  }
}

@mixin setRule($rule,$value) {
  $rule: $value;
}

@mixin setIconProp($prop,$value) {
  *:not(svg):not(g):not([data-no-#{$prop}="true"]) {
    #{$prop}: $value;
  }
}

@mixin mouseInteractiveIcon($color,$hoverColor:$color,$activeColor:$hoverColor,$transition: 0) {
  $rules: fill, stroke;
  cursor: pointer;

  @if $transition != 0 {
    $transitionRules: '';
    @each $rule in $rules {
      $i: index($rules, $rule);
      $transitionRules: $transitionRules + #{$rule $transition};
      @if $i != length($rules) {
        $transitionRules: $transitionRules + ', ';
      }
    }
    *:not(g) {
      transition: #{$transitionRules};
    }
  }

  @each $rule in $rules {
    @include setIconProp($rule, $color);
    &:hover {
      @include setIconProp($rule, $hoverColor)
    }
    &:active {
      @include setIconProp($rule, $activeColor)
    }
    //*:not([data-no-#{$rule}="true"]):not(g) {
    //  #{$rule}: $color;
    //}
    //&:hover *:not([data-no-#{$rule}="true"]):not(g) {
    //  #{$rule}: $hoverColor;
    //}
    //&:active *:not([data-no-#{$rule}="true"]):not(g) {
    //  #{$rule}: $activeColor;
    //}
  }
}