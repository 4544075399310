@use '@app/styles' as styles;
//@use 'node_modules/@nu-art/thunderstorm/styles' as *;

.entry {
  margin: 0 5px;
  border-radius: 4px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid styles.purple(2);
  background: styles.purple(6);

  .icon--wrapper {
    padding: 0 5px;
    height: 100%;
    width: 22px;
    cursor: pointer;

    svg path {
      fill: black;
      stroke-width: 1px;
      stroke: black;
    }

    &:hover {
      background: styles.purple(5);
    }
  }

  .entry-display {
    border-left: 2px solid styles.purple(2);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    font: {
      size: 14px;
      weight: bold;
    }
    color: black;

  }

  .ref-link {
    border-left: 2px solid styles.purple(2);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    font: {
      size: 14px;
      weight: bold;
    }
    color: black;

    &:hover {
      background: styles.purple(5);
    }
  }

  .entry-tail {
    @include styles.mouseInteractiveBackground(transparent);
    @include styles.mouseInteractiveIcon(styles.orange(3));
  }
}

.entry-loader {
  margin: 0 5px;

  .ts-loader {
    width: 30px;
    height: 30px;
  }
}