@use '@app/styles/frontend/ui/ui-manager/consts' as *;

.time-range-input {
  gap: 5px;
  width: 100%;

  .ts-dropdown {
    @extend %ui-manager__ts-dropdown-design;
    max-width: 100px;
  }

  .ts-input {
    @extend %ui-manager__ts-input-design;
    width: 150px;
    text-align: center;

    &::placeholder {
      display: flex;
      justify-content: center;
      align-items: baseline;
      font-family: Arial, sans-serif;
    }
  }
}