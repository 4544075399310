.ts-textarea {
  //Dimensions
  width: 100%;
  height: 100%;
  padding: 8px;
  resize: vertical;
  flex-shrink: 0;

  //Text
  white-space: pre-wrap;
  font: {
    size: 0.8rem;
    weight: 600;
  }

  //Border
  border: none;

  //Colors
  background: rgba(0, 0, 0, 2%);

  //Shadows
  box-shadow: inset 1px 1px 5px #00000030;

  //Pseudos
  &::placeholder {
    font-style: italic;
  }

  &:focus-visible {
    outline: none;
  }
}