@use "@app/styles" as styles;
@use '@app/styles/frontend/ui/ui-manager/consts' as *;


.expression-card.ts-card, .quai-manager-ui .expression-card {
  @extend %ui-manager__ts-card;

  &.hide-card {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: unset;
  }

  &.term-card {

    .ts-prop-renderer__label {
      display: flex;
      align-items: center;
      width: unset;
      gap: 5px;

      .term-drag-handle {
        @include styles.mouseInteractiveIcon(black);
        cursor: grab;
        -webkit-user-drag: element;
      }

      .term-header {
        color: #333333;
        font-weight: 400;
        font-size: 16px;
      }

      .ts-dropdown {
        @extend %ui-manager__ts-dropdown-design;
        width: 120px;
        margin-inline: 5px;
      }
    }

    &.vertical {
      gap: 10px;
    }

    &.out-of-screen {
      position: fixed;
      top: 200vh;
      left: 200vw;
    }
  }

  &.top-expression {

    .ts-prop-renderer {
      &.horizontal {
        gap: 10px;

        .ts-dropdown {
          @extend %ui-manager__ts-dropdown-design;
          max-width: 100px;
        }

        .ts-prop-renderer__label {
          width: unset;

          .editor-title-container {
            width: 100%;
            color: #333333;
            font-weight: 400;
            gap: 20px;
          }
        }
      }
    }
  }
}

.term-placeholder {
  height: 100px;
  background: styles.dark-blue(7);

  &.hidden {
    display: none;
  }
}

#pop-up__term-actions {
  @extend %actions-popup;
}