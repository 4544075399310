.structured-complex-logic {
  gap: 15px;
  width: 100%;
  height: 100%;


  .sub-term-list {
    gap: 20px;
    width: 100%;

    .sub-term-list__sub-term-container {
      width: 100%;
      gap: 10px;

      .sub-term-list__title {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
      }
    }
  }

  .structured-complex-logic__title {
    font-size: 16px;
    color: #333333;
    font-weight: 400;
  }
}